import {
  ColourPicker,
  FileUpload,
  FormCheckList,
  FormDatePicker,
  ImageUpload,
  Input,
  RichTextEditor,
  SelectWithFiltering,
  TextArea,
} from "./_Elements";
import {
  getCustomComponent,
  getFieldPlaceholder,
  getTouchedValue,
} from "../form/Helpers";

function FormField({
  actions,
  actionsClass,
  description,
  disabled,
  dispatch,
  entityName,
  errors,
  field,
  handleBlur,
  handleChange,
  handleCheckListChange,
  handleDateChange,
  handleFilteredPicklistChange,
  handlePicklistChange,
  label,
  mandatory,
  options,
  optionsEnum,
  parentFormState,
  setFieldValue,
  setSubForm,
  showTimeSelect,
  showTimeSelectOnly,
  stages,
  state,
  tooltipDescription,
  touched,
  value,
  values,
}) {
  const {
    dateFormat,
    displayValue,
    filterDate,
    inline,
    mapOptions,
    mapOptionGroups,
    mapValue,
    name,
    renderCustomHeader,
    showErrorsBeforeTouched,
  } = field;
  const type = field.mapType ? field.mapType(values) : field.type;

  switch (type) {
    case "bit":
    case "nvarchar":
    case "ntext":
    case "int":
    case "decimal":
    case "money":
    case "email":
    case "float":
      return (
        <Input
          actions={actions}
          actionsClass={actionsClass}
          checked={type === "bit" ? value : null}
          description={description}
          disabled={disabled}
          errors={errors}
          label={label}
          mandatory={mandatory}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder=""
          showErrorsBeforeTouched={showErrorsBeforeTouched}
          tooltipDescription={tooltipDescription}
          touched={getTouchedValue(field, touched)}
          type={type}
          value={value}
        />
      );
    case "lookup":
    case "picklist":
      if (value && options && !options.some((o) => o.Key === value)) {
        options.push({
          Value: values[field.name + "name"],
          Key: value,
          InActive: true,
        });
      }
      return (
        <SelectWithFiltering
          actions={actions}
          actionsClass={actionsClass}
          description={description}
          disabled={disabled}
          errors={errors}
          label={label}
          mandatory={mandatory}
          name={name}
          options={
            !mapOptionGroups
              ? type === "lookup"
                ? mapOptions
                  ? mapOptions(options, state, values)
                  : options
                : Object.entries(optionsEnum).map(
                    ([Name, Number]) => ({
                      Key: Number,
                      Value: Name,
                    })
                  )
              : null
          }
          optionGroups={
            mapOptionGroups && options
              ? mapOptionGroups(options, state, values)
              : []
          }
          onChange={(event) => {
            field.handleChange
              ? field.handleChange(
                  event,
                  handleChange,
                  field.name,
                  values
                )
              : handleFilteredPicklistChange(
                  event,
                  handleChange,
                  field.name,
                  values
                );
          }}
          onBlur={handleBlur}
          placeholder={getFieldPlaceholder(field)}
          reverseLookup={field.reverseLookup}
          showErrorsBeforeTouched={showErrorsBeforeTouched}
          tooltipDescription={tooltipDescription}
          touched={getTouchedValue(field, touched)}
          value={value}
        />
      );
    case "datetime":
      return (
        <FormDatePicker
          {...{
            dateFormat,
            description,
            disabled,
            errors,
            handleDateChange,
            label,
            mandatory,
            name,
            renderCustomHeader,
            showTimeSelect,
            showTimeSelectOnly,
            tooltipDescription,
          }}
          filterDate={(date) =>
            filterDate ? filterDate(date, values) : true
          }
          locale={"en-GB"}
          selected={value}
        />
      );
    case "checklist":
      return (
        <FormCheckList
          {...{
            actions,
            actionsClass,
            description,
            disabled,
            errors,
            label,
            inline,
            name,
            showErrorsBeforeTouched,
            tooltipDescription,
            value,
          }}
          onChange={handleCheckListChange}
          options={
            mapOptions ? mapOptions(options, state, values) : options
          }
          touched={getTouchedValue(field, touched)}
        />
      );
    case "image":
      return (
        <ImageUpload
          {...{
            displayValue,
            errors,
            handleChange,
            label,
            mapValue,
            name,
            showErrorsBeforeTouched,
            value,
          }}
          touched={getTouchedValue(field, touched)}
        />
      );
    case "fileupload":
      return (
        <FileUpload
          handleChange={handleChange}
          mapValue={mapValue}
          name={name}
          value={value}
          label={label}
        />
      );
    case "textarea":
      return (
        <TextArea
          actions={actions}
          actionsClass={actionsClass}
          description={description}
          disabled={disabled}
          errors={errors}
          height={
            field.textAreaHeight ? field.textAreaHeight : "60px"
          }
          label={label}
          mandatory={mandatory}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder=""
          showErrorsBeforeTouched={showErrorsBeforeTouched}
          tooltipDescription={tooltipDescription}
          touched={getTouchedValue(field, touched)}
          value={value}
        />
      );
    case "richtexteditor":
      return (
        <RichTextEditor
          actions={actions}
          actionsClass={actionsClass}
          disabled={disabled}
          errors={errors}
          field={field}
          label={label}
          mandatory={mandatory}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder=""
          setFieldValue={setFieldValue}
          touched={getTouchedValue(field, touched)}
          value={value}
          state={state}
        />
      );
    case "colourpicker":
      return (
        <>
          <div className="d-flex align-items-end">
            <div className="flex-fill me-3">
              <Input
                actions={actions}
                actionsClass={actionsClass}
                checked={type === "bit" ? value : null}
                description={description}
                disabled={disabled}
                errors={errors}
                label={label}
                mandatory={mandatory}
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                tooltipDescription={tooltipDescription}
                touched={getTouchedValue(field, touched)}
                type={type}
                value={value}
              />
            </div>
            <ColourPicker
              handleChange={handleChange}
              name={name}
              type={field.colourPickerType}
              value={value}
            />
          </div>
        </>
      );
    case "component":
      return getCustomComponent(
        disabled,
        dispatch,
        entityName,
        errors,
        field,
        handleBlur,
        handleChange,
        handleCheckListChange,
        handleDateChange,
        handleFilteredPicklistChange,
        handlePicklistChange,
        name,
        parentFormState,
        setFieldValue,
        setSubForm,
        stages,
        state,
        touched,
        value,
        values
      );
    default:
      return <p>Unknown</p>;
  }
}

export default FormField;
