import { ActualVoucherRow, SampleVoucher } from "../voucher/Voucher";

function ActualVouchers({
  backgroundColour,
  borderColour,
  borderType,
  borderThickness,
  fontFamily,
  fontSize,
  fontWeight,
  localeCode,
  longDateFormat,
  margin,
  padding,
  redeemLink,
  termsLink,
  textColour,
}) {
  return (
    <table
      className="full-width zero-margin hide-on-designer"
      cellSpacing="0"
    >
      <tbody
        data-xsl-wrap="xsl:for-each"
        data-xsl-attributes="select='Vouchers/Voucher'"
      >
        <ActualVoucherRow
          {...{
            backgroundColour,
            borderColour,
            borderType,
            borderThickness,
            fontFamily,
            fontSize,
            fontWeight,
            localeCode,
            longDateFormat,
            margin,
            padding,
            redeemLink,
            termsLink,
            textColour,
          }}
        />
      </tbody>
    </table>
  );
}

function Vouchers({ properties }) {
  const {
    voucherBackgroundColour,
    voucherBorderColour,
    voucherBorderType,
    voucherBorderThickness,
    voucherFontFamily,
    voucherFontSize,
    voucherFontWeight,
    voucherLocaleCode,
    voucherLongDateFormat,
    voucherMargin,
    voucherPadding,
    voucherRedeemLink,
    voucherTermsLink,
    voucherTextColour,
  } = properties;
  return (
    <table
      className="full-width zero-margin"
      cellSpacing="0"
      data-xsl-wrap="xsl:if"
      data-xsl-attributes="test='Vouchers/Voucher'"
    >
      <tbody>
        <tr>
          <td>
            <ActualVouchers
              backgroundColour={voucherBackgroundColour}
              borderColour={voucherBorderColour}
              borderType={voucherBorderType}
              borderThickness={voucherBorderThickness}
              fontFamily={voucherFontFamily}
              fontSize={voucherFontSize}
              fontWeight={voucherFontWeight}
              localeCode={voucherLocaleCode}
              longDateFormat={voucherLongDateFormat}
              margin={voucherMargin}
              padding={voucherPadding}
              redeemLink={voucherRedeemLink}
              termsLink={voucherTermsLink}
              textColour={voucherTextColour}
            />
            <SampleVoucher
              backgroundColour={voucherBackgroundColour}
              borderColour={voucherBorderColour}
              borderType={voucherBorderType}
              borderThickness={voucherBorderThickness}
              fontFamily={voucherFontFamily}
              fontSize={voucherFontSize}
              fontWeight={voucherFontWeight}
              localeCode={voucherLocaleCode}
              longDateFormat={voucherLongDateFormat}
              margin={voucherMargin}
              padding={voucherPadding}
              redeemLink={voucherRedeemLink}
              termsLink={voucherTermsLink}
              textColour={voucherTextColour}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Vouchers;
