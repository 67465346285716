import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { checkRelatedEntityHasRecords } from "../components/form/Helpers";
import { checkIfSellFixturesAndSeriesSettingOn } from "../js/utility";
import { BookingProductBundleContentItemFormStages } from "./bookingProductBundleContentItem";
import lookups from "./lookups";

export const BookingProductFormStages = [
  {
    title: "General",
    id: "general",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_protoproductidname",
            className: "col-md-6",
            description: "",
            label: "Product Variant",
            type: "nvarchar",
          },
          {
            name: "g4b_number",
            className: "col-md-6",
            description: "",
            label: "Quantity",
            type: "int",
          },
          {
            name: "g4b_bookingprice",
            className: "col-md-6",
            description: "",
            label: "Booking Price",
            type: "money",
          },
          {
            name: "g4b_discountvalue",
            className: "col-md-6",
            description: "",
            label: "Discount Value",
            type: "money",
          },
          {
            name: "g4b_startdatetime",
            className: "col-md-6",
            description: "",
            label: "Start Time",
            type: "datetime",
          },
          {
            name: "g4b_enddatetime",
            className: "col-md-6",
            description: "",
            label: "End Time",
            type: "datetime",
          },
          {
            name: "g4b_crosssellprotoproductidname",
            className: "col-md-6",
            description: "",
            label: "Cross Sell Product Variant",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Product Users",
    id: "product-users",
    displayEditButtonFromState: () => false,
    sectionInitiallyCollapsedFromState: (state) =>
      !checkRelatedEntityHasRecords("g4b_productuser", state),
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_contactidname",
              label: "Contact Name",
              className: "col-short-name",
            },
            {
              name: "g4b_membershipnumber",
              label: "Membership Number",
              className: "col-short-name",
              linkedEntityId: "g4m_membershipid",
              linkForNewRecord: "/membership",
            },
            {
              name: "g4b_productbaseidname",
              label: "Product",
              className: "col-short-name",
              linkedEntityId: "g4b_productbaseid",
              linkForNewRecord: "/product",
            },
            {
              name: "g4b_varianttypeidname",
              label: "Variant",
              className: "col-short-name",
            },
            {
              name: "g4b_startdate",
              className: "col-date",
              label: "Start Date",
              type: "datetime",
            },
            {
              name: "g4b_enddate",
              className: "col-date",
              label: "End Date",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_productuser",
          lookup: lookups.g4b_productuser.all,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Price Breaks Details",
    id: "price-breaks-details",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (state) => state.g4b_pricebreakquantity,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_pricebreakquantity",
            className: "col-md-6",
            description: "",
            label: "Price Break Quantity",
            type: "int",
          },
          {
            name: "g4b_pricebreakdiscountpercentage",
            className: "col-md-6",
            description: "",
            label: "Price Break Discount Percentage (%)",
            type: "number",
          },
          {
            name: "g4b_pricebreakdiscountamount",
            className: "col-md-6",
            description: "",
            label: "Price Break Discount Amount",
            type: "money",
          },
        ],
      },
    ],
  },
  {
    title: "Product Modifiers",
    id: "product-modifiers",
    displayEditButtonFromState: () => false,
    sectionInitiallyCollapsedFromState: (state) =>
      !checkRelatedEntityHasRecords(
        "g4b_bookingproductmodifier",
        state
      ),
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_bookableproductmodifieridname",
              label: "Name",
              className: "col-short-name",
            },
            {
              name: "g4b_modifiervalue",
              label: "Value",
              className: "col-number",
            },
            {
              name: "g4b_protoproductidname",
              label: "Product Variant",
              className: "col-short-name",
              type: "money",
            },
            {
              name: "g4b_bookingproductmodifierprice",
              label: "Modifier Price",
              className: "col-number",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_bookingproductmodifier",
          lookup: lookups.g4b_bookingproductmodifier.all,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Membership",
    id: "membership",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (state) => state.g4m_membershippaymentplanid,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4m_membershippaymentplanidname",
            className: "col-md-6",
            description: "",
            label: "Payment Plan",
            type: "nvarchar",
          },
          {
            name: "g4m_membershipinitialfeestartdate",
            className: "col-md-6",
            description: "",
            label: "Initial Start Date",
            type: "datetime",
          },
          {
            name: "g4m_membershipinitialfee",
            className: "col-md-6",
            description: "",
            label: "Membership Initial Fee",
            type: "money",
          },
          {
            name: "g4m_membershipproratafee",
            className: "col-md-6",
            description: "",
            label: "Membership Prorata Fee",
            type: "money",
          },
          {
            name: "g4m_membershipjoiningfee",
            className: "col-md-6",
            description: "",
            label: "Membership Joining Fee",
            type: "money",
          },
        ],
      },
    ],
  },
  {
    title: "Journal Entries",
    id: "journal-entries",
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          fields: [
            {
              name: "g4b_people",
              label: "People",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_units",
              label: "Units",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_value",
              label: "Value",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_netvalue",
              label: "Net Value",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_valueused",
              label: "Value Used (%)",
              className: "col-number",
            },
            {
              name: "g4b_tax",
              label: "Tax",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_commission",
              label: "Commission",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_commissioncategoryidname",
              label: "Commission Category",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_journalentry",
          lookup: lookups.g4b_journalentry.all,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Bundle Content Items",
    id: "bundle-content-items",
    displayEditButtonFromState: () => false,
    sectionInitiallyCollapsedFromState: (state) =>
      !checkRelatedEntityHasRecords(
        "g4b_bookingproductbundlecontentitem",
        state
      ),
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_productbundleitemidname",
              label: "Product Bundle Item",
              className: "col-short-name",
              type: "string",
            },
            {
              name: "g4b_variantidname",
              label: "Product Variant",
              className: "col-short-name",
              type: "string",
            },
            {
              name: "g4b_fixtureidname",
              displayIfFromGlobalState: (globalState) => {
                return checkIfSellFixturesAndSeriesSettingOn(
                  globalState
                );
              },
              label: "Fixture",
              className: "col-short-name",
            },
            {
              name: "g4b_seriesidname",
              displayIfFromGlobalState: (globalState) => {
                return checkIfSellFixturesAndSeriesSettingOn(
                  globalState
                );
              },
              label: "Series",
              className: "col-short-name",
            },
            {
              name: "g4b_startdatetime",
              label: "Start",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_enddatetime",
              label: "End",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_netprice",
              label: "Net Price",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_price",
              label: "Price",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_taxrate",
              label: "Tax Rate (%)",
              className: "col-number",
            },
            {
              name: "g4b_taxamount",
              label: "Tax Amount",
              className: "col-number",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName:
            "g4b_bookingproductbundlecontentitem",
          lookup: lookups.g4b_bookingproductbundlecontentitem.all,
          subFormStages: BookingProductBundleContentItemFormStages,
          useSubForm: true,
        },
      },
    ],
  },
];
