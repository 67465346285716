import { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import { getLookupData } from "../../../js/utility";
import { AlertStyle, ButtonStyle } from "../../../js/enums";
import lookups from "../../../forms/lookups";
import {
  Alert,
  Button,
  CloseButton,
  Input,
  Loading,
  Modal,
  SelectWithFiltering,
} from "../../elements/_Elements";
import {
  getValidationMessageForBundleItems,
  handleProductBundleItemChange,
} from "./Helpers";
import ProductBundleItemProductCreate from "./ProductBundleItemProductCreate";

function ProductBundleItem({
  disabled,
  entityName,
  handleChange,
  name,
  state,
  value,
  values,
}) {
  const [productBundleItems, setProductBundleItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [expandedProducts, setExpandedProducts] = useState([]);
  const [productToRemove, setProductToRemove] = useState(null);
  const [displayAddNewProductModal, setDisplayAddNewProductModal] =
    useState(null);

  useEffect(() => {
    getValidationMessageForBundleItems(
      productBundleItems,
      setAlertMessage
    );
  }, [productBundleItems, values]);

  const productLookup = getLookupData(
    lookups.g4b_productbase.all.name,
    state
  );

  const productVariantsLookup = getLookupData(
    lookups.g4b_protoproduct.all.name,
    state
  );

  const variantTypesLookup = getLookupData(
    lookups.g4b_varianttype.all.name,
    state
  );

  useEffect(() => {
    setProductBundleItems(
      values && values.productBundleItems && productLookup
        ? values.productBundleItems.map((item) => {
            const product = productLookup.find(
              (type) => type.Key === item.ProductId
            );
            return {
              ...item,
              ProductName: product
                ? product.Value
                : item.ProductName
                ? item.ProductName
                : "",
            };
          })
        : []
    );
  }, [productLookup, values]);

  const toggleProduct = (productId) => {
    const updatedProducts = expandedProducts.includes(productId)
      ? expandedProducts.filter((id) => id !== productId)
      : [...expandedProducts, productId];

    // update the expanded products
    setExpandedProducts(updatedProducts);
  };

  const removeProduct = (productId) => {
    setProductToRemove(null);
    handleChange({
      target: {
        name: name,
        value: value.filter(
          (product) => product.ProductId !== productId
        ),
      },
    });
  };

  const getVariantTypeForProduct = (productId) => {
    let productVariantsForProduct = productVariantsLookup.filter(
      (x) => {
        return x.ProductId === productId;
      }
    );
    return variantTypesLookup.filter((v) =>
      productVariantsForProduct.some((p) => p.VariantTypeId === v.Key)
    );
  };

  useEffect(() => {
    if (values.productbase) {
      // add the new product bundle item
      let newValue = [...productBundleItems];
      newValue.push({
        Id: null,
        ProductId: values.productbase,
        VariantTypeId: null,
        FixedPrice: null,
        Percentage: null,
        FixedRevenueShare: false,
        CollectGiftAid: false,
        SetAsParentProductBundleItem: false,
      });

      handleChange({
        target: {
          name: name,
          value: newValue,
        },
      });

      // clear the product base value
      handleChange({
        target: {
          name: "productbase",
          value: "",
        },
      });
    }
  }, [
    handleChange,
    name,
    productBundleItems,
    values.productbase,
    values.varianttype,
  ]);

  // Wait for lookups to load.
  if (!productLookup || !variantTypesLookup) {
    return <Loading />;
  }

  return (
    <>
      {displayAddNewProductModal && (
        <ProductBundleItemProductCreate
          entityName={entityName}
          handleChange={handleChange}
          name={name}
          setDisplayAddNewProductModal={setDisplayAddNewProductModal}
          productBundleItems={productBundleItems}
          state={state}
        />
      )}
      {productToRemove && (
        <Modal
          title={"Remove Product from Bundle?"}
          modalCloseButtonClick={() => {
            setProductToRemove(null);
          }}
          className="modal modal-dialog-scrollable"
        >
          <div className="modal-body">
            <small>
              Are you sure you want to remove this product from the
              bundle?
            </small>
          </div>
          <div className="modal-footer">
            <Button
              text={"Confirm"}
              style={ButtonStyle.Primary}
              onClick={() => {
                removeProduct(productToRemove);
              }}
            />
            <Button
              text={"Cancel"}
              style={ButtonStyle.Info}
              onClick={() => {
                setProductToRemove(null);
              }}
            />
          </div>
        </Modal>
      )}
      {alertMessage && (
        <Alert
          className="mb-3"
          style={AlertStyle.Danger}
          text={alertMessage}
        />
      )}
      {productBundleItems &&
        productBundleItems
          .sort((a, b) => a.ProductName.localeCompare(b.ProductName))
          .map((producBundleItem) => {
            const {
              CollectGiftAid,
              FixedPrice,
              FixedRevenueShare,
              Percentage,
              ProductId,
              ProductName,
              SetAsParentProductBundleItem,
              VariantTypeId,
            } = producBundleItem;
            return (
              <Fragment key={ProductId}>
                <div className="row align-items-center py-2 border-top border-bottom border-light">
                  <div
                    className={classNames(
                      "col-3 col-md-2 product-variant-type-name",
                      expandedProducts.includes(ProductId)
                        ? "active"
                        : ""
                    )}
                    onClick={() => toggleProduct(ProductId)}
                  >
                    {ProductName}
                  </div>
                  <div className="col-6 col-md-4 d-flex align-items-center justify-content-center">
                    <SelectWithFiltering
                      disabled={disabled}
                      label="Default Variant Type"
                      name={ProductId + "variantTypeId"}
                      options={getVariantTypeForProduct(ProductId)}
                      mandatory={true}
                      onChange={(event) =>
                        handleProductBundleItemChange(
                          "variantTypeId",
                          event.key,
                          ProductId,
                          productBundleItems,
                          handleChange
                        )
                      }
                      value={VariantTypeId ? VariantTypeId : ""}
                    />
                  </div>
                  <div className="col-6 col-md-4 d-flex align-items-center justify-content-center">
                    <Input
                      disabled={disabled}
                      label="Set as Parent Product Bundle Item?"
                      name={
                        ProductId + "setAsParentProductBundleItem"
                      }
                      onChange={(event) => {
                        handleProductBundleItemChange(
                          "setAsParentProductBundleItem",
                          event.target.checked,
                          ProductId,
                          productBundleItems,
                          handleChange
                        );
                      }}
                      type="bit"
                      checked={SetAsParentProductBundleItem}
                    />
                  </div>
                  <div className="col-3 col-md-2 ms-auto">
                    <CloseButton
                      className="float-end"
                      disabled={disabled}
                      label="Close"
                      onClick={() => {
                        setProductToRemove(ProductId);
                      }}
                    />
                  </div>
                </div>
                <div
                  className={classNames(
                    "row py-3 product-variant collapse",
                    expandedProducts.includes(ProductId) ? "show" : ""
                  )}
                >
                  <div className="col-md-3">
                    <Input
                      disabled={disabled}
                      label="Fixed Price"
                      name={ProductId + "fixedPrice"}
                      onChange={(event) =>
                        handleProductBundleItemChange(
                          "fixedPrice",
                          event.target.value,
                          ProductId,
                          productBundleItems,
                          handleChange
                        )
                      }
                      placeholder=""
                      type="money"
                      value={FixedPrice}
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      disabled={disabled}
                      label="Percentage"
                      name={ProductId + "percentage"}
                      onChange={(event) =>
                        handleProductBundleItemChange(
                          "percentage",
                          event.target.value,
                          ProductId,
                          productBundleItems,
                          handleChange
                        )
                      }
                      placeholder=""
                      type="decimal"
                      value={Percentage}
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      disabled={disabled}
                      label="Fixed Revenue Share"
                      name={ProductId + "fixedRevenueShare"}
                      onChange={(event) =>
                        handleProductBundleItemChange(
                          "fixedRevenueShare",
                          event.target.checked,
                          ProductId,
                          productBundleItems,
                          handleChange
                        )
                      }
                      placeholder=""
                      type="bit"
                      checked={FixedRevenueShare}
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      disabled={disabled}
                      label="Collect Gift Aid?"
                      name={ProductId + "collectGiftAid"}
                      onChange={(event) =>
                        handleProductBundleItemChange(
                          "collectGiftAid",
                          event.target.checked,
                          ProductId,
                          productBundleItems,
                          handleChange
                        )
                      }
                      placeholder=""
                      type="bit"
                      checked={CollectGiftAid}
                    />
                  </div>
                </div>
              </Fragment>
            );
          })}
      <Button
        className="mt-3"
        type={"button"}
        style={ButtonStyle.Info}
        text={"Create new product"}
        onClick={() => {
          setDisplayAddNewProductModal(true);
        }}
      />
    </>
  );
}

export default ProductBundleItem;
