import { getDateString } from "../../Helpers";

function VoucherTableCell({
  children,
  className,
  fontFamily,
  fontSize,
  fontWeight,
  padding,
  textColour,
}) {
  return (
    <td
      className={className}
      style={{
        color: textColour,
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontWeight: fontWeight,
        padding: padding,
      }}
    >
      {children}
    </td>
  );
}

function ActualVoucherRow({
  backgroundColour,
  borderColour,
  borderType,
  borderThickness,
  fontFamily,
  fontSize,
  fontWeight,
  localeCode,
  longDateFormat,
  margin,
  padding,
  redeemLink,
  termsLink,
  textColour,
}) {
  return (
    <tr>
      <td style={{ padding: margin }}>
        <table
          className="full-width zero-margin"
          cellSpacing="0"
          style={{
            borderColor: borderColour,
            borderStyle: borderType,
            borderWidth: borderThickness,
          }}
        >
          <tbody>
            <tr style={{ backgroundColor: backgroundColour }}>
              <VoucherTableCell
                {...{
                  fontFamily,
                  fontSize,
                  fontWeight,
                  padding,
                  textColour,
                }}
              >
                <p style={{ fontSize: "20px" }}>
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: '<xsl:value-of select="VoucherName" />',
                    }}
                  />
                </p>
              </VoucherTableCell>
            </tr>
            <tr style={{ backgroundColor: backgroundColour }}>
              <td>
                <table className="mobile-full2 column" align="left">
                  <tbody>
                    <tr>
                      <VoucherTableCell
                        {...{
                          fontFamily,
                          fontSize,
                          fontWeight,
                          padding,
                          textColour,
                        }}
                      >
                        <p
                          data-xsl-wrap="xsl:if"
                          data-xsl-attributes='test="VoucherValue"'
                        >
                          <strong>Value: </strong>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                '<xsl:value-of select="g4:FormatCurrency(VoucherValue, ../../CurrencyCode)" />',
                            }}
                          />
                        </p>
                        <p>
                          <strong>Code: </strong>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                '<xsl:value-of select="VoucherCode" />',
                            }}
                          />
                        </p>
                        <p
                          data-xsl-wrap="xsl:if"
                          data-xsl-attributes='test="ExpiryDate"'
                        >
                          <strong>Expiry Date: </strong>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `<xsl:value-of select="g4:TranslateDate(ExpiryDate, '${longDateFormat}', '${localeCode}')" />`,
                            }}
                          />
                        </p>
                      </VoucherTableCell>
                    </tr>
                  </tbody>
                </table>
                <div
                  className="inner-content-only"
                  dangerouslySetInnerHTML={{
                    __html: "<!--[if mso]></td><td><![endif]--></td>",
                  }}
                ></div>
                <table className="mobile-full2 column" align="left">
                  <tbody>
                    <tr>
                      <VoucherTableCell
                        {...{
                          fontFamily,
                          fontSize,
                          fontWeight,
                          padding,
                          textColour,
                        }}
                      >
                        <div
                          className="inner-content-only"
                          dangerouslySetInnerHTML={{
                            __html: `<xsl:variable name="voucherCodeUrl" select="concat('https://www.eventdata.co.uk/Library/Symbology/Symbol.aspx?Type=128{HtmlEncode:&Dpi=300&}Data=', VoucherCode)"></xsl:variable>`,
                          }}
                        ></div>
                        <p
                          style={{ textAlign: "center" }}
                          dangerouslySetInnerHTML={{
                            __html:
                              '<xsl:img src="{$voucherCodeUrl}" style="max-width: 100%; width: 268px;" width="268"></xsl:img>',
                          }}
                        ></p>
                      </VoucherTableCell>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            {(redeemLink || termsLink) && (
              <tr
                style={{
                  backgroundColor: backgroundColour,
                }}
              >
                <VoucherTableCell
                  {...{
                    fontFamily,
                    fontSize,
                    fontWeight,
                    padding,
                    textColour,
                  }}
                >
                  {redeemLink && (
                    <p style={{ float: "left" }}>
                      <a href={redeemLink}>Redeem Online</a>
                    </p>
                  )}
                  {termsLink && (
                    <p style={{ float: "right" }}>
                      <a href={termsLink}>View Terms & Conditions</a>
                    </p>
                  )}
                </VoucherTableCell>
              </tr>
            )}
          </tbody>
        </table>
      </td>
    </tr>
  );
}

function ActualVoucher({
  backgroundColour,
  borderColour,
  borderThickness,
  borderType,
  fontFamily,
  fontSize,
  fontWeight,
  localeCode,
  longDateFormat,
  margin,
  padding,
  redeemLink,
  termsLink,
  textColour,
}) {
  return (
    <table
      className="full-width zero-margin hide-on-designer"
      cellSpacing="0"
    >
      <tbody>
        <ActualVoucherRow
          {...{
            backgroundColour,
            borderColour,
            borderType,
            borderThickness,
            fontFamily,
            fontSize,
            fontWeight,
            localeCode,
            longDateFormat,
            margin,
            padding,
            redeemLink,
            termsLink,
            textColour,
          }}
        />
      </tbody>
    </table>
  );
}

function SampleVoucher({
  backgroundColour,
  borderColour,
  borderType,
  borderThickness,
  fontFamily,
  fontSize,
  fontWeight,
  margin,
  padding,
  redeemLink,
  termsLink,
  textColour,
}) {
  return (
    <table
      className="full-width zero-margin remove-item"
      cellSpacing="0"
    >
      <tbody>
        <tr>
          <td style={{ padding: margin }}>
            <table
              className="full-width zero-margin"
              cellSpacing="0"
              style={{
                borderColor: borderColour,
                borderStyle: borderType,
                borderWidth: borderThickness,
              }}
            >
              <tbody>
                <tr style={{ backgroundColor: backgroundColour }}>
                  <VoucherTableCell
                    {...{
                      fontFamily,
                      fontSize,
                      fontWeight,
                      padding,
                      textColour,
                    }}
                  >
                    <p style={{ fontSize: "20px" }}>
                      <strong>Voucher Product Name</strong>
                    </p>
                  </VoucherTableCell>
                </tr>
                <tr style={{ backgroundColor: backgroundColour }}>
                  <td>
                    <table
                      className="mobile-full2 column"
                      align="left"
                    >
                      <tbody>
                        <tr>
                          <VoucherTableCell
                            {...{
                              fontFamily,
                              fontSize,
                              fontWeight,
                              padding,
                              textColour,
                            }}
                          >
                            <p>
                              <strong>Value: </strong>
                              <span>£20.00</span>
                            </p>
                            <p>
                              <strong>Code: </strong>
                              <span>SJ20N-F3UD2-301U5</span>
                            </p>
                            <p>
                              <strong>Expiry Date: </strong>
                              <span>
                                {getDateString(
                                  0,
                                  1,
                                  "dddd D MMMM YYYY"
                                )}
                              </span>
                            </p>
                          </VoucherTableCell>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      className="mobile-full2 column"
                      align="left"
                    >
                      <tbody>
                        <tr>
                          <VoucherTableCell
                            {...{
                              fontFamily,
                              fontSize,
                              fontWeight,
                              padding,
                              textColour,
                            }}
                          >
                            <p style={{ textAlign: "center" }}>
                              <img
                                src="http://www.eventdata.co.uk/Library/Symbology/Symbol.aspx?Type=128&Dpi=300&Data=8PP0D-CC96A-00082"
                                alt="8PP0D-CC96A-0008"
                                style={{ maxWidth: "100%" }}
                                width="268"
                              />
                            </p>
                          </VoucherTableCell>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                {(redeemLink || termsLink) && (
                  <tr
                    style={{
                      backgroundColor: backgroundColour,
                    }}
                  >
                    <VoucherTableCell
                      {...{
                        fontFamily,
                        fontSize,
                        fontWeight,
                        padding,
                        textColour,
                      }}
                    >
                      {redeemLink && (
                        <p style={{ float: "left" }}>
                          <a href={redeemLink}>Redeem Online</a>
                        </p>
                      )}
                      {termsLink && (
                        <p style={{ float: "right" }}>
                          <a href={termsLink}>
                            View Terms & Conditions
                          </a>
                        </p>
                      )}
                    </VoucherTableCell>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export { ActualVoucher, ActualVoucherRow, SampleVoucher };
