import { ActualVoucher, SampleVoucher } from "./Voucher";

function VoucherControl({ properties }) {
  const {
    backgroundColour,
    borderColour,
    borderType,
    borderThickness,
    fontFamily,
    fontSize,
    fontWeight,
    localeCode,
    longDateFormat,
    margin,
    padding,
    redeemLink,
    termsLink,
    textColour,
  } = properties;
  return (
    <div
      className="plugin-placeholder"
      data-plugin-type="Green4.CRM.Agent.Plugins.VoucherPlugin"
      data-plugin-assembly="Green4.CRM.Agent.Plugins"
      data-contains-xslt="true"
      data-plugin-inline-css="true"
    >
      <div
        data-xsl-wrap="xsl:template"
        data-xsl-attributes="match='Voucher'"
      >
        <ActualVoucher
          {...{
            backgroundColour,
            borderColour,
            borderType,
            borderThickness,
            fontFamily,
            fontSize,
            fontWeight,
            localeCode,
            longDateFormat,
            margin,
            padding,
            redeemLink,
            termsLink,
            textColour,
          }}
        />
        <SampleVoucher
          {...{
            backgroundColour,
            borderColour,
            borderType,
            borderThickness,
            fontFamily,
            fontSize,
            fontWeight,
            localeCode,
            longDateFormat,
            margin,
            padding,
            redeemLink,
            termsLink,
            textColour,
          }}
        />
      </div>
    </div>
  );
}

export default VoucherControl;
