import { ActualCouponRow, SampleCoupon } from "../coupon/Coupon";

function ActualCoupons({
  backgroundColour,
  borderColour,
  borderThickness,
  borderType,
  code,
  fontFamily,
  fontSize,
  fontWeight,
  localeCode,
  longDateFormat,
  margin,
  padding,
  redeemBy,
  redeemFrom,
  redeemLink,
  termsLink,
  textColour,
  useBy,
}) {
  return (
    <table
      className="full-width zero-margin hide-on-designer"
      cellSpacing="0"
    >
      <tbody
        data-xsl-wrap="xsl:for-each"
        data-xsl-attributes="select='Coupons/Coupon'"
      >
        <ActualCouponRow
          {...{
            backgroundColour,
            borderColour,
            borderThickness,
            borderType,
            code,
            fontFamily,
            fontSize,
            fontWeight,
            localeCode,
            longDateFormat,
            margin,
            padding,
            redeemBy,
            redeemFrom,
            redeemLink,
            termsLink,
            textColour,
            useBy,
          }}
        />
      </tbody>
    </table>
  );
}

function Coupons({ properties }) {
  const {
    couponBackgroundColour,
    couponBorderColour,
    couponBorderThickness,
    couponBorderType,
    couponCode,
    couponFontFamily,
    couponFontSize,
    couponFontWeight,
    couponMargin,
    couponPadding,
    couponRedeemBy,
    couponRedeemFrom,
    couponRedeemLink,
    couponTermsLink,
    couponTextColour,
    couponUseBy,
  } = properties;
  return (
    <table
      className="full-width zero-margin"
      cellSpacing="0"
      data-xsl-wrap="xsl:if"
      data-xsl-attributes="test='Coupons/Coupon'"
    >
      <tbody>
        <tr>
          <td>
            <ActualCoupons
              backgroundColour={couponBackgroundColour}
              borderColour={couponBorderColour}
              borderThickness={couponBorderThickness}
              borderType={couponBorderType}
              code={couponCode}
              fontFamily={couponFontFamily}
              fontSize={couponFontSize}
              fontWeight={couponFontWeight}
              margin={couponMargin}
              padding={couponPadding}
              redeemBy={couponRedeemBy}
              redeemFrom={couponRedeemFrom}
              redeemLink={couponRedeemLink}
              termsLink={couponTermsLink}
              textColour={couponTextColour}
              useBy={couponUseBy}
            />
            <SampleCoupon
              backgroundColour={couponBackgroundColour}
              borderColour={couponBorderColour}
              borderThickness={couponBorderThickness}
              borderType={couponBorderType}
              code={couponCode}
              fontFamily={couponFontFamily}
              fontSize={couponFontSize}
              fontWeight={couponFontWeight}
              margin={couponMargin}
              padding={couponPadding}
              redeemBy={couponRedeemBy}
              redeemFrom={couponRedeemFrom}
              redeemLink={couponRedeemLink}
              termsLink={couponTermsLink}
              textColour={couponTextColour}
              useBy={couponUseBy}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Coupons;
