import { useState } from "react";
import { DraggableIconCard, Tabs } from "../../elements/_Elements";
import {
  CalendarIcon,
  CartIcon,
  CheckIcon,
  CreditCardIcon,
  EnvelopeIcon,
  RotateIcon,
  TicketIcon,
  TimesIcon,
  UserIcon,
} from "../../Icons";

function TriggerElements() {
  const [activeTab, setActiveTab] = useState("Booking");

  const tabs = [
    { title: "Booking" },
    { title: "Contacts" },
    { title: "Membership" },
  ];

  return (
    <>
      <Tabs
        activeTab={activeTab}
        onClick={(tab) => setActiveTab(tab.title)}
        tabs={tabs}
      />
      <div className="sidebar-content position-relative flex-fill my-3">
        {activeTab === "Booking" && (
          <>
            <DraggableIconCard
              icon={CartIcon}
              iconClassName="rounded-start text-white bg-booking"
              id="g4.trigger.booking.bookingmade"
              title="Booking Made"
            />
            <DraggableIconCard
              icon={EnvelopeIcon}
              iconClassName="rounded-start text-white bg-booking"
              id="g4.trigger.booking.bookingconfirmationrequired"
              title="Booking Confirmation Required"
            />
            <DraggableIconCard
              icon={CartIcon}
              iconClassName="rounded-start text-white bg-booking"
              id="g4.trigger.booking.bookingcancelled"
              title="Booking Cancelled"
            />
            <DraggableIconCard
              icon={TicketIcon}
              iconClassName="rounded-start text-white bg-booking"
              id="g4.trigger.booking.couponshared"
              title="Coupon Shared"
            />
            <DraggableIconCard
              icon={CartIcon}
              iconClassName="rounded-start text-white bg-booking"
              id="g4.trigger.booking.firstactivity"
              title="First Activity"
            />
            <DraggableIconCard
              icon={CartIcon}
              iconClassName="rounded-start text-white bg-booking"
              id="g4.trigger.booking.lastactivity"
              title="Last Activity"
            />
            <DraggableIconCard
              icon={CreditCardIcon}
              iconClassName="rounded-start text-white bg-booking"
              id="g4.trigger.booking.paybylink"
              title="Pay By Link"
            />
            <DraggableIconCard
              icon={TicketIcon}
              iconClassName="rounded-start text-white bg-booking"
              id="g4.trigger.booking.vouchershared"
              title="Voucher Shared"
            />
          </>
        )}
        {activeTab === "Contacts" && (
          <>
            <DraggableIconCard
              icon={UserIcon}
              iconClassName="rounded-start text-white bg-contact"
              id="g4.trigger.contact.birthday"
              title="Contact Birthday"
            />
            <DraggableIconCard
              icon={UserIcon}
              iconClassName="rounded-start text-white bg-contact"
              id="g4.trigger.contact.signup"
              title="Signup"
            />
            <DraggableIconCard
              icon={UserIcon}
              iconClassName="rounded-start text-white bg-contact"
              id="g4.trigger.contact.temporaryaccess"
              title="Temporary Access"
            />
            <DraggableIconCard
              icon={UserIcon}
              iconClassName="rounded-start text-white bg-contact"
              id="g4.trigger.contact.update"
              title="Update Details"
            />
            <DraggableIconCard
              icon={UserIcon}
              iconClassName="rounded-start text-white bg-contact"
              id="g4.trigger.contact.unsubscribe"
              title="Unsubscribe"
            />
          </>
        )}
        {activeTab === "Membership" && (
          <>
            <DraggableIconCard
              icon={TimesIcon}
              iconClassName="rounded-start text-white bg-membership"
              id="g4.trigger.membership.cancelled"
              title="Membership Cancelled"
            />
            <DraggableIconCard
              icon={CheckIcon}
              iconClassName="rounded-start text-white bg-membership"
              id="g4.trigger.membership.reactivated"
              title="Membership Reactivated"
            />
            <DraggableIconCard
              icon={CalendarIcon}
              iconClassName="rounded-start text-white bg-membership"
              id="g4.trigger.membership.enddate"
              title="Membership End Date"
            />
            <DraggableIconCard
              icon={RotateIcon}
              iconClassName="rounded-start text-white bg-membership"
              id="g4.trigger.membership.renewal"
              title="Membership Renewal"
            />
          </>
        )}
      </div>
    </>
  );
}

export default TriggerElements;
