import { ActualCoupon, SampleCoupon } from "./Coupon";

function CouponControl({ properties }) {
  const {
    backgroundColour,
    borderColour,
    borderThickness,
    borderType,
    code,
    fontFamily,
    fontSize,
    fontWeight,
    localeCode,
    longDateFormat,
    margin,
    padding,
    redeemBy,
    redeemFrom,
    redeemLink,
    termsLink,
    textColour,
    useBy,
  } = properties;
  return (
    <div
      className="plugin-placeholder"
      data-plugin-type="Green4.CRM.Agent.Plugins.CouponPlugin"
      data-plugin-assembly="Green4.CRM.Agent.Plugins"
      data-contains-xslt="true"
      data-plugin-inline-css="true"
    >
      <div
        data-xsl-wrap="xsl:template"
        data-xsl-attributes="match='Coupon'"
      >
        <ActualCoupon
          {...{
            backgroundColour,
            borderColour,
            borderThickness,
            borderType,
            code,
            fontFamily,
            fontSize,
            fontWeight,
            localeCode,
            longDateFormat,
            margin,
            padding,
            redeemBy,
            redeemFrom,
            redeemLink,
            termsLink,
            textColour,
            useBy,
          }}
        />
        <SampleCoupon
          {...{
            backgroundColour,
            borderColour,
            borderThickness,
            borderType,
            code,
            fontFamily,
            fontSize,
            fontWeight,
            localeCode,
            longDateFormat,
            margin,
            padding,
            redeemBy,
            redeemFrom,
            redeemLink,
            termsLink,
            textColour,
            useBy,
          }}
        />
      </div>
    </div>
  );
}

export default CouponControl;
