import { useState } from "react";
import classNames from "classnames";
import { useFormReducer } from "../FormContext";
import { Tabs } from "../../elements/_Elements";
import SidebarForm from "../SidebarForm";
import { DesignMode } from "./enums";
import { changeDesignMode } from "./Helpers";

function CommunicationDesignerControlForm({
  handleChange,
  handlePicklistChange,
  isEditingControl,
  saveUndoSnapshot,
  setFieldValue,
  stages,
  state,
  values,
}) {
  const dispatch = useFormReducer();

  const [activeTab, setActiveTab] = useState(stages[0].title);

  const filteredStages = stages.filter((s) =>
    s.displayIfFromValues ? s.displayIfFromValues(values) : true
  );

  //saveUndoSnapshot should be null when we are editing a control.
  //We don't want to call saveUndoSnapshot when editing a control because
  //we only want to save the snapshot when apply and close is clicked
  const handleChangeValue = (event) => {
    if (!isEditingControl && saveUndoSnapshot) {
      saveUndoSnapshot(values);
    }
    handleChange(event);
  };

  const handlePicklistChangeValue = (event) => {
    if (!isEditingControl && saveUndoSnapshot) {
      saveUndoSnapshot(values);
    }
    handlePicklistChange(event);
  };

  const changeTab = (tab) => {
    setActiveTab(tab.title);
    // change the design mode if the user clicked on Controls or Rows
    if (tab.title === "Controls") {
      changeDesignMode(dispatch, DesignMode.Controls, values);
    } else if (tab.title === "Rows") {
      changeDesignMode(dispatch, DesignMode.Rows, values);
    }
  };

  return (
    <>
      <Tabs
        activeTab={activeTab}
        onClick={(tab) => changeTab(tab)}
        tabs={filteredStages}
      />
      <div className="sidebar-content position-relative flex-fill my-3">
        <div
          className={classNames(
            "position-absolute top-0 end-0 bottom-0 start-0 p-2",
            isEditingControl ? "overflow-y-scroll" : ""
          )}
        >
          <SidebarForm
            {...{
              activeTab,
              setFieldValue,
              state,
              values,
            }}
            handleChange={handleChangeValue}
            handleFilteredPicklistChange={(event, _, name) => {
              const value = event ? event.value : "";
              setFieldValue(name, value);
            }}
            handlePicklistChange={handlePicklistChangeValue}
            stages={filteredStages}
            useTabs={true}
          />
        </div>
      </div>
    </>
  );
}

export default CommunicationDesignerControlForm;
