import {
  BackgroundColour,
  BorderColour,
  BorderThickness,
  BorderType,
  FontFamily,
  FontSize,
  FontWeight,
  Margin,
  Padding,
  TextColour,
} from "../../../CommonProperties";

const VoucherControlFormStages = [
  {
    title: "Content",
    sections: [
      {
        name: "Options",
        fields: [
          {
            name: "redeemLink",
            description: "",
            label: "Redeem Link",
            type: "nvarchar",
          },
          {
            name: "termsLink",
            description: "",
            label: "Terms Link",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Locale",
        fields: [
          {
            name: "localeCode",
            description: "",
            label: "Locale Code",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Date Format",
        fields: [
          {
            name: "longDateFormat",
            description: "",
            label: "Long Date Format",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Style",
    sections: [
      {
        name: "Content",
        fields: [
          BackgroundColour(""),
          TextColour(""),
          FontFamily(""),
          FontSize(""),
          FontWeight(""),
          Margin(""),
          Padding(""),
          BorderType(""),
          BorderThickness(""),
          BorderColour(""),
        ],
      },
    ],
  },
];

export default VoucherControlFormStages;
