import { LoadingState } from "../../../js/enums";
import { GetAllEntityBasicInfo } from "../../../js/service";

//---------------------General------------------------------
const getDataFromStateLookupOrServices = async (
  globalDispatch,
  lookupData,
  setLoading,
  setInitialLoad
) => {
  lookupData.forEach(async (l) => {
    const [response] = await Promise.all([
      l.lookupOptions &&
      l.lookupOptions.data &&
      l.lookupOptions.data.length > 0
        ? l.lookupOptions
        : GetAllEntityBasicInfo(
            globalDispatch,
            l.entityName,
            l.searchFilter ? l.searchFilter : null
          ),
    ]);
    l.setData(response && response.data ? response.data : []);
  });
  setLoading(LoadingState.Loaded);
  setInitialLoad(false);
};

//---------------------Product Bundle Items-----------------
const bundleItemsFieldName = "productBundleItems";

const calculateTotalPercentage = (productBundleItems) => {
  let result = 0;
  productBundleItems.forEach((item) => {
    if (item.Percentage && item.Percentage > 0) {
      result = result + item.Percentage;
    }
  });
  return result;
};

const getValidationMessageForBundleItems = (
  productBundleItems,
  setAlertMessage
) => {
  if (!productBundleItems || productBundleItems.length === 0) {
    setAlertMessage(null);
  } else if (!productBundleItems.every((p) => p.VariantTypeId)) {
    setAlertMessage(
      "All bundle items must have a Default Variant Type set"
    );
  } else if (
    productBundleItems.some(
      (p) => p.Percentage > 0 && p.FixedPrice > 0
    )
  ) {
    setAlertMessage(
      "It is not possible to set both a fixed price and a percentage on a bundle item. Please update these values."
    );
  } else if (
    productBundleItems.filter(
      (p) =>
        (!p.Percentage || p.Percentage === 0) &&
        (!p.FixedPrice || p.FixedPrice === 0)
    ).length > 1
  ) {
    setAlertMessage(
      "It is not possible to have more than one bundle item with no fixed price and percentage values set. Please update these values."
    );
  } else if (calculateTotalPercentage(productBundleItems) > 100) {
    setAlertMessage(
      "It is not possible to have the total percentage exceed 100%. Please update these values."
    );
  } else if (
    productBundleItems.some(
      (p) => p.Percentage < 0 || p.FixedPrice < 0
    )
  ) {
    setAlertMessage(
      "It is not possible to set a negative value on the fixed price or percentage fields on a bundle item. Please update these values."
    );
  } else if (
    productBundleItems.filter((p) => p.SetAsParentProductBundleItem)
      .length > 1
  ) {
    setAlertMessage(
      "There should be only one bundle item with Set as Parent Product Bundle Item selected. Please update these values."
    );
  } else {
    setAlertMessage(null);
  }
};

const handleProductBundleItemChange = (
  propertyName,
  value,
  productId,
  productBundleItems,
  handleChange
) => {
  const newValue = productBundleItems.map((productBundleItem) => {
    if (productBundleItem.ProductId === productId) {
      return {
        ...productBundleItem,
        VariantTypeId:
          propertyName === "variantTypeId"
            ? value
            : productBundleItem.VariantTypeId,
        FixedPrice:
          propertyName === "fixedPrice"
            ? value
            : productBundleItem.FixedPrice,
        Percentage:
          propertyName === "percentage"
            ? value
            : productBundleItem.Percentage,
        FixedRevenueShare:
          propertyName === "fixedRevenueShare"
            ? value
            : productBundleItem.FixedRevenueShare,
        CollectGiftAid:
          propertyName === "collectGiftAid"
            ? value
            : productBundleItem.CollectGiftAid,
        SetAsParentProductBundleItem:
          propertyName === "setAsParentProductBundleItem"
            ? value
            : productBundleItem.SetAsParentProductBundleItem,
      };
    } else if (propertyName === "setAsParentProductBundleItem") {
      //If the changed property was setAsParentProductBundleItem then be sure
      //to set this property on the rest of the items to false since we can
      //only have one set to true
      return {
        ...productBundleItem,
        SetAsParentProductBundleItem: false,
      };
    }
    return productBundleItem;
  });

  handleChange({
    target: {
      name: bundleItemsFieldName,
      value: newValue,
    },
  });
};

//---------------------Product Variants---------------------
const variantsfieldName = "productVariants";

const handlePeakPriceChange = (
  variantTypeId,
  variantPriceListId,
  peakPrice,
  productVariants,
  handleChange
) => {
  const newValue = productVariants.map((productVariant) => {
    if (productVariant.VariantTypeId === variantTypeId) {
      // Update the price change for the matching variant type
      return {
        ...productVariant,
        PriceListItems: productVariant.PriceListItems.map(
          (priceListItem) => {
            if (
              priceListItem.VariantPriceListId === variantPriceListId
            ) {
              return {
                ...priceListItem,
                PeakPrice: peakPrice,
              };
            }
            return priceListItem;
          }
        ),
      };
    }
    // For all other variant types, return the original value
    return productVariant;
  });

  handleChange({
    target: {
      name: variantsfieldName,
      value: newValue,
    },
  });
};

const handleProductVariantChange = (
  propertyName,
  value,
  variantTypeId,
  productVariants,
  handleChange
) => {
  const newValue = productVariants.map((productVariant) => {
    if (productVariant.VariantTypeId === variantTypeId) {
      return {
        ...productVariant,
        Code: propertyName === "code" ? value : productVariant.Code,
        MandatoryVariant:
          propertyName === "mandatoryVariant"
            ? value
            : productVariant.MandatoryVariant,
        MarketingListId:
          propertyName === "marketingListId"
            ? value
            : productVariant.MarketingListId,
        NotForSale:
          propertyName === "notForSale"
            ? value
            : productVariant.NotForSale,
        TaxCodeId:
          propertyName === "taxCodeId"
            ? value
            : productVariant.TaxCodeId,
      };
    }
    return productVariant;
  });

  handleChange({
    target: {
      name: variantsfieldName,
      value: newValue,
    },
  });
};

const handleOffPeakPriceChange = (
  variantTypeId,
  variantPriceListId,
  offPeakPrice,
  productVariants,
  usePeakPrice,
  handleChange
) => {
  const newValue = productVariants.map((productVariant) => {
    if (productVariant.VariantTypeId === variantTypeId) {
      // Update the price change for the matching variant type
      return {
        ...productVariant,
        PriceListItems: productVariant.PriceListItems.map(
          (priceListItem) => {
            if (
              priceListItem.VariantPriceListId === variantPriceListId
            ) {
              return {
                ...priceListItem,
                PeakPrice: usePeakPrice
                  ? priceListItem.PeakPrice
                  : offPeakPrice,
                OffPeakPrice: offPeakPrice,
              };
            }
            return priceListItem;
          }
        ),
      };
    }
    // For all other variant types, return the original value
    return productVariant;
  });

  handleChange({
    target: {
      name: variantsfieldName,
      value: newValue,
    },
  });
};

const handleVariantPriceListChange = (
  variantPriceListId,
  productVariants,
  handleChange
) => {
  const newValue = productVariants.map((productVariant) => {
    let newPriceListItems = [...productVariant.PriceListItems];
    newPriceListItems.push({
      OffPeakPrice: "",
      PeakPrice: "",
      VariantPriceListId: variantPriceListId,
      VariantTypeId: productVariant.VariantTypeId,
    });

    return {
      ...productVariant,
      PriceListItems: newPriceListItems,
    };
  });

  handleChange({
    target: {
      name: variantsfieldName,
      value: newValue,
    },
  });
};

export {
  getDataFromStateLookupOrServices,
  getValidationMessageForBundleItems,
  handlePeakPriceChange,
  handleProductBundleItemChange,
  handleProductVariantChange,
  handleOffPeakPriceChange,
  handleVariantPriceListChange,
};
