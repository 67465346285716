import React, { useState } from "react";
import { AlertStyle, ButtonStyle } from "../../../js/enums";
import { useGlobalReducer } from "../../../GlobalContext";
import { useFormReducer } from "../FormContext";
import { Update } from "../../../js/service";
import {
  Alert,
  Button,
  Input,
  Modal,
} from "../../elements/_Elements";
import { handleRedirect } from "../Helpers";

function AnonymiseContactModal({
  setDisplayAnonymiseModal,
  isSubmitting,
  setIsSubmitting,
  state,
}) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();
  const [reason, setReason] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const anonymise = async () => {
    setIsSubmitting(true);
    setErrorMessage("");
    try {
      const successMessage = "Contact anonymised";
      const [serviceResponse] = await Promise.all([
        Update(
          globalDispatch,
          successMessage,
          {
            Id: state.id,
            Name: state.name,
            Attributes: {
              reason: reason,
            },
          },
          "contact",
          "ticketing/anonymisecontact"
        ),
      ]);
      if (serviceResponse && serviceResponse.data) {
        handleRedirect(null, dispatch, state.id, "refreshPage");
      } else if (
        serviceResponse &&
        serviceResponse.response &&
        serviceResponse.response.status === 400 &&
        serviceResponse.response.data
      ) {
        setErrorMessage(serviceResponse.response.data);
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={"Anonymise Contact"}
      modalCloseButtonClick={() => setDisplayAnonymiseModal(false)}
      className="modal modal-dialog-scrollable"
    >
      <div className="modal-body">
        <small>
          {
            "This will remove all data relating to the contact such as name, phone numbers and address details. Are you sure you want to anonymise this contact? This action cannot be reversed once confirmed. You must also provide a reason for anonymising the contact"
          }
        </small>
        <Input
          label={"Reason:"}
          mandatory={true}
          name={"Reason"}
          onChange={(event) => setReason(event.target.value)}
          value={reason}
        />
        {errorMessage && (
          <Alert style={AlertStyle.Danger} text={errorMessage} />
        )}
      </div>
      <div className="modal-footer">
        <Button
          text={"Confirm"}
          style={ButtonStyle.Primary}
          onClick={() => anonymise()}
          showSpinner={isSubmitting}
          disabled={!reason || reason.trim() === "" || isSubmitting}
        />
        <Button
          text={"Cancel"}
          style={ButtonStyle.Info}
          onClick={() => setDisplayAnonymiseModal(false)}
          showSpinner={isSubmitting}
          disabled={isSubmitting}
        />
      </div>
    </Modal>
  );
}

export default AnonymiseContactModal;
