import { getPageAllowedRoles } from "./roles";

const navigationItems = [
  {
    id: "configuration",
    text: "Configuration",
    subItems: [
      {
        id: "productManagement",
        text: "Product",
        links: [
          {
            identifiers: ["/products", "/product"],
            to: "/products",
            text: "Products",
            allowedRoles: getPageAllowedRoles("product"),
          },
          {
            identifiers: ["/variant-types", "/variant-type"],
            to: "/variant-types",
            text: "Variant Types",
            allowedRoles: getPageAllowedRoles("variant-type"),
          },
          {
            identifiers: ["/product-categories", "/product-category"],
            to: "/product-categories",
            text: "Categories",
            allowedRoles: getPageAllowedRoles("product-category"),
          },
          {
            identifiers: ["/product-calendars", "/product-calendar"],
            to: "/product-calendars",
            text: "Calendars",
            allowedRoles: getPageAllowedRoles("product-calendar"),
          },
          {
            identifiers: ["/groups", "/group"],
            to: "/groups",
            text: "Groups",
            allowedRoles: getPageAllowedRoles("group"),
          },
          {
            identifiers: [
              "/additional-products",
              "/additional-product",
            ],
            to: "/additional-products",
            text: "Additional Products",
            allowedRoles: getPageAllowedRoles("additional-product"),
          },
          {
            identifiers: ["/coupon-types", "/coupon-type"],
            to: "/coupon-types",
            text: "Coupon Types",
            allowedRoles: getPageAllowedRoles("coupon-type"),
          },
        ],
      },
      {
        id: "venueManagement",
        text: "Venue",
        links: [
          {
            identifiers: [
              "/access-control-providers",
              "/access-control-provider",
            ],
            to: "/access-control-providers",
            text: "Access Control Providers",
            allowedRoles: getPageAllowedRoles(
              "access-control-provider"
            ),
          },
          {
            identifiers: ["/access-points", "/access-point"],
            to: "/access-points",
            text: "Access Points",
            allowedRoles: getPageAllowedRoles("access-point"),
          },
          {
            identifiers: [
              "/bookable-resources",
              "/bookable-resource",
            ],
            to: "/bookable-resources",
            text: "Bookable Resources",
            allowedRoles: getPageAllowedRoles("bookable-resource"),
          },
          {
            identifiers: ["/channels", "/channel"],
            to: "/channels",
            text: "Channels",
            allowedRoles: getPageAllowedRoles("channel"),
          },
          {
            identifiers: ["/operators", "/operator"],
            to: "/operators",
            text: "Operators",
            allowedRoles: getPageAllowedRoles("operator"),
          },
          {
            identifiers: ["/operator-groups", "/operator-group"],
            to: "/operator-groups",
            text: "Operator Groups",
            allowedRoles: getPageAllowedRoles("operator-group"),
          },
          {
            identifiers: ["/payment-methods", "/payment-method"],
            to: "/payment-methods",
            text: "Payment Methods",
            allowedRoles: getPageAllowedRoles("payment-method"),
          },
          {
            identifiers: ["/questions", "/question"],
            to: "/questions",
            text: "Questions",
            allowedRoles: getPageAllowedRoles("question"),
          },
          {
            identifiers: ["/tax-codes", "/tax-code"],
            to: "/tax-codes",
            text: "Tax Codes",
            allowedRoles: getPageAllowedRoles("tax-code"),
          },
          {
            identifiers: ["/till-groups", "/till-group"],
            to: "/till-groups",
            text: "Till Groups",
            allowedRoles: getPageAllowedRoles("till-group"),
          },
          {
            identifiers: ["/terminals", "/terminal"],
            to: "/terminals",
            text: "Terminals",
            allowedRoles: getPageAllowedRoles("terminal"),
          },
          {
            identifiers: ["/zones", "/zone"],
            to: "/zones",
            text: "Zones",
            allowedRoles: getPageAllowedRoles("zone"),
          },
        ],
      },
      {
        id: "fixturesAndSeries",
        description:
          "These pages will only display if checked on this page and the 'Enable Fixtures and Series' field is checked on the Admin Settings page",
        text: "Fixtures & Series",
        links: [
          {
            identifiers: ["/fixtures", "/fixture"],
            to: "/fixtures",
            text: "Fixtures",
            allowedRoles: getPageAllowedRoles("fixture"),
          },
          {
            identifiers: ["/series", "/serie"],
            to: "/series",
            text: "Series",
            allowedRoles: getPageAllowedRoles("series"),
          },
          {
            identifiers: ["/venues", "/venue"],
            to: "/venues",
            text: "Venues",
            allowedRoles: getPageAllowedRoles("venue"),
          },
          {
            identifiers: ["/blocks", "/block"],
            to: "/blocks",
            text: "Blocks",
            allowedRoles: getPageAllowedRoles("block"),
          },
          {
            identifiers: ["/seat-classes", "/seat-class"],
            to: "/seat-classes",
            text: "Seat Classes",
            allowedRoles: getPageAllowedRoles("seat-class"),
          },
        ],
      },
      {
        id: "pricingManagement",
        text: "Pricing",
        links: [
          {
            identifiers: ["/sales-plans", "/sales-plan"],
            to: "/sales-plans",
            text: "Sales Plans",
            allowedRoles: getPageAllowedRoles("sales-plan"),
          },
          {
            identifiers: [
              "/channel-price-lists",
              "/channel-price-list",
            ],
            to: "/channel-price-lists",
            text: "Channel Price Lists",
            allowedRoles: getPageAllowedRoles("channel-price-list"),
          },
          {
            identifiers: [
              "/variant-price-lists",
              "/variant-price-list",
            ],
            to: "/variant-price-lists",
            text: "Variant Price Lists",
            allowedRoles: getPageAllowedRoles("variant-price-list"),
          },
          {
            identifiers: [
              "/price-break-groups",
              "/price-break-group",
              "/price-break",
            ],
            to: "/price-break-groups",
            text: "Price Break Groups",
            allowedRoles: getPageAllowedRoles("price-break-group"),
          },
        ],
      },
      {
        id: "promotions",
        text: "Promotions",
        links: [
          {
            identifiers: ["/promotions", "/promotion"],
            to: "/promotions",
            text: "Promotions",
            allowedRoles: getPageAllowedRoles("promotion"),
          },
          {
            identifiers: ["/promotion-types", "/promotion-type"],
            to: "/promotion-types",
            text: "Types",
            allowedRoles: getPageAllowedRoles("promotion-type"),
          },
          {
            identifiers: [
              "/promotion-exclusion-dates",
              "/promotion-exclusion-date",
            ],
            to: "/promotion-exclusion-dates",
            text: "Exclusion Dates",
            allowedRoles: getPageAllowedRoles(
              "promotion-exclusion-date"
            ),
          },
          {
            identifiers: [
              "/promotion-product-groups",
              "/promotion-product-group",
            ],
            to: "/promotion-product-groups",
            text: "Product Groups",
            allowedRoles: getPageAllowedRoles(
              "promotion-product-group"
            ),
          },
        ],
      },
      {
        id: "printing",
        text: "Printing",
        links: [
          {
            identifiers: ["/print-transforms", "/print-transform"],
            to: "/print-transforms",
            text: "Print Transforms",
            allowedRoles: getPageAllowedRoles("print-transform"),
          },
          {
            identifiers: ["/printers", "/printer"],
            to: "/printers",
            text: "Printers",
            allowedRoles: getPageAllowedRoles("printer"),
          },
        ],
      },
      {
        id: "emailMarketing",
        text: "Email Marketing",
        links: [
          {
            identifiers: ["/communications", "/communication"],
            to: "/communications",
            text: "Communications",
            allowedRoles: getPageAllowedRoles("communication"),
          },
          {
            identifiers: [
              "/communication-audiences",
              "/communication-audience",
            ],
            to: "/communication-audiences",
            text: "Audiences",
            allowedRoles: getPageAllowedRoles(
              "communication-audience"
            ),
          },
          {
            identifiers: [
              "/communication-flows",
              "/communication-flow",
            ],
            to: "/communication-flows",
            text: "Flows",
            allowedRoles: getPageAllowedRoles("communication-flow"),
          },
          {
            identifiers: ["/marketing-lists", "/marketing-list"],
            to: "/marketing-lists",
            text: "Marketing Lists",
            allowedRoles: getPageAllowedRoles("marketing-list"),
          },
          {
            identifiers: ["/abtests", "/abtest"],
            to: "/abtests",
            text: "AB Tests",
            allowedRoles: getPageAllowedRoles("abtest"),
          },
          {
            identifiers: ["/campaigns", "/campaign"],
            to: "/campaigns",
            text: "Campaigns",
            allowedRoles: getPageAllowedRoles("campaign"),
          },
          {
            identifiers: [
              "/subscription-groups",
              "/subscription-group",
            ],
            to: "/subscription-groups",
            text: "Subscription Groups",
            allowedRoles: getPageAllowedRoles("subscription-group"),
          },
          {
            to: "/blacklist",
            description:
              "Enable only if the client is trusted to responsibly manage blacklisted email addresses",
            text: "Blacklist and Mail Exclusion",
            allowedRoles: getPageAllowedRoles("blacklist"),
          },
          {
            to: "/communication-settings",
            text: "Communication Settings",
            allowedRoles: getPageAllowedRoles(
              "communication-settings"
            ),
          },
        ],
      },
      {
        id: "settings",
        text: "Settings",
        links: [
          {
            to: "/admin-settings",
            text: "Admin Settings",
            allowedRoles: getPageAllowedRoles("admin-settings"),
          },
          {
            to: "/global-settings",
            text: "Global Settings",
            allowedRoles: getPageAllowedRoles("global-settings"),
          },
          {
            to: "/sitemap",
            text: "Sitemap",
            allowedRoles: getPageAllowedRoles("sitemap"),
          },
          {
            identifiers: ["/cities", "/city"],
            to: "/cities",
            text: "Cities",
            allowedRoles: getPageAllowedRoles("city"),
          },
          {
            identifiers: ["/regions", "/region"],
            to: "/regions",
            text: "Regions",
            allowedRoles: getPageAllowedRoles("region"),
          },
          {
            identifiers: ["/countries", "/country"],
            to: "/countries",
            text: "Countries",
            allowedRoles: getPageAllowedRoles("country"),
          },
          {
            identifiers: ["/on-hold-reasons", "/on-hold-reason"],
            to: "/on-hold-reasons",
            text: "On Hold Reasons",
            allowedRoles: getPageAllowedRoles("on-hold-reason"),
          },
        ],
      },
    ],
  },
  {
    id: "transactions",
    text: "Transactions",
    links: [
      {
        identifiers: ["/accounts", "/account"],
        to: "/accounts",
        text: "Accounts",
        allowedRoles: getPageAllowedRoles("account"),
      },
      {
        identifiers: ["/bookings", "/booking"],
        to: "/bookings",
        text: "Bookings",
        allowedRoles: getPageAllowedRoles("booking"),
      },
      {
        identifiers: ["/contacts", "/contact", "/on-hold-audit"],
        to: "/contacts",
        text: "Contacts",
        allowedRoles: getPageAllowedRoles("contact"),
      },
      {
        identifiers: ["/coupons", "/coupon"],
        to: "/coupons",
        text: "Coupons",
        allowedRoles: getPageAllowedRoles("coupon"),
      },
      {
        identifiers: [
          "/donation-batch-headers",
          "/donation-batch-header",
        ],
        to: "/donation-batch-headers",
        text: "Donation Batch Headers",
        allowedRoles: getPageAllowedRoles("donation-batch-header"),
      },
      {
        identifiers: ["/membership-accounts", "/membership-account"],
        to: "/membership-accounts",
        text: "Membership Accounts",
        allowedRoles: getPageAllowedRoles("membership-account"),
      },
      {
        identifiers: ["/payment-batches", "/payment-batch"],
        to: "/payment-batches",
        text: "Payment Batches",
        allowedRoles: getPageAllowedRoles("payment-batch"),
      },
      {
        identifiers: ["/vouchers", "/voucher"],
        to: "/vouchers",
        text: "Vouchers",
        allowedRoles: getPageAllowedRoles("voucher"),
      },
    ],
  },
  {
    id: "reporting",
    text: "Reporting",
    links: [
      {
        to: "/journal-daily-reports",
        text: "Journal Daily Reports",
        allowedRoles: getPageAllowedRoles("journal-daily-reports"),
      },
      {
        to: "/operational-report",
        text: "Operational",
        allowedRoles: getPageAllowedRoles("operational-report"),
      },
      {
        to: "/financial-report",
        text: "Financial",
        allowedRoles: getPageAllowedRoles("financial-report"),
      },
    ],
    subItems: [
      {
        id: "analysis-reports",
        text: "Analysis Reports",
        links: [
          {
            to: "/contacts-report",
            text: "Contacts",
            allowedRoles: getPageAllowedRoles("contacts-report"),
          },
          {
            to: "/email-marketing-report",
            text: "Email Marketing",
            allowedRoles: getPageAllowedRoles(
              "email-marketing-report"
            ),
          },
          {
            to: "/memberships-report",
            text: "Memberships",
            allowedRoles: getPageAllowedRoles("memberships-report"),
          },
          {
            to: "/sales-and-bookings-report",
            text: "Sales and Bookings",
            allowedRoles: getPageAllowedRoles(
              "sales-and-bookings-report"
            ),
          },
        ],
      },
    ],
  },
];

function getNavigationItems() {
  return navigationItems.reduce((result, item) => {
    if (item.links && item.links.length > 0) {
      result.push(item);
    }
    if (item.subItems) {
      item.subItems.forEach((subItem) => {
        if (subItem.links && subItem.links.length > 0) {
          result.push(subItem);
        }
      });
    }
    return result;
  }, []);
}

export { getNavigationItems, navigationItems };
