import { DragType, When } from "./Enums";
import {
  ArrowLeft,
  ArrowRight,
  CalendarIcon,
  CartIcon,
  CheckIcon,
  CreditCardIcon,
  DotCircleIcon,
  EnvelopeIcon,
  PlusIcon,
  RefreshIcon,
  TicketIcon,
  TimesIcon,
  UserIcon,
} from "../../Icons";
import { GlobalDispatchMethods } from "../../../js/enums";
import { DispatchMethods } from "../enums";
import lookups from "../../../forms/lookups";

const genders = ["Male", "Female"];
const ageGroups = [
  "0-12",
  "13-18",
  "19-21",
  "22-30",
  "31-40",
  "41-50",
  "51-60",
  "61-70",
  "71+",
];
const yearsOfBirth = [
  "1920s",
  "1930s",
  "1940s",
  "1950s",
  "1960s",
  "1970s",
  "1980s",
  "1990s",
  "2000s",
  "2010s",
];
const milestones = [
  "16",
  "18",
  "21",
  "30",
  "40",
  "50",
  "60",
  "70",
  "80",
  "90",
  "100",
];

const applyTriggerChanges = (dispatch, state, values) => {
  const {
    canBeCancelled,
    editTrigger,
    flow_data,
    interval,
    numberOfCommunications,
    timepart,
    trigger,
    when,
  } = values;
  const { index } = editTrigger;

  // update the flow data
  let flow = flow_data[index];
  flow.CanBeCancelled = canBeCancelled;
  flow.Interval = when !== String(When.Immediately) ? interval : 0;
  flow.Timepart = when !== String(When.Immediately) ? timepart : null;
  flow.When = when;

  // populate the communications
  const communicationTrigger = getLookupForTrigger(trigger);
  const communicationLookupOptions = state.lookupOptions.find(
    (x) => x.name === communicationTrigger.name
  );
  const productGroupLookupOptions = state.lookupOptions.find(
    (x) => x.name === lookups.g4b_productgroup.all.name
  );
  if (!communicationLookupOptions || !productGroupLookupOptions) {
    throw new Error("Invalid lookup data");
  }

  let communications = [];
  for (let i = 1; i <= numberOfCommunications; i++) {
    const communicationId = values[`communication${i}`];
    const communication = communicationLookupOptions.data.find(
      (x) => x.Key === communicationId
    );
    if (!communication) {
      throw new Error("Invalid communication");
    }

    let comTagsList = [];
    const tags = values[`tags${i}`];
    tags.forEach((tag) => {
      const productTag = productGroupLookupOptions.data.find(
        (x) => x.Key === tag
      );
      if (productTag) {
        comTagsList.push(productTag.Value);
      } else {
        comTagsList.push(tag);
      }
    });

    communications.push({
      ComTags: comTagsList.join("; "),
      ComTagsList: comTagsList,
      Name: communication.Value,
      RegardingType: flow.RegardingType,
      StartType: 2,
      StatusCode: communication.StatusCode,
      Tags: values[`tags${i}`],
      Value: communication.Key,
    });
  }
  flow.Communications = communications;

  validateFlowData(flow_data);

  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      flow_data: flow_data,
      editTrigger: "",
    },
  });
};

const cancelTriggerChanges = (dispatch, values) => {
  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      ...values,
      editTrigger: "",
    },
  });
};

const cloneTrigger = (
  globalDispatch,
  index,
  setFieldValue,
  values
) => {
  // prevent if a trigger is being edited
  if (values.editTrigger) {
    globalDispatch({
      type: GlobalDispatchMethods.AddNotification,
      notification: {
        message: "Cannot clone while a trigger is being edited",
        success: false,
      },
      dispatch: globalDispatch,
    });
    return;
  }

  let trigger = values.flow_data[index];
  const flowDataCopy = JSON.parse(JSON.stringify(values.flow_data));
  flowDataCopy.splice(index, 0, trigger);

  updateControlIndexes(flowDataCopy);

  setFieldValue("flow_data", flowDataCopy);
};

const deleteTrigger = (
  globalDispatch,
  index,
  setFieldValue,
  values
) => {
  // prevent if a trigger is being edited
  if (values.editTrigger) {
    globalDispatch({
      type: GlobalDispatchMethods.AddNotification,
      notification: {
        message: "Cannot delete while a trigger is being edited",
        success: false,
      },
      dispatch: globalDispatch,
    });
    return;
  }

  const flowData = values.flow_data.filter((_, i) => i !== index);
  validateFlowData(flowData);

  setFieldValue("flow_data", flowData);
};

const displayCanBeCancelled = (values) => {
  const { trigger, when } = values;

  switch (trigger) {
    case "g4.trigger.booking.firstactivity":
    case "g4.trigger.booking.lastactivity":
      return true;
    case "g4.trigger.booking.bookingmade":
    case "g4.trigger.booking.bookingconfirmationrequired":
    case "g4.trigger.booking.paybylink":
    case "g4.trigger.membership.reactivated":
    case "g4.trigger.membership.enddate":
    case "g4.trigger.membership.renewal":
      return String(when) === String(When.After);
    default:
      return false;
  }
};

const getCanBeCancelledLabel = (trigger) => {
  switch (trigger) {
    case "g4.trigger.booking.bookingmade":
    case "g4.trigger.booking.bookingconfirmationrequired":
    case "g4.trigger.booking.firstactivity":
    case "g4.trigger.booking.lastactivity":
    case "g4.trigger.booking.paybylink":
      return "Abandon when booking cancelled";
    case "g4.trigger.membership.reactivated":
    case "g4.trigger.membership.enddate":
    case "g4.trigger.membership.renewal":
      return "Abandon when membership cancelled";
    default:
      return "";
  }
};

const getCaption = (trigger) => {
  switch (trigger) {
    case "g4.trigger.booking.bookingmade":
      return "Booking Made";
    case "g4.trigger.booking.bookingconfirmationrequired":
      return "Booking Confirmation Required";
    case "g4.trigger.booking.bookingcancelled":
      return "Booking Cancelled";
    case "g4.trigger.booking.couponshared":
      return "Coupon Shared";
    case "g4.trigger.booking.firstactivity":
      return "First Activity";
    case "g4.trigger.booking.lastactivity":
      return "Last Activity";
    case "g4.trigger.booking.paybylink":
      return "Pay By link";
    case "g4.trigger.booking.vouchershared":
      return "Voucher Shared";
    case "g4.trigger.contact.birthday":
      return "Contact Birthday";
    case "g4.trigger.contact.signup":
      return "Signup";
    case "g4.trigger.contact.temporaryaccess":
      return "Temporary Access";
    case "g4.trigger.contact.update":
      return "Update Details";
    case "g4.trigger.contact.unsubscribe":
      return "Unsubscribe";
    case "g4.trigger.membership.cancelled":
      return "Membership Cancelled";
    case "g4.trigger.membership.reactivated":
      return "Membership Reactivated";
    case "g4.trigger.membership.enddate":
      return "Membership End Date";
    case "g4.trigger.membership.renewal":
      return "Membership Renewal";
    default:
      return "";
  }
};

const getCaptionName = (trigger) => {
  switch (trigger) {
    case "g4.trigger.booking.bookingmade":
      return "Booking - Booking Made";
    case "g4.trigger.booking.bookingconfirmationrequired":
      return "Booking - Booking Confirmation Required";
    case "g4.trigger.booking.bookingcancelled":
      return "Booking - Booking Cancelled";
    case "g4.trigger.booking.couponshared":
      return "Booking - Coupon Shared";
    case "g4.trigger.booking.firstactivity":
      return "Booking - First Activity";
    case "g4.trigger.booking.lastactivity":
      return "Booking - Last Activity";
    case "g4.trigger.booking.paybylink":
      return "Booking - Pay By link";
    case "g4.trigger.booking.vouchershared":
      return "Booking - Voucher Shared";
    case "g4.trigger.contact.birthday":
      return "Contacts - Contact Birthday";
    case "g4.trigger.contact.signup":
      return "Contacts - Signup";
    case "g4.trigger.contact.temporaryaccess":
      return "Contacts - Temporary Access";
    case "g4.trigger.contact.update":
      return "Contacts - Update Details";
    case "g4.trigger.contact.unsubscribe":
      return "Contacts - Unsubscribe";
    case "g4.trigger.membership.cancelled":
      return "Membership - Membership Cancelled";
    case "g4.trigger.membership.reactivated":
      return "Membership - Membership Reactivated";
    case "g4.trigger.membership.enddate":
      return "Membership - Membership End Date";
    case "g4.trigger.membership.renewal":
      return "Membership - Membership Renewal";
    default:
      return "";
  }
};

const getDragType = (activeId) => {
  return activeId.indexOf("movetrigger") === 0
    ? DragType.MoveTrigger
    : DragType.NewTrigger;
};

const getErrorsForFlow = (state) => {
  const { flow_data } = state;

  let errors = [];

  // check for unassigned comms
  if (flow_data.some((control) => control.UnassignedCommunications)) {
    errors.push("Not all communication have been assigned");
    // if the communications are not all assigned then return just this error
    return errors;
  }

  // check for unassigned tags
  if (flow_data.some((control) => control.UnassignedTags)) {
    errors.push("Not all tags have been assigned");
  }

  // check for communications, which aren't published, if flow is in live mode
  if (
    flow_data.some((control) => control.InvalidStatusCommunications)
  ) {
    errors.push("Not all communications have been published");
  }

  // check for incorrect regarding object for comms
  if (flow_data.some((control) => control.InvalidRegardingType)) {
    errors.push(
      "Not all communications are regarding the same type of entity as the trigger"
    );
  }

  // check comms are for flows
  if (flow_data.some((control) => control.InvalidCommunications)) {
    errors.push(
      "Not all communications are designated for use with Flows"
    );
  }

  return errors;
};

const getIconForFlowTrigger = (trigger) => {
  let icon, iconClassName;

  switch (trigger) {
    case "g4.trigger.booking.bookingmade":
      icon = CartIcon;
      iconClassName = "bg-booking";
      break;
    case "g4.trigger.booking.bookingconfirmationrequired":
      icon = EnvelopeIcon;
      iconClassName = "bg-booking";
      break;
    case "g4.trigger.booking.bookingcancelled":
      icon = CartIcon;
      iconClassName = "bg-booking";
      break;
    case "g4.trigger.booking.couponshared":
      icon = TicketIcon;
      iconClassName = "bg-booking";
      break;
    case "g4.trigger.booking.firstactivity":
      icon = CartIcon;
      iconClassName = "bg-booking";
      break;
    case "g4.trigger.booking.lastactivity":
      icon = CartIcon;
      iconClassName = "bg-booking";
      break;
    case "g4.trigger.booking.paybylink":
      icon = CreditCardIcon;
      iconClassName = "bg-booking";
      break;
    case "g4.trigger.booking.vouchershared":
      icon = TicketIcon;
      iconClassName = "bg-booking";
      break;
    case "g4.trigger.contact.birthday":
      icon = UserIcon;
      iconClassName = "bg-contact";
      break;
    case "g4.trigger.contact.signup":
      icon = UserIcon;
      iconClassName = "bg-contact";
      break;
    case "g4.trigger.contact.temporaryaccess":
      icon = UserIcon;
      iconClassName = "bg-contact";
      break;
    case "g4.trigger.contact.update":
      icon = UserIcon;
      iconClassName = "bg-contact";
      break;
    case "g4.trigger.contact.unsubscribe":
      icon = UserIcon;
      iconClassName = "bg-contact";
      break;
    case "g4.trigger.membership.cancelled":
      icon = TimesIcon;
      iconClassName = "bg-membership";
      break;
    case "g4.trigger.membership.reactivated":
      icon = CheckIcon;
      iconClassName = "bg-membership";
      break;
    case "g4.trigger.membership.enddate":
      icon = CalendarIcon;
      iconClassName = "bg-membership";
      break;
    case "g4.trigger.membership.renewal":
      icon = RefreshIcon;
      iconClassName = "bg-membership";
      break;
    default:
      icon = PlusIcon;
      iconClassName = "bg-success";
  }

  return { icon, iconClassName };
};

const getIconForWhen = (when) => {
  if (when === 2) {
    return ArrowLeft;
  }
  if (when === 3) {
    return ArrowRight;
  }
  return DotCircleIcon;
};

const getLookupForTrigger = (trigger) => {
  switch (trigger) {
    case "g4.trigger.booking.bookingmade":
    case "g4.trigger.booking.bookingconfirmationrequired":
    case "g4.trigger.booking.bookingcancelled":
    case "g4.trigger.booking.firstactivity":
    case "g4.trigger.booking.lastactivity":
    case "g4.trigger.booking.paybylink":
      return lookups.g4c_communication.bookingFlow;
    case "g4.trigger.contact.birthday":
    case "g4.trigger.contact.signup":
    case "g4.trigger.contact.temporaryaccess":
    case "g4.trigger.contact.update":
    case "g4.trigger.contact.unsubscribe":
      return lookups.g4c_communication.contactFlow;
    case "g4.trigger.booking.couponshared":
      return lookups.g4c_communication.couponFlow;
    case "g4.trigger.membership.cancelled":
    case "g4.trigger.membership.reactivated":
    case "g4.trigger.membership.enddate":
    case "g4.trigger.membership.renewal":
      return lookups.g4c_communication.membershipFlow;
    case "g4.trigger.booking.vouchershared":
      return lookups.g4c_communication.voucherFlow;
    default:
      throw new Error("Invalid trigger");
  }
};

const getOptionsForTrigger = (trigger, lookupData) => {
  let tags = [];
  switch (trigger) {
    case "g4.trigger.booking.bookingmade":
      tags = [
        {
          Key: "Finance Payment",
          Value: "Finance Payment",
        },
        ...lookupData,
      ];
      break;
    case "g4.trigger.booking.bookingconfirmationrequired":
      tags = [
        {
          Key: "Unassigned Beneficiaries",
          Value: "Unassigned Beneficiaries",
        },
        ...lookupData,
      ];
      break;
    case "g4.trigger.booking.firstactivity":
      tags = [
        {
          Key: "First Ever",
          Value: "First Ever",
        },
        {
          Key: "First In Booking",
          Value: "First In Booking",
        },
        ...lookupData,
      ];
      break;
    case "g4.trigger.contact.birthday":
      tags = [
        ...genders.map((value) => ({
          Key: value,
          Value: value,
        })),
        ...ageGroups.map((value) => ({
          Key: value,
          Value: value,
        })),
        ...yearsOfBirth.map((value) => ({
          Key: value,
          Value: value,
        })),
        ...milestones.map((value) => ({
          Key: value,
          Value: value,
        })),
      ];
      break;
    case "g4.trigger.contact.signup":
      tags = [
        ...genders.map((value) => ({
          Key: value,
          Value: value,
        })),
        ...ageGroups.map((value) => ({
          Key: value,
          Value: value,
        })),
        ...yearsOfBirth.map((value) => ({
          Key: value,
          Value: value,
        })),
      ];
      break;
    case "g4.trigger.booking.lastactivity":
    case "g4.trigger.membership.cancelled":
    case "g4.trigger.membership.reactivated":
    case "g4.trigger.membership.enddate":
    case "g4.trigger.membership.renewal":
      tags = lookupData;
      break;
    case "g4.trigger.booking.bookingcancelled":
    case "g4.trigger.booking.couponshared":
    case "g4.trigger.booking.paybylink":
    case "g4.trigger.booking.vouchershared":
    case "g4.trigger.contact.temporaryaccess":
    case "g4.trigger.contact.update":
    case "g4.trigger.contact.unsubscribe":
    default:
      tags = [];
      break;
  }

  return tags;
};

const getTriggerRegardingType = (trigger) => {
  switch (trigger) {
    case "g4.trigger.booking.bookingmade":
    case "g4.trigger.booking.bookingconfirmationrequired":
    case "g4.trigger.booking.bookingcancelled":
    case "g4.trigger.booking.firstactivity":
    case "g4.trigger.booking.lastactivity":
    case "g4.trigger.booking.paybylink":
      return "g4b_booking";
    case "g4.trigger.booking.couponshared":
      return "g4b_coupon";
    case "g4.trigger.booking.vouchershared":
      return "g4b_voucher";
    case "g4.trigger.contact.birthday":
    case "g4.trigger.contact.signup":
    case "g4.trigger.contact.temporaryaccess":
    case "g4.trigger.contact.update":
    case "g4.trigger.contact.unsubscribe":
      return "contact";
    case "g4.trigger.membership.cancelled":
    case "g4.trigger.membership.reactivated":
    case "g4.trigger.membership.enddate":
    case "g4.trigger.membership.renewal":
      return "g4m_membership";
    default:
      return "";
  }
};

const getWhenOptions = (trigger) => {
  const whenOptions = Object.entries(When).map(([key, value]) => ({
    Key: value,
    Value: key,
  }));
  switch (trigger) {
    case "g4.trigger.booking.couponshared":
    case "g4.trigger.booking.vouchershared":
    case "g4.trigger.contact.temporaryaccess":
      return whenOptions.filter(
        (x) => x.Key !== When.Before && x.Key !== When.After
      );
    case "g4.trigger.booking.bookingmade":
    case "g4.trigger.booking.bookingconfirmationrequired":
    case "g4.trigger.booking.bookingcancelled":
    case "g4.trigger.booking.paybylink":
    case "g4.trigger.contact.signup":
    case "g4.trigger.contact.update":
    case "g4.trigger.contact.unsubscribe":
    case "g4.trigger.membership.cancelled":
    case "g4.trigger.membership.reactivated":
      return whenOptions.filter((x) => x.Key !== When.Before);
    default:
      return whenOptions;
  }
};

const getWhenText = (interval, timePart, when) => {
  if (when.toString() === "1") {
    return "Immediately on ";
  }

  let timePartText = "Hour(s)";
  switch (timePart.toString()) {
    case "2":
      timePartText = "Day(s)";
      break;
    case "3":
      timePartText = "Week(s)";
      break;
    case "4":
      timePartText = "Month(s)";
      break;
    case "5":
      timePartText = "Year(s)";
      break;
    case "1":
    default:
      timePartText = "Hour(s)";
      break;
  }
  const whenText = when === 2 ? "Before" : "After";

  return `${interval} ${timePartText} ${whenText} `;
};

const handleDragEnd = (event, setFieldValue, state, values) => {
  const { active, over } = event;

  if (!active || !over) {
    return;
  }

  const dragType = getDragType(active.id);
  if (dragType === null) {
    return;
  }

  const { g4ca_flowmode } = state;

  // deep copy the triggers to avoid changing the original value
  let flowData = [];
  const flowDataCopy = JSON.parse(JSON.stringify(values.flow_data));

  switch (dragType) {
    case DragType.NewTrigger:
      flowData = handleNewTrigger(
        flowDataCopy,
        active.id,
        over.id,
        g4ca_flowmode
      );
      break;
    case DragType.MoveTrigger:
      flowData = handleMoveTrigger(flowDataCopy, active.id, over.id);
      break;
    default:
      break;
  }

  if (flowData === null || flowData.length === 0) {
    return;
  }

  validateFlowData(flowData);

  // update the rules value
  setFieldValue("flow_data", flowData);
};

const handleMoveTrigger = (result, activeId, overId) => {
  // determine the indexes
  const overIdentifiers = overId.split("-");
  if (overIdentifiers.length !== 2) {
    return null;
  }

  const index = parseInt(overIdentifiers[1]);

  // trigger being moved
  const activeIdentifiers = activeId.split("-");
  const triggerIndex = parseInt(activeIdentifiers[1]);

  // remember the trigger being removed
  const trigger = result[triggerIndex];

  // remove the trigger
  result.splice(triggerIndex, 1);

  // add the trigger back in
  const newTriggerIndex = triggerIndex < index ? index - 1 : index;
  result.splice(newTriggerIndex, 0, trigger);

  return result;
};

const handleNewTrigger = (result, activeId, overId, flowMode) => {
  // determine the indexes
  const overIdentifiers = overId.split("-");
  if (overIdentifiers.length !== 2) {
    return null;
  }

  const index = parseInt(overIdentifiers[1]);

  const newTrigger = {
    BackgroundColor: "",
    CanBeCancelled: false,
    CancelTrigger: "",
    CancelType: null,
    Communications: [
      {
        ComTags: "",
        ComTagsList: [],
        Name: "",
        RegardingType: "",
        StartType: null,
        StatusCode: null,
        Tags: [],
        Valid: 2,
        Value: null,
      },
    ],
    FlowMode: flowMode,
    Index: 0,
    Interval: null,
    RegardingType: getTriggerRegardingType(activeId),
    Timepart: null,
    Trigger: activeId,
    When: When.Immediately,
  };

  if (!result) {
    result = [newTrigger];
  } else {
    // add the new rule
    result.splice(index, 0, newTrigger);
  }

  return result;
};

const setEditTrigger = (
  dispatch,
  globalDispatch,
  index,
  flowControl,
  values
) => {
  // prevent if a trigger is being edited
  if (values.editTrigger) {
    globalDispatch({
      type: GlobalDispatchMethods.AddNotification,
      notification: {
        message: "Cannot edit while a trigger is being edited",
        success: false,
      },
      dispatch: globalDispatch,
    });
    return;
  }

  if (!flowControl) {
    return;
  }

  const {
    CanBeCancelled,
    Communications,
    Interval,
    Timepart,
    Trigger,
    When,
  } = flowControl;

  let properties = [];
  Communications.forEach((communication, index) => {
    properties[`communication${index + 1}`] = communication.Value;
    properties[`tags${index + 1}`] = communication.Tags;
  });

  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      ...values,
      canBeCancelled: CanBeCancelled,
      interval: Interval,
      editTrigger: {
        index: index,
      },
      numberOfCommunications: Communications.length,
      timepart: Timepart,
      trigger: Trigger,
      when: When,
      ...properties,
    },
  });
};

const updateControlIndexes = (flowData) => {
  if (!flowData || flowData.length === 0) {
    return;
  }

  let index = 0;
  flowData.forEach((control) => {
    control.Index = index;
    index++;
  });
};

const validateFlowData = (flowData, flowMode = null) => {
  if (!flowData || flowData.length === 0) {
    return;
  }

  updateControlIndexes(flowData);

  flowData.forEach((control) => {
    const controlFlowMode = flowMode
      ? flowMode.toString()
      : control.FlowMode.toString();

    // set the valid status for each communication
    let index = 1;
    control.Communications.forEach((communication) => {
      communication.Valid =
        (index < control.Communications.Length &&
          communication.Tags.Length === 0) ||
        (controlFlowMode === "2" && communication.StatusCode !== 4) ||
        control.RegardingType !== communication.RegardingType ||
        communication.StartType !== 2
          ? 2
          : 1;
      index++;
    });

    // check for unassigned communications
    control.UnassignedCommunications = control.Communications.some(
      (x) => !x.Value
    );

    // check for unassigned tags
    let unassignedTags = false;
    for (
      let index = 0;
      index < control.Communications.Length - 1;
      index++
    ) {
      if (control.Communications[index].Tags.Length === 0) {
        unassignedTags = true;
        break;
      }
    }
    control.UnassignedTags = unassignedTags;

    // check for invalid status
    control.InvalidStatusCommunications =
      controlFlowMode === "2" &&
      control.Communications.some((x) => x.StatusCode !== 4);

    // check for invalid regarding type
    control.InvalidRegardingType = control.Communications.some(
      (x) => x.RegardingType !== control.RegardingType
    );

    // check for invalid type of communications
    control.InvalidCommunications = control.Communications.some(
      (x) => x.StartType !== 2
    );

    // set the Valid property for the control
    control.Valid =
      control.UnassignedCommunications ||
      control.UnassignedTags ||
      control.InvalidStatusCommunications ||
      control.InvalidRegardingType ||
      control.InvalidCommunications
        ? 2
        : 1;
  });
};

export {
  applyTriggerChanges,
  cancelTriggerChanges,
  cloneTrigger,
  deleteTrigger,
  displayCanBeCancelled,
  getCanBeCancelledLabel,
  getCaption,
  getCaptionName,
  getErrorsForFlow,
  getIconForFlowTrigger,
  getIconForWhen,
  getLookupForTrigger,
  getOptionsForTrigger,
  getWhenOptions,
  getWhenText,
  handleDragEnd,
  setEditTrigger,
  validateFlowData,
};
