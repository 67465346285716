import {
  BookingControl,
  ButtonControl,
  CouponControl,
  DividerControl,
  ImageControl,
  ImageTextControl,
  MembershipRenewalControl,
  SocialFollowControl,
  TextControl,
  VoucherControl,
} from "./controls";
import { mapControlProperties } from "./Helpers";

function CommunicationControl({ name, properties, template }) {
  const controlProperties = mapControlProperties(
    properties,
    template
  );

  return (
    <div control-name={name}>
      {template === "booking-control" ? (
        <BookingControl properties={controlProperties} />
      ) : template === "button-control" ? (
        <ButtonControl properties={controlProperties} />
      ) : template === "coupon-control" ? (
        <CouponControl properties={controlProperties} />
      ) : template === "divider-control" ? (
        <DividerControl properties={controlProperties} />
      ) : template === "image-control" ? (
        <ImageControl properties={controlProperties} />
      ) : template === "image-text-control" ? (
        <ImageTextControl properties={controlProperties} />
      ) : template === "membership-renewal-control" ? (
        <MembershipRenewalControl properties={controlProperties} />
      ) : template === "social-follow-control" ? (
        <SocialFollowControl properties={controlProperties} />
      ) : template === "text-control" ? (
        <TextControl properties={controlProperties} />
      ) : template === "voucher-control" ? (
        <VoucherControl properties={controlProperties} />
      ) : (
        <p>Undefined control</p>
      )}
    </div>
  );
}

export default CommunicationControl;
