import "./selectWithFiltering.scss";
import {
  ABTestPublishedStatus,
  BackgroundStyle,
  BookingStatus,
  CommunicationActivityStatus,
  CommunicationPublishedStatus,
  CouponVoucherStatus,
  DeliveryFulfilmentStatus,
  DonationBatchHeaderStatus,
  DonationBatchLineStatus,
  DonationStatus,
  MandateStatus,
  PaymentStatus,
} from "../../js/enums";
import Badge from "./Badge";

function StatusBadge({ enumList, value }) {
  const getStatusBadge = () => {
    switch (enumList) {
      case ABTestPublishedStatus:
        const abTestPublishedStatusText =
          value && value > 0
            ? String(
                Object.keys(ABTestPublishedStatus).find(
                  (key) => ABTestPublishedStatus[key] === value
                )
              )
            : "";
        return abTestPublishedStatusText ? (
          <Badge
            backgroundStyle={
              abTestPublishedStatusText === "Published"
                ? BackgroundStyle.Success
                : BackgroundStyle.Info
            }
            className="me-2"
            item={{ text: abTestPublishedStatusText }}
          />
        ) : (
          ""
        );
      case BookingStatus:
        const bookingStatusText =
          value && value > 0
            ? String(
                Object.keys(BookingStatus).find(
                  (key) => BookingStatus[key] === value
                )
              )
            : "";
        return bookingStatusText ? (
          <Badge
            backgroundStyle={
              bookingStatusText === "Active"
                ? BackgroundStyle.Success
                : bookingStatusText === "Inactive" ||
                  bookingStatusText === "Incomplete"
                ? BackgroundStyle.Danger
                : bookingStatusText === "Reserved"
                ? BackgroundStyle.Warning
                : BackgroundStyle.Info
            }
            className="me-2"
            item={{ text: bookingStatusText }}
          />
        ) : (
          ""
        );
      case CommunicationActivityStatus:
        const commActivityText =
          value !== null && value !== undefined && value >= 0
            ? String(
                Object.keys(CommunicationActivityStatus).find(
                  (key) => CommunicationActivityStatus[key] === value
                )
              )
            : "";
        return commActivityText ? (
          <Badge
            backgroundStyle={
              commActivityText === "Completed"
                ? BackgroundStyle.Success
                : commActivityText === "Cancelled"
                ? BackgroundStyle.Danger
                : commActivityText === "Scheduled"
                ? BackgroundStyle.Warning
                : BackgroundStyle.Info
            }
            className="me-2"
            item={{ text: commActivityText }}
          />
        ) : (
          ""
        );
      case CommunicationPublishedStatus:
        const communicationPublishedStatusText =
          value && value > 0
            ? String(
                Object.keys(CommunicationPublishedStatus).find(
                  (key) => CommunicationPublishedStatus[key] === value
                )
              )
            : "";
        return communicationPublishedStatusText ? (
          <Badge
            backgroundStyle={
              communicationPublishedStatusText === "Published"
                ? BackgroundStyle.Success
                : communicationPublishedStatusText === "Inactive"
                ? BackgroundStyle.Danger
                : communicationPublishedStatusText === "Testing"
                ? BackgroundStyle.Warning
                : BackgroundStyle.Info
            }
            className="me-2"
            item={{ text: communicationPublishedStatusText }}
          />
        ) : (
          ""
        );
      case CouponVoucherStatus:
        const couponVoucherStatusText =
          value && value > 0
            ? String(
                Object.keys(CouponVoucherStatus).find(
                  (key) => CouponVoucherStatus[key] === value
                )
              )
            : "";
        return couponVoucherStatusText ? (
          <Badge
            backgroundStyle={
              couponVoucherStatusText === "Available"
                ? BackgroundStyle.Success
                : couponVoucherStatusText === "Expired" ||
                  couponVoucherStatusText === "Cancelled"
                ? BackgroundStyle.Danger
                : BackgroundStyle.Warning
            }
            className="me-2"
            item={{ text: couponVoucherStatusText }}
          />
        ) : (
          ""
        );
      case DeliveryFulfilmentStatus:
        const deliveryFulfilmentText =
          value !== null && value !== undefined && value >= 0
            ? String(
                Object.keys(DeliveryFulfilmentStatus).find(
                  (key) => DeliveryFulfilmentStatus[key] === value
                )
              )
            : "";
        return deliveryFulfilmentText ? (
          <Badge
            backgroundStyle={
              deliveryFulfilmentText === "Delivered"
                ? BackgroundStyle.Success
                : BackgroundStyle.Info
            }
            className="me-2"
            item={{ text: deliveryFulfilmentText }}
          />
        ) : (
          ""
        );
      case DonationStatus:
        const donationStatusText =
          value !== null && value !== undefined && value >= 0
            ? String(
                Object.keys(DonationStatus).find(
                  (key) => DonationStatus[key] === value
                )
              )
            : "";
        return donationStatusText ? (
          <Badge
            backgroundStyle={
              donationStatusText === "Approved"
                ? BackgroundStyle.Success
                : donationStatusText === "Cancelled" ||
                  donationStatusText === "Not Eligible" ||
                  donationStatusText === "Rejected"
                ? BackgroundStyle.Danger
                : donationStatusText === "Pending" ||
                  donationStatusText === "Unprocessed" ||
                  donationStatusText === "Sent"
                ? BackgroundStyle.Warning
                : BackgroundStyle.Info
            }
            className="me-2"
            item={{ text: donationStatusText }}
          />
        ) : (
          ""
        );
      case DonationBatchHeaderStatus:
        //If value is in the format of an object with EntityName, Id and Name, then the Id value is what we want.
        //Otherwise if value is just the number then read it directly
        const val =
          value !== null && value !== undefined
            ? value.Id >= 0
              ? value.Id
              : value >= 0
              ? value
              : null
            : null;
        const donationBatchHeaderStatusText =
          val >= 0
            ? String(
                Object.keys(DonationBatchHeaderStatus).find(
                  (key) => DonationBatchHeaderStatus[key] === val
                )
              )
            : "";
        return donationBatchHeaderStatusText ? (
          <Badge
            backgroundStyle={
              donationBatchHeaderStatusText === "Processed"
                ? BackgroundStyle.Success
                : donationBatchHeaderStatusText === "Failed"
                ? BackgroundStyle.Danger
                : donationBatchHeaderStatusText === "Sent"
                ? BackgroundStyle.Warning
                : BackgroundStyle.Info
            }
            className="me-2"
            item={{ text: donationBatchHeaderStatusText }}
          />
        ) : (
          ""
        );
      case DonationBatchLineStatus:
        const donationBatchLineStatusText =
          value !== null && value !== undefined && value >= 0
            ? String(
                Object.keys(DonationBatchLineStatus).find(
                  (key) => DonationBatchLineStatus[key] === value
                )
              )
            : "";
        return donationBatchLineStatusText ? (
          <Badge
            backgroundStyle={
              donationBatchLineStatusText === "Accepted"
                ? BackgroundStyle.Success
                : donationBatchLineStatusText === "Rejected"
                ? BackgroundStyle.Danger
                : donationBatchLineStatusText === "Sent"
                ? BackgroundStyle.Warning
                : BackgroundStyle.Info
            }
            className="me-2"
            item={{ text: donationBatchLineStatusText }}
          />
        ) : (
          ""
        );
      case MandateStatus:
        const mandateText =
          value && value > 0
            ? String(
                Object.keys(MandateStatus).find(
                  (key) => MandateStatus[key] === value
                )
              )
            : "";
        return mandateText ? (
          <Badge
            backgroundStyle={
              mandateText === "Active"
                ? BackgroundStyle.Success
                : mandateText === "Inactive"
                ? BackgroundStyle.Danger
                : BackgroundStyle.Info
            }
            className="me-2"
            item={{ text: mandateText }}
          />
        ) : (
          ""
        );
      case PaymentStatus:
        const paymentStatusText =
          value && value > 0
            ? String(
                Object.keys(PaymentStatus).find(
                  (key) => PaymentStatus[key] === value
                )
              )
            : "";
        return paymentStatusText ? (
          <Badge
            backgroundStyle={
              paymentStatusText === "Approved"
                ? BackgroundStyle.Success
                : paymentStatusText === "Cancelled" ||
                  paymentStatusText === "Rejected"
                ? BackgroundStyle.Danger
                : paymentStatusText === "Scheduled" ||
                  paymentStatusText === "In Progress"
                ? BackgroundStyle.Warning
                : BackgroundStyle.Info
            }
            className="me-2"
            item={{ text: paymentStatusText }}
          />
        ) : (
          ""
        );
      default:
        return "";
    }
  };

  return getStatusBadge();
}

export default StatusBadge;
