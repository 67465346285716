import {
  FontFamilies,
  FontSizes,
  FontWeights,
  LineTypes,
} from "../../../enums";

const CouponControlDefaultValues = [
  {
    Name: "localeCode",
    Value: "en-GB",
  },
  {
    Name: "longDateFormat",
    Value: "dddd dd MMM yyyy",
  },
  {
    Name: "backgroundColour",
    Value: "#dddddd",
  },
  {
    Name: "textColour",
    Value: "#333333",
  },
  {
    Name: "fontFamily",
    Value: String(FontFamilies["Arial, Helvetica, sans-serif"]),
  },
  {
    Name: "fontSize",
    Value: String(FontSizes["12px"]),
  },
  {
    Name: "fontWeight",
    Value: String(FontWeights.Normal),
  },
  {
    Name: "margin",
    Value: "5px",
  },
  {
    Name: "padding",
    Value: "10px",
  },
  {
    Name: "borderType",
    Value: String(LineTypes.Solid),
  },
  {
    Name: "borderThickness",
    Value: "1px",
  },
  {
    Name: "borderColour",
    Value: "#333333",
  },
  {
    Name: "code",
    Value: "Code:",
  },
  {
    Name: "redeemFrom",
    Value: "Valid From:",
  },
  {
    Name: "redeemBy",
    Value: "Redeem By:",
  },
  {
    Name: "useBy",
    Value: "Start Before:",
  },
  {
    Name: "redeemLink",
    Value: "",
  },
  {
    Name: "termsLink",
    Value: "",
  },
];

export default CouponControlDefaultValues;
