import { Fragment, useEffect } from "react";
import moment from "moment";
import { DispatchMethods } from "./enums";
import {
  Create,
  Get,
  GetAllEntityBasicInfo,
  GetAllEntityDetailedInfo,
  GetDefaultValuesForEntity,
  GetEntityRecordById,
  Search,
  SetRecordState,
  Update,
} from "../../js/service";
import {
  checkIfTwoArraysAreTheSame,
  convertDateTimeToUTCTimeZoneWhileMaintainingDateTimeValue,
  createDateFromToday,
  displayFieldValue,
  isSameOrBeforeDate,
  isSameOrLaterDate,
} from "../../js/utility";
import {
  CardProvider,
  DateTimePickerRangesAll,
  GlobalDispatchMethods,
  LoadingState,
  ProductType,
  RecordStatus,
} from "../../js/enums";
import { Link } from "react-router-dom";
import {
  DateTimeFilter,
  Input,
  SelectWithFiltering,
} from "../elements/_Elements";
import { MagnifyIcon } from "../Icons";

function addToSessionStorage(name, data, duration = 30) {
  // cache for 30 seconds by default
  const date = new Date();
  date.setSeconds(date.getSeconds() + duration);
  sessionStorage.setItem(
    name,
    JSON.stringify({ expiry: date, data: data })
  );
}

function allowEditOnOverview(stage, state) {
  if (stage.editOnOverviewFromState) {
    return stage.editOnOverviewFromState(state);
  } else if (stage.editOnOverview) {
    return stage.editOnOverview;
  }
  return false;
}

function anyRealCardProvider(values) {
  return (
    values !== null &&
    values.g4b_cardprovider !== undefined &&
    values.g4b_cardprovider !== CardProvider.Test
  );
}

function checkForAnyEditableStages(stages, state) {
  //Either we are able to edit a stage on overview
  //or we have some function to determine whether the edit button appears
  //However if neither is explicity set then that will cause the default
  //behaviour of showing the edit button which is the reason for the third
  //case in the or statement
  return stages.some(
    (stage) =>
      stage.editOnOverview ||
      (stage.displayEditButtonFromState &&
        stage.displayEditButtonFromState(state)) ||
      (!stage.editOnOverview && !stage.displayEditButtonFromState)
  );
}

function checkIfFieldDisabled(field, state, values) {
  // check if the field is disabled either via a flag for field.disabled being set to true
  if (field.disabled) {
    return true;
  }

  // don't disable if field has either a disabledIfFromValues or a disabledIfFromState function and it returns false
  // on either one. disabledIfFromValues checks the field against values set in the current page values
  // while disabledIfFromState checks the field against values set in the state
  const disabledFromValues =
    field.disabledIfFromValues && field.disabledIfFromValues(values);
  const disabledFromState =
    field.disabledIfFromState && field.disabledIfFromState(state);

  return disabledFromValues || disabledFromState;
}

function checkIfFieldRequired(field, state, values, globalState) {
  // check if the field is required via a flag for field.required being set to true
  // and whether the field is to be displayed by checking for set values either
  // in the saved state or the current values
  return (
    field.required &&
    displayElement(field, state, values, globalState)
  );
}

function checkIfRequiredFieldValueValid(field, values) {
  //If values[field.name] is equal to 0 then the first part of the OR in this if statement will fail
  //This is not good for a picklist where one of the options has a value of 0
  //So the check on the second part of the OR is to handle that sitaution
  //This is the same case if values[field.name] is equal to false for a field of type bit
  //so the third part handles that. This is to prevent the default value from being set on the field
  //when the value should be the values value of false

  return (
    values[field.name] ||
    (field.type === "picklist" &&
      values.hasOwnProperty(field.name) &&
      values[field.name] === 0) ||
    (field.type === "bit" &&
      values.hasOwnProperty(field.name) &&
      values[field.name] !== null &&
      values[field.name].toString() === "false")
  );
}

function checkForValidRoles(allowedRoles, globalState) {
  return (
    !allowedRoles ||
    (globalState &&
      globalState.user &&
      globalState.user.userRoles &&
      globalState.user.userRoles.length > 0 &&
      allowedRoles.some((role) => {
        return globalState.user.userRoles.includes(role);
      }))
  );
}

const createOrUpdateRecord = async (
  action,
  entityName,
  globalDispatch,
  stages,
  state,
  methodName
) => {
  let response = null;
  try {
    const { name, value } = getNameFieldValue(
      stages,
      state,
      entityName
    );

    const method = state.id ? Update : Create;
    const successMessage = state.id
      ? `${value} successfully updated`
      : `${value} successfully created`;

    const serviceResponse = await method(
      globalDispatch,
      successMessage,
      {
        Id: state.id,
        Name: name,
        Attributes: getAttributesForSave(action, stages, state),
      },
      entityName,
      methodName
    );

    response = serviceResponse.data;

    //ticketing/updateadminsettings has a setting called g4b_sellfixturesandseries which can affect which pages appear in the sidebar.
    //So we need to make sure that the initialState is reset so that we can get the updated sitemap
    if (methodName === "ticketing/updateadminsettings") {
      globalDispatch({
        type: GlobalDispatchMethods.ResetInitialState,
      });
    }
  } catch (error) {
    console.error(error);
  }

  return response;
};

function displayEditButtonOnOverview(stage, state) {
  if (stage.displayEditButtonFromState) {
    return stage.displayEditButtonFromState(state);
  }
  return true;
}

function displayElement(element, state, values, globalState) {
  // don't show if element has hidden properties
  if (element.hidden) {
    return false;
  }

  if (globalState && element.displayIfFromGlobalState) {
    return element.displayIfFromGlobalState(globalState);
  }

  // don't show if element has either a displayIfFromValues or a displayIfFromState function and it returns false
  // on either one. displayIfFromValues checks the element against values set in the current page values
  // while displayIfFromState checks the element against values set in the state
  return (
    (!element.displayIfFromValues ||
      element.displayIfFromValues(values)) &&
    (!element.displayIfFromState || element.displayIfFromState(state))
  );
}

function displayElementOnOverview(field, state, values, globalState) {
  if (field.hidden) {
    return false;
  }

  if (globalState && field.displayIfFromGlobalState) {
    return field.displayIfFromGlobalState(globalState);
  }

  if (field.displayIfFromValues || field.displayIfFromState) {
    return (
      (field.displayIfFromValues &&
        field.displayIfFromValues(values)) ||
      (field.displayIfFromValues &&
        field.displayIfFromValues(state)) ||
      (field.displayIfFromState &&
        field.displayIfFromState(values)) ||
      (field.displayIfFromState && field.displayIfFromState(state))
    );
  }
  return true;
}

function displayOverviewRender(element, state, values) {
  //Only return true if the element has an overviewRender property.
  //However if an element also has an overviewRenderCondition property,
  //then check if this property has been fulfilled before returning true
  return (
    element.overviewRender &&
    (!element.overviewRenderCondition ||
      element.overviewRenderCondition(state, values))
  );
}

function displayStage(stage, state) {
  return stage.displayIfFromState
    ? stage.displayIfFromState(state)
    : true;
}

function filterStages(stages, state, globalState) {
  return stages.filter((s) =>
    displayElement(s, state, null, globalState)
  );
}

function generateFilterQueryString(filters) {
  var filtersQueryString = "";
  //It could be the case that the current URL that we intend to put in the
  //close redirect has query string params on it already that are related to the filter
  //which could have been done when we came back to the current page from a form page.
  //So we want to make sure these have been removed so that they don't get added on top
  //of the selected filters that we do want to keep track of.
  const currentUrlParams = new URLSearchParams(
    window.location.search
  );

  for (const entry of currentUrlParams.entries()) {
    //There should only ever be one text type filter and thats the textsearch
    //in the URL which we don't want to keep from the current URL
    //We also want to make sure we don't keep any existing date time ranges
    //so that we can apply any new date time ranges from filters
    if (
      filters &&
      !filters.some((f) => f.Key && f.Key === entry[0]) &&
      entry[0] !== "textsearch" &&
      !entry[0].endsWith("-DateTimeRange") &&
      entry[0] !== "id"
    ) {
      filtersQueryString =
        filtersQueryString + "&" + entry[0] + "=" + entry[1];
    }
    //If we are in a page that has an id in the URL query param, then
    //we should make sure that we keep track of it but not to give it the same
    //key as id so that we don't confuse the id of the page we may be going to
    else if (entry[0] === "id") {
      filtersQueryString =
        filtersQueryString + "&parentid=" + entry[1];
    }
  }

  //Then we apply on the values from the filters if there is a value set
  if (filters && filters.length > 0) {
    filters.forEach((f) => {
      //If its a select type filter then we want to make sure
      //its not an empty array value
      if (
        f.Value &&
        ((f.Type === "select" && f.Value.length > 0) ||
          f.Type !== "select")
      ) {
        if (f.Key) {
          let filterValue =
            f.Type === "datetime" ? f.Value.getTime() : f.Value;
          filtersQueryString =
            filtersQueryString + "&" + f.Key + "=" + filterValue;
          let truncatedKey = f.Key.endsWith("-From")
            ? f.Key.substring(0, f.Key.length - 5)
            : f.Key.endsWith("-To")
            ? f.Key.substring(0, f.Key.length - 3)
            : f.Key;
          if (
            f.Type === "datetime" &&
            f.DateTimeRange &&
            !filtersQueryString.includes(
              truncatedKey + "-DateTimeRange"
            )
          ) {
            filtersQueryString =
              filtersQueryString +
              "&" +
              truncatedKey +
              "-DateTimeRange=" +
              f.DateTimeRange;
          }
        } else if (f.Type === "text") {
          filtersQueryString =
            filtersQueryString + "&textsearch=" + f.Value;
        }
      }
    });
  }
  return filtersQueryString;
}

function generateRedirectURL(closeRedirect) {
  const urlParams = new URLSearchParams(window.location.search);
  var filtersQueryString = "";
  for (const entry of urlParams.entries()) {
    if (
      entry &&
      entry[0] &&
      entry[0].toLowerCase() !== "closeredirect" &&
      entry[0].toLowerCase() !== "id" &&
      entry[0].toLowerCase() !== "parentid"
    ) {
      filtersQueryString = filtersQueryString
        ? filtersQueryString + "&" + entry[0] + "=" + entry[1]
        : entry[0] + "=" + entry[1];
    } else if (entry[0].toLowerCase() === "parentid") {
      filtersQueryString = filtersQueryString
        ? filtersQueryString + "&id=" + entry[1]
        : "id=" + entry[1];
    }
  }

  //if filtersQueryString has a value and closeRedirect ends with a ? so be sure not to add an extra one.
  //Otherwise add it in
  return `${closeRedirect}${
    filtersQueryString ? (closeRedirect.endsWith("?") ? "" : "?") : ""
  }${filtersQueryString}`;
}

function getAttributesForSave(action, stages, state) {
  let result = stages
    .flatMap((stage) =>
      stage.sections
        .filter((section) => section.fields)
        .flatMap((section) =>
          section.fields
            .filter((f) => !f.ignoreOnSave)
            .map((field) => {
              if (field.type === "datetime") {
                return {
                  [field.name]:
                    convertDateTimeToUTCTimeZoneWhileMaintainingDateTimeValue(
                      state[field.name]
                    ),
                };
              } else {
                return {
                  [field.name]: state[field.name],
                };
              }
            })
        )
    )
    .reduce((acc, curr) => Object.assign(acc, curr), {});

  //Add in save action attribute just in case services need to do something specific based on the save action given
  result["saveAction"] = action;
  return result;
}

function getDefaultValueForType(type) {
  switch (type) {
    case "bit":
      return false;
    case "nvarchar":
    case "ntext":
    case "int":
    case "money":
    case "float":
    case "decimal":
    case "lookup":
    case "picklist":
    default:
      return "";
  }
}

function getDescription(field, state, values, parentFormState) {
  // check if the field has an alternative description
  // and whether the condition to display it has been met in the saved state or the current values
  if (field.displayAltDescription) {
    let descriptionByValue = false;
    let descriptionByState = false;
    if (
      values &&
      field.displayAltDescription(values, parentFormState)
    ) {
      descriptionByValue = field.displayAltDescription(
        values,
        parentFormState
      );
    }

    if (
      state &&
      field.displayAltDescription(state, parentFormState)
    ) {
      descriptionByState = field.displayAltDescription(
        state,
        parentFormState
      );
    }
    return descriptionByValue || descriptionByState;
  }

  return field.description;
}

function getSectionInitiallyCollapsed(stage, state, values) {
  if (values && stage.sectionInitiallyCollapsedFromValues) {
    return stage.sectionInitiallyCollapsedFromValues(values);
  }
  if (state && stage.sectionInitiallyCollapsedFromState) {
    return stage.sectionInitiallyCollapsedFromState(state);
  }
  return false;
}

function checkRelatedEntityHasRecords(entityName, state) {
  if (state && state.relatedEntities) {
    var matchedEntity = state.relatedEntities.find(
      (r) => r.entityName === entityName
    );
    return (
      matchedEntity &&
      matchedEntity.entities &&
      matchedEntity.entities.length > 0
    );
  }
  return false;
}

function getTooltipDescription(field, state, values) {
  // check if the field has an alternative tooltip description
  // and whether the condition to display it has been met in the saved state or the current values
  if (field.displayTooltipDescription) {
    let tooltipDescriptionByValue = false;
    let tooltipDescriptionByState = false;
    if (values && field.displayTooltipDescription(values)) {
      tooltipDescriptionByValue =
        field.displayTooltipDescription(values);
    }

    if (state && field.displayTooltipDescription(state)) {
      tooltipDescriptionByState =
        field.displayTooltipDescription(state);
    }
    return tooltipDescriptionByValue || tooltipDescriptionByState;
  }

  return field.tooltipDescription;
}

function getEnum(field, state, values, globalState) {
  // check if the field has an alternative enum
  // and whether the condition to display it has been met in the saved state or the current values

  if (field.displayAltEnum) {
    let enumByValue = false;
    let enumByState = false;
    if (values && field.displayAltEnum(values)) {
      enumByValue = field.displayAltEnum(values);
    }

    if (state && field.displayAltEnum(state)) {
      enumByState = field.displayAltEnum(state);
    }

    return enumByValue || enumByState;
  }

  if (globalState && field.displayAltEnumViaGlobalState) {
    return field.displayAltEnumViaGlobalState(globalState);
  }

  return field.enum;
}

const getFieldDataType = (field) => {
  if (field === null || field === undefined) {
    return "";
  }

  switch (typeof field) {
    case "number":
      return "number";
    case "boolean":
      return "boolean";
    case "string":
      return "string";
    case "object":
      return Array.isArray(field)
        ? "array"
        : field instanceof Date
        ? "datetime"
        : "object";
    default:
      return moment(field, moment.ISO_8601, true).isValid()
        ? "datetime"
        : "";
  }
};

function getFieldInitialValue(
  state,
  field,
  urlParams,
  parentFormEntityName,
  parentFormFieldLookup,
  parentFormState,
  parentFormValues,
  linkedEntities
) {
  if (urlParams && urlParams.get(field.name)) {
    return urlParams.get(field.name);
  }

  if (checkIfRequiredFieldValueValid(field, state)) {
    return state[field.name];
  }

  if (field.autoFillValue) {
    return field.autoFillValue(
      state,
      parentFormState,
      parentFormValues,
      parentFormEntityName,
      parentFormFieldLookup
    );
  }

  if (field.default) {
    return field.default;
  }

  //linkedEntities should only be filled in
  //if we are opening a subform for a linked entity within a parent entity
  if (linkedEntities && linkedEntities.length > 0) {
    const linkedEntity = linkedEntities.find(
      (x) => x.Key === field.name
    );

    if (linkedEntity && linkedEntity.Value) {
      return linkedEntity.Value;
    }
  }

  return getDefaultValueForType(field.type);
}

function getFieldLabel(field, state, values) {
  if (field.getLabel) {
    let labelByValue = false;
    let labelByState = false;
    if (values && field.getLabel(values)) {
      labelByValue = field.getLabel(values);
    }
    if (state && field.getLabel(state)) {
      labelByState = field.getLabel(state);
    }
    return labelByValue || labelByState;
  }
  return field.label;
}

/**
 * Returns the label of a given field based on the provided stages.
 * @param {string} field - The name of the field to get the label for.
 * @param {Array} stages - An array of stages containing sections and fields.
 * @returns {string} - The label of the field, or the field name if no label is found.
 */
function getFieldLabelForFormTable(field, stages) {
  if (field.label) {
    return field.label;
  }
  if (field.name === "createdon") {
    return "Created On";
  }
  if (field.name === "modifiedon") {
    return "Modified On";
  }
  const matchingField = getFieldObject(field.name, stages);
  return matchingField ? matchingField.label : field.name;
}

function getFieldObject(field, stages, filterType) {
  if (field) {
    //If its the createdon or modifiedon fields, then return it as a field object as we don't put those
    //down as a field on the given stages
    if (field.startsWith("createdon")) {
      return { name: field, label: "Created On", type: "datetime" };
    } else if (field.startsWith("modifiedon")) {
      return { name: field, label: "Modified On", type: "datetime" };
    }
    if (stages) {
      for (const stage of stages) {
        for (const section of stage.sections) {
          if (section.fields) {
            let fieldName = field;

            //For a filterType of datetime, remove the -From or the -To from the end of the
            //given field so that we can match to the correct field name
            if (filterType === "datetime") {
              if (field.endsWith("-From")) {
                fieldName = field.substring(0, field.length - 5);
              } else if (field.endsWith("-To")) {
                fieldName = field.substring(0, field.length - 3);
              }
            }

            const matchingField = section.fields.find(
              (f) => f.name === fieldName
            );

            if (matchingField) {
              //if we got a matching field for a datetime filter then we need to return
              //a copy of the matching field but with a different name as we need to maintain
              //the -From and -To suffixes as well as making sure we don't make changes
              //to the fields themselves
              if (filterType === "datetime") {
                return {
                  name: field,
                  label: matchingField.label,
                  type: "datetime",
                };
              } else {
                return matchingField;
              }
            }
          }
        }
      }
    }
  }
  return null;
}

function getFieldValue(field, values) {
  // check for nested value
  let value = values;
  for (const name of field.name.split(".")) {
    value = value[name];
    if (value === undefined || value === null) {
      break;
    }
  }

  if (field.type === "datetime") {
    return value ? new Date(value) : null;
  }

  return value;
}

function getFilterComponent(
  allRecords,
  displayLabel,
  filter,
  filters,
  setFilters,
  state,
  subFormStages,
  values,
  displayIcons = true
) {
  const isDateTime = filter.type === "datetime";
  const isSelect = filter.type === "select";

  let options = isSelect
    ? filter.enum
      ? Object.entries(filter.enum).map(([Name, Number]) => ({
          Key: Number,
          Value: Name,
        }))
      : filter.lookup
      ? getLookupFieldOptions(filter, state, values, null)
      : filter.customLookup
      ? filter.customLookup(state)
      : allRecords
      ? getOptionsFromRecords(allRecords, filter.name)
      : []
    : [];

  return isSelect && (!options || options.length === 0) ? (
    <Fragment />
  ) : (
    <>
      {isDateTime ? (
        <DateTimeFilter
          displayLabel={displayLabel}
          filter={filter}
          filters={filters}
          setFilters={(values) => setFilters(values)}
          subFormStages={subFormStages}
        />
      ) : isSelect ? (
        <SelectWithFiltering
          label={
            displayLabel
              ? getFieldLabelForFormTable(filter, subFormStages)
              : ""
          }
          description={filter.description}
          isMulti={true}
          name={filter.name}
          options={[{ Key: "", Value: "" }, ...options]}
          onChange={(event) =>
            tableHandleFilterChange(
              filter,
              event,
              filters,
              setFilters
            )
          }
          value={
            filters.some((f) => f.Key === filter.name)
              ? filters.filter((f) => f.Key === filter.name)[0].Value
              : ""
          }
        />
      ) : (
        <Input
          checked={
            filter.type === "bit"
              ? filters.some((f) => f.Key === filter.name)
                ? filters.filter((f) => f.Key === filter.name)[0]
                    .Value
                : false
              : null
          }
          description={filter.description}
          inputIcon={
            displayIcons && filter.type === "text" ? MagnifyIcon : ""
          }
          label={
            displayLabel
              ? getFieldLabelForFormTable(filter, subFormStages)
              : ""
          }
          name={filter.name}
          onChange={(event) =>
            tableHandleFilterChange(
              filter,
              event,
              filters,
              setFilters
            )
          }
          type={filter.type}
          value={
            filters.some((f) => f.Key === filter.name)
              ? filters.filter((f) => f.Key === filter.name)[0].Value
              : ""
          }
        />
      )}
    </>
  );
}

function getFromSessionStorage(name) {
  const data = sessionStorage.getItem(name);
  if (data) {
    const parsedData = JSON.parse(data);
    if (
      parsedData.expiry &&
      new Date(parsedData.expiry) > new Date()
    ) {
      return parsedData.data;
    }
  }
  return null;
}

function getTableContentFromStateRelatedEntity(
  loadStateRelatedEntityName,
  filterByStatus,
  recordStatusToShow,
  state
) {
  var relatedEntityData = state.relatedEntities.find(
    (r) => r.entityName === loadStateRelatedEntityName
  );
  if (relatedEntityData) {
    return relatedEntityData.entities.filter((r) => {
      if (
        filterByStatus &&
        r.Fields.hasOwnProperty("statecode") &&
        String(recordStatusToShow)
      ) {
        return (
          String(r.Fields.statecode) === String(recordStatusToShow)
        );
      } else {
        return r;
      }
    });
  } else {
    return null;
  }
}

function getEditLink(
  id,
  linkForNewRecord,
  value,
  filters,
  openNewTab = false
) {
  var filtersQueryString = generateFilterQueryString(filters);
  return (
    <Link
      to={
        linkForNewRecord +
        "?id=" +
        id +
        "&closeRedirect=" +
        encodeURIComponent(window.location.pathname) +
        filtersQueryString
      }
      target={openNewTab ? "_blank" : ""}
      rel={openNewTab ? "noopener noreferrer" : ""}
    >
      {value}
    </Link>
  );
}

function getHeaderText(entityName, stages, state, title, onOverview) {
  if (entityName === "contact") {
    return (stages.length === 1 || onOverview) &&
      state.id &&
      state.fullname
      ? state.fullname
      : title;
  } else {
    return (stages.length === 1 || onOverview) &&
      state.id &&
      state.name
      ? state.name
      : title;
  }
}

function getInputType(dataType) {
  switch (dataType) {
    case "bit":
      return "checkbox";
    case "int":
    case "decimal":
    case "money":
    case "float":
      return "number";
    case "email":
      return "email";
    default:
      return "text";
  }
}

function getLinkForNewRecord(table, parentId, filters) {
  const { linkedEntityId, linkForNewRecord } = table;
  var filtersQueryString = generateFilterQueryString(filters);
  if (linkedEntityId && parentId) {
    return `${linkForNewRecord}?${linkedEntityId}=${parentId}&closeRedirect=${encodeURIComponent(
      window.location.pathname
    )}${filtersQueryString}`;
  }
  return `${linkForNewRecord}?closeRedirect=${encodeURIComponent(
    window.location.pathname
  )}${filtersQueryString}`;
}

function getLookupByName(lookup, lookupOptions) {
  return lookupOptions
    ? lookupOptions.find((l) => l.name === lookup.name)
    : null;
}

function getLookupFieldOptions(
  field,
  state,
  values,
  parentFormState
) {
  const lookupOptions =
    field.lookup &&
    state.lookupOptions &&
    state.lookupOptions.find(
      (lookup) => lookup.name === field.lookup.name
    )?.data;
  return field.filterLookupOptions
    ? field.filterLookupOptions(
        lookupOptions,
        values,
        parentFormState
      )
    : lookupOptions;
}

function getLookupsForStages(stages, state) {
  // check if there are any lookups to populate
  const lookups = [];
  stages.forEach((stage) => {
    stage.sections
      .filter((section) => section.fields)
      .forEach((section) => {
        section.fields
          .filter((field) => field.lookup || field.getLookups)
          .forEach((field) => {
            if (
              field.lookup &&
              shouldIncludeLookup(
                lookups,
                field.lookup,
                state.lookupOptions
              )
            ) {
              lookups.push(field.lookup);
            }
            const fieldLookups = field.getLookups
              ? field.getLookups(state)
              : [];
            if (fieldLookups && fieldLookups.length > 0) {
              fieldLookups.forEach((lookup) => {
                if (
                  shouldIncludeLookup(
                    lookups,
                    lookup,
                    state.lookupOptions
                  )
                ) {
                  lookups.push(lookup);
                }
              });
            }
          });
      });
    stage.sections
      .filter((section) => section.table && section.table.filters)
      .forEach((section) => {
        section.table.filters
          .filter((filter) => filter.lookup || filter.getLookups)
          .forEach((filter) => {
            if (
              filter.lookup &&
              shouldIncludeLookup(
                lookups,
                filter.lookup,
                state.lookupOptions
              )
            ) {
              lookups.push(filter.lookup);
            }
            const filterLookups = filter.getLookups
              ? filter.getLookups(state)
              : [];
            if (filterLookups && filterLookups.length > 0) {
              filterLookups.forEach((lookup) => {
                if (
                  shouldIncludeLookup(
                    lookups,
                    lookup,
                    state.lookupOptions
                  )
                ) {
                  lookups.push(lookup);
                }
              });
            }
          });
      });
  });

  return lookups;
}

function getLookupInState(state, lookup) {
  return state &&
    state.lookupOptions &&
    state.lookupOptions.some((l) => l.name === lookup.name)
    ? state.lookupOptions.find((l) => l.name === lookup.name)
    : null;
}

function getTableColumnValue(column, record) {
  return (column.linkedEntityId || column.linkById) &&
    column.linkedEntityPath
    ? getEditLink(
        column.linkById
          ? record.Id
          : record.Fields[column.linkedEntityId],
        column.linkedEntityPath,
        column.isNameField
          ? record.Name
          : record.Fields[column.fieldName],
        null,
        column.openInNewTab
      )
    : column.isNameField
    ? record.Name
    : displayFieldValue(
        record.Fields[column.fieldName],
        column.type,
        column
      );
}

function shouldIncludeLookup(lookups, lookup, lookupOptions) {
  return (
    lookup &&
    !lookups.some((l) => lookup.name === l.name) &&
    (!lookupOptions || !getLookupByName(lookup, lookupOptions))
  );
}

function getConfirmationModal(
  confirmationModal,
  onSubmit,
  setConfirmationModal,
  setFieldValue,
  state,
  values
) {
  const ModalComponent = confirmationModal.modalComponent;
  return (
    <ModalComponent
      {...{
        onSubmit,
        setConfirmationModal,
        setFieldValue,
        state,
        values,
      }}
    />
  );
}

function getCustomAction(
  customAction,
  setCustomAction,
  setFieldValue,
  state,
  values
) {
  const Component = customAction.selectedAction;
  const field = customAction.field;
  const actionLabel = customAction.selectedActionLabel;
  return (
    <Component
      {...{
        actionLabel,
        field,
        setCustomAction,
        setFieldValue,
        state,
        values,
      }}
    />
  );
}

function getCustomComponent(
  disabled,
  dispatch,
  entityName,
  errors,
  field,
  handleBlur,
  handleChange,
  handleCheckListChange,
  handleDateChange,
  handleFilteredPicklistChange,
  handlePicklistChange,
  name,
  parentFormState,
  setFieldValue,
  setSubForm,
  stages,
  state,
  touched,
  value,
  values
) {
  const Component = field.component;
  return (
    <Component
      {...{
        disabled,
        dispatch,
        entityName,
        errors,
        field,
        handleBlur,
        handleChange,
        handleCheckListChange,
        handleDateChange,
        handleFilteredPicklistChange,
        handlePicklistChange,
        name,
        parentFormState,
        setFieldValue,
        setSubForm,
        stages,
        state,
        touched,
        value,
        values,
      }}
    />
  );
}
function getDateRangeDateValues(dateRange) {
  var startOfToday = new Date(new Date().setHours(0, 0, 0, 0));
  var endOfToday = new Date(new Date().setHours(23, 59, 59, 999));
  switch (dateRange) {
    case DateTimePickerRangesAll.Yesterday:
      var yesterday = createDateFromToday(-1, 0, 0);
      return [
        new Date(yesterday.setHours(0, 0, 0, 0)),
        new Date(yesterday.setHours(23, 59, 59, 999)),
      ];
    case DateTimePickerRangesAll.Today:
      return [startOfToday, endOfToday];
    case DateTimePickerRangesAll.Tomorrow:
      var tomorrow = createDateFromToday(1, 0, 0);
      return [
        new Date(tomorrow.setHours(0, 0, 0, 0)),
        new Date(tomorrow.setHours(23, 59, 59, 999)),
      ];
    case DateTimePickerRangesAll["Next 7 Days"]:
      return [startOfToday, createDateFromToday(7, 0, 0)];
    case DateTimePickerRangesAll["Last 7 Days"]:
      return [createDateFromToday(-7, 0, 0), endOfToday];
    case DateTimePickerRangesAll["Next Month"]:
      var nextMonthFirstDay = new Date(
        startOfToday.getFullYear(),
        startOfToday.getMonth() + 1,
        1
      );
      var nextMonthLastDay = new Date(
        startOfToday.getFullYear(),
        startOfToday.getMonth() + 2,
        0 //Setting 0 for the day sets it to the last day of the previous month
      );
      return [
        new Date(nextMonthFirstDay.setHours(0, 0, 0, 0)),
        new Date(nextMonthLastDay.setHours(23, 59, 59, 999)),
      ];
    case DateTimePickerRangesAll["Last Month"]:
      var lastMonthFirstDay = new Date(
        startOfToday.getFullYear(),
        startOfToday.getMonth() - 1,
        1
      );
      var lastMonthLastDay = new Date(
        startOfToday.getFullYear(),
        startOfToday.getMonth(),
        0 //Setting 0 for the day sets it to the last day of the previous month
      );
      return [
        new Date(lastMonthFirstDay.setHours(0, 0, 0, 0)),
        new Date(lastMonthLastDay.setHours(23, 59, 59, 999)),
      ];
    case DateTimePickerRangesAll["This Month"]:
      var thisMonthFirstDay = new Date(
        startOfToday.getFullYear(),
        startOfToday.getMonth(),
        1
      );
      var thisMonthLastDay = new Date(
        startOfToday.getFullYear(),
        startOfToday.getMonth() + 1,
        0 //Setting 0 for the day sets it to the last day of the previous month
      );
      return [
        new Date(thisMonthFirstDay.setHours(0, 0, 0, 0)),
        new Date(thisMonthLastDay.setHours(23, 59, 59, 999)),
      ];
    case DateTimePickerRangesAll["Next Year"]:
      var nextYearStart = new Date(
        startOfToday.getFullYear() + 1,
        0,
        1
      );
      var nextYearEnd = new Date(
        startOfToday.getFullYear() + 1,
        11,
        31
      );
      return [
        new Date(nextYearStart.setHours(0, 0, 0, 0)),
        new Date(nextYearEnd.setHours(23, 59, 59, 999)),
      ];
    case DateTimePickerRangesAll["Last Year"]:
      var lastYearStart = new Date(
        startOfToday.getFullYear() - 1,
        0,
        1
      );
      var lastYearEnd = new Date(
        startOfToday.getFullYear() - 1,
        11,
        31
      );
      return [
        new Date(lastYearStart.setHours(0, 0, 0, 0)),
        new Date(lastYearEnd.setHours(23, 59, 59, 999)),
      ];
    case DateTimePickerRangesAll["This Year"]:
      var thisYearStart = new Date(startOfToday.getFullYear(), 0, 1);
      var thisYearEnd = new Date(startOfToday.getFullYear(), 11, 31);
      return [
        new Date(thisYearStart.setHours(0, 0, 0, 0)),
        new Date(thisYearEnd.setHours(23, 59, 59, 999)),
      ];
    default:
      return null;
  }
}

function getNameFieldValue(stages, state, entityName) {
  let name = null;
  let value = null;

  let requiresName = true;
  switch (entityName) {
    case "contact":
    case "g4b_onholdaudit":
    case "g4b_pricebreak":
    case "sitemap":
      requiresName = false;
      break;
    default:
      requiresName = true;
      break;
  }

  if (requiresName) {
    // find the name of the name field
    const nameField = stages
      .flatMap((stage) => stage.sections)
      .flatMap((section) => section.fields)
      .find((field) => field.isNameField);

    if (!nameField) {
      throw new Error("No name field found in form");
    }

    name = state[nameField.name] || null;
    value = state[nameField.name] || null;
  }

  switch (entityName) {
    case "contact":
      value = "Contact";
      break;
    case "g4_question":
      value = "Question";
      break;
    case "g4b_accesspoint":
      value = "Access point";
      break;
    case "g4b_additionalproduct":
      value = "Additional product";
      break;
    case "g4b_analysiscategory":
      value = "Category";
      break;
    case "g4b_channelquestion":
      value = "Channel question";
      break;
    case "g4_group":
      value = "Group";
      break;
    case "g4b_onholdaudit":
      value = "On Hold Audit";
      break;
    case "g4b_pricebreak":
      value = "Price Break";
      break;
    case "g4b_productcalendar":
      value = "Product calendar";
      break;
    case "g4b_reportingcategory":
      value = "Reporting category";
      break;
    case "g4b_taxcode":
      value = "Tax code";
      break;
    case "g4b_telegramqueue":
      value = "Telegram Queue";
      break;
    case "g4b_varianttypecategory":
      value = "Variant type category";
      break;
    case "g4b_varianttype":
      value = "Variant type";
      break;
    case "g4b_zone":
      value = "Zone";
      break;
    case "sitemap":
      value = "Sitemap";
      break;
    default:
  }

  return {
    name: name,
    value: value,
  };
}

function getFieldPlaceholder(field) {
  return field.placeholder !== undefined && field.placeholder !== null
    ? field.placeholder
    : "Please select";
}

function getOptionsFromRecords(allRecords, filterName) {
  const idMap = {};

  return allRecords
    .filter((record) => {
      const field = record.Fields[filterName];
      if (field) {
        if (typeof field === "string") {
          if (!idMap[field]) {
            idMap[field] = true;
            return true;
          }
        } else if (!idMap[field.Id]) {
          idMap[field.Id] = true;
          return true;
        }
      }
      return false;
    })
    .map((record) => {
      const field = record.Fields[filterName];
      if (typeof field === "string") {
        return { Key: field, Value: field };
      }
      const { Id, Name } = field;
      return { Key: Id, Value: Name };
    })
    .sort((a, b) => a.Value.localeCompare(b.Value));
}

function getOverviewIndex(stages) {
  return stages.length > 1 ? stages.length + 1 : 1;
}

function getSectionInitialFilters(section, id) {
  return section.table && section.table.filters
    ? id &&
      !section.table.useSubmitButton &&
      section.table.filters.some((f) => f.filterByParent)
      ? section.table.filters
          .filter((f) => f.filterByParent)
          .map((f) => {
            return {
              Key: f.name,
              Value: id,
              Type: "hidden",
              FilterAction: f.filterAction,
            };
          })
      : //Need to add extra check for f.defaultValue being null or undefined as if f.defaultValue is equal to 0
      //like with a false value for a field of type bit, then this would return a 0 rather than
      //true or false if we just had it to just f.defaultValue)
      section.table.filters.some(
          (f) =>
            f.defaultValue !== null || f.defaultValue !== undefined
        )
      ? section.table.filters
          .filter(
            (f) =>
              f.defaultValue !== null || f.defaultValue !== undefined
          )
          .flatMap((f) => {
            //Ignore this if the query string param has closeRedirect or and id on it
            //as the current query string params are for the previous page
            const urlParams = new URLSearchParams(
              window.location.search
            );
            var useInitialFilters = true;
            for (const entry of urlParams.entries()) {
              if (
                entry[0].toLowerCase() === "closeredirect" ||
                entry[0].toLowerCase() === "id"
              ) {
                useInitialFilters = false;
              }
            }
            if (f.type === "datetime") {
              var defaultDates = getDateRangeDateValues(
                f.dateTimeDefaultRangeType
              );
              const fromKey = f.name + "-From";
              const toKey = f.name + "-To";
              const dateTimeRangeKey = f.name + "-DateTimeRange";
              return [
                {
                  Key: fromKey,
                  DateTimeRange:
                    useInitialFilters &&
                    urlParams.has(dateTimeRangeKey)
                      ? Number(urlParams.get(dateTimeRangeKey))
                      : f.dateTimeDefaultRangeType,
                  Value:
                    useInitialFilters && urlParams.has(fromKey)
                      ? new Date(Number(urlParams.get(fromKey)))
                      : defaultDates && defaultDates[0]
                      ? defaultDates[0]
                      : f.dateFromDefaultValue,
                  Type: f.type,
                  FilterAction: f.filterAction,
                },
                {
                  Key: toKey,
                  DateTimeRange:
                    useInitialFilters &&
                    urlParams.has(dateTimeRangeKey)
                      ? Number(urlParams.get(dateTimeRangeKey))
                      : f.dateTimeDefaultRangeType,
                  Value:
                    useInitialFilters && urlParams.has(toKey)
                      ? new Date(Number(urlParams.get(toKey)))
                      : defaultDates && defaultDates[1]
                      ? defaultDates[1]
                      : f.dateToDefaultValue,
                  Type: f.type,
                  FilterAction: f.filterAction,
                },
              ];
            } else if (f.type === "select") {
              //If we are coming into this page with a query string param for the select
              //filter, then it will be in the form of a comma separated string. So we must turn this
              //into a string array
              return {
                Key: f.name,
                Value:
                  useInitialFilters && urlParams.has(f.name)
                    ? urlParams.get(f.name).split(",")
                    : f.defaultValue,
                Type: f.type,
                FilterAction: f.filterAction,
              };
            } else {
              return {
                Key: f.name,
                Value:
                  f.textFieldsSearch && urlParams.has("textsearch")
                    ? urlParams.get("textsearch")
                    : urlParams.has(f.name)
                    ? urlParams.get(f.name)
                    : f.defaultValue,
                Type: f.type,
                FilterAction: f.filterAction,
                TextFieldsSearch: f.textFieldsSearch,
              };
            }
          })
      : []
    : [];
}

function getStageNames(stages) {
  const stagesToRender = getStagesToRender(stages);
  return stagesToRender
    .map((stage) => stage.title)
    .concat("Overview");
}

function getStagesToRender(stages) {
  return stages.filter((stage) =>
    typeof stage.renderStage !== "undefined"
      ? stage.renderStage
      : true
  );
}

function getTouchedValue(field, touched) {
  if (!touched) {
    return null;
  }
  // check for nested value
  let t = touched;
  for (const name of field.name.split(".")) {
    t = touched[name];
    if (t === undefined || t === null) {
      break;
    }
  }

  return t;
}

function handleCheckListChange(
  event,
  handleChange,
  state,
  values,
  field
) {
  //Initialise arrays in values if not already set
  if (!values[event.target.name + "name"]) {
    values[event.target.name + "name"] = [];
  }
  if (!values[event.target.name]) {
    values[event.target.name] = [];
  }

  const { value, checked } = event.target;
  const newValue = checked
    ? [...values[event.target.name], value]
    : values[event.target.name].filter((id) => id !== value);

  if (field && field.lookup && field.lookup.name) {
    //Get the lookup option info from the state by matching on the lookup name
    let lookupOption = state.lookupOptions.find(
      (o) => o.name === field.lookup.name
    );
    if (lookupOption) {
      //Get the specific lookup data based on the value selected
      let lookupData = lookupOption.data.filter(
        (l) => l.Key === value
      );
      if (lookupData.length === 1) {
        // Update the names
        values[event.target.name + "name"] = lookupOption.data
          .filter((x) => newValue.some((y) => y === x.Key))
          .map((x) => x.Value);
      }
    }
    handleChange({
      target: {
        name: event.target.name,
        value: newValue,
      },
    });
  }
}

function handleFilteredPicklistChange(
  event,
  handleChange,
  fieldName,
  values
) {
  if (event) {
    const { key, label } = event;
    //Having a value for label implies that something was selected.
    //This is to avoid a situation where if value = 0 then it would
    //fail this if statement even though it should pass
    if (label && label !== "") {
      values[fieldName + "name"] = label;
      values[fieldName] = key;
    } else {
      values[fieldName + "name"] = null;
      values[fieldName] = null;
    }
  } else {
    values[fieldName + "name"] = null;
    values[fieldName] = null;
  }

  handleChange({
    target: {
      name: fieldName + "name",
      value: values[fieldName + "name"],
    },
  });

  handleChange({
    target: {
      name: fieldName,
      value: values[fieldName],
    },
  });
}

function handlePicklistChange(event, handleChange, values) {
  const { value, selectedIndex } = event.target;
  if (value && value !== "") {
    values[event.target.name + "name"] =
      event.target[selectedIndex].label;
  } else {
    values[event.target.name + "name"] = null;
  }
  handleChange(event);
}

function handleRedirect(
  closeRedirect,
  dispatch,
  id,
  action,
  updatedState
) {
  // Redirect to closeRedirect if user has selected to save and close
  // Redirect to the record if the user has selected to save a new record
  if (action) {
    if (action === "saveAndClose") {
      dispatch({
        type: DispatchMethods.SetRedirect,
        redirect: generateRedirectURL(closeRedirect),
      });
    } else if (action === "saveAndNew") {
      dispatch({
        type: DispatchMethods.SetRecordId,
        redirect: generateRedirectURL(closeRedirect),
        replace: true,
      });
    } else if (id) {
      const urlParams = new URLSearchParams(window.location.search);
      // if the id is not set
      // or if we are copying a record
      // or copying a comm
      // or stopping or starting a comm
      // then redirect
      if (
        !urlParams.has("id") ||
        action === "saveAndPublish" ||
        action === "copyRecord" ||
        action === "stopStartComm" ||
        action === "refreshPage"
      ) {
        urlParams.set("id", id);
        dispatch({
          type: DispatchMethods.SetRecordId,
          id: id,
          redirect:
            window.location.pathname + "?" + urlParams.toString(),
          replace: true,
        });
      }
      //Otherwise if its just a save on an existing record, then set the updateAction in the
      //values which will allow the component to refresh any specific elements
      else if (action === "save" && updatedState) {
        updatedState["updateAction"] = "refresh";
        dispatch({
          type: DispatchMethods.SetReloadValues,
          reloadValues: updatedState,
        });
      }
    }
  }
}

function hideNavigationButtons(globalState, stage, stages, state) {
  if (state.id && stage && stage.hideNavigationButtons) {
    return stage.hideNavigationButtons(state);
  }

  //Hide the buttons if the user does not have the required role
  return !checkIfUserRoleCanEditRecord(globalState, stages);
}

function checkIfUserRoleCanEditRecord(globalState, stages) {
  //If there are no stages with a recordEditRoles value set then allow for
  //any role to edit the record. If it does have a value then check if the logged in
  //user has a valid role which will allow them to edit the record
  let stageWithRecordEditAllowRoles =
    stages &&
    stages.length > 0 &&
    stages.some((s) => s.recordEditRoles)
      ? stages.find((s) => s.recordEditRoles)
      : null;

  return (
    !stageWithRecordEditAllowRoles ||
    checkForValidRoles(
      stageWithRecordEditAllowRoles.recordEditRoles,
      globalState
    )
  );
}

function isNonEmptyString(value) {
  return typeof value === "string" && value.trim() !== "";
}

function mapPropsToValuesForStages(
  stages,
  state,
  parentFormEntityName,
  parentFormFieldLookup,
  parentFormState,
  parentFormValues,
  linkedEntities
) {
  const initialValues = {};

  const urlParams = new URLSearchParams(window.location.search);
  stages.forEach((stage) => {
    stage.sections
      .filter((section) => section.fields)
      .forEach((section) => {
        section.fields.forEach((field) => {
          initialValues[field.name] = getFieldInitialValue(
            state,
            field,
            urlParams,
            parentFormEntityName,
            parentFormFieldLookup,
            parentFormState,
            parentFormValues,
            linkedEntities
          );
          if (field.type === "lookup") {
            initialValues[field.name + "name"] = getFieldInitialValue(
              state,
              { name: field.name + "name" },
              urlParams,
              parentFormEntityName,
              parentFormFieldLookup,
              parentFormState,
              parentFormValues,
              linkedEntities
            );
          }
        });
      });
  });

  return initialValues;
}

function scrollToTopOfPage() {
  document.getElementById("content").scroll({
    top: 0,
  });
}

function selectStage(dispatch, stage, state) {
  // only allow a previous stage to be selected
  if (stage >= state.stage) {
    return;
  }

  dispatch({
    type: DispatchMethods.SetStage,
    stage: stage,
    formData: state.formData,
  });
}

function setCreateOrUpdateLookupRecord(
  setSubForm,
  field,
  id,
  linkedEntityId
) {
  setSubForm({
    field: field,
    id: id,
    linkedEntityId: linkedEntityId,
  });

  scrollToTopOfPage();
}

function setCustomActionValues(
  action,
  actionLabel,
  field,
  setCustomAction
) {
  setCustomAction({
    selectedAction: action,
    selectedActionLabel: actionLabel,
    field: field,
  });
  scrollToTopOfPage();
}

const sortColumn = (
  entities,
  orderByProperty,
  orderedByAsc,
  dataType = ""
) => {
  // Sort the entities array based on orderByProperty and orderAsc
  return [...entities].sort((a, b) => {
    var afield = a["Fields"][orderByProperty];
    var bfield = b["Fields"][orderByProperty];
    if (orderByProperty === "Name") {
      afield = a["Name"] && a["Name"] !== undefined ? a["Name"] : "";
      bfield = b["Name"] && b["Name"] !== undefined ? b["Name"] : "";
      return orderedByAsc
        ? afield.localeCompare(bfield)
        : bfield.localeCompare(afield);
    } else {
      const dataTypeA =
        dataType !== "" ? dataType : getFieldDataType(afield);
      const dataTypeB =
        dataType !== "" ? dataType : getFieldDataType(bfield);

      if (dataTypeA === "number" || dataTypeB === "number") {
        if (!afield || afield === undefined) {
          afield = orderedByAsc
            ? Number.MAX_SAFE_INTEGER
            : Number.MIN_SAFE_INTEGER;
        }
        if (!bfield || bfield === undefined) {
          bfield = orderedByAsc
            ? Number.MAX_SAFE_INTEGER
            : Number.MIN_SAFE_INTEGER;
        }

        return orderedByAsc
          ? afield > bfield
            ? 1
            : bfield === afield
            ? 0
            : -1
          : afield < bfield
          ? 1
          : afield === bfield
          ? 0
          : -1;
      } else if (dataTypeA === "boolean" || dataTypeB === "boolean") {
        if (!afield || afield === undefined) {
          afield = false;
        }
        if (!bfield || bfield === undefined) {
          bfield = false;
        }

        return orderedByAsc
          ? afield > bfield
            ? 1
            : afield === bfield
            ? 0
            : -1
          : afield < bfield
          ? 1
          : afield === bfield
          ? 0
          : -1;
      } else if (
        dataTypeA === "datetime" ||
        dataTypeB === "datetime"
      ) {
        var dAField = new Date(0);
        var dBField = new Date(0);
        if (afield && afield !== undefined) {
          dAField = new Date(afield);
        }
        if (bfield && bfield !== undefined) {
          dBField = new Date(bfield);
        }
        return orderedByAsc
          ? dAField > dBField
            ? 1
            : dAField === dBField
            ? 0
            : -1
          : dAField < dBField
          ? 1
          : dAField === dBField
          ? 0
          : -1;
      } else if (dataTypeA === "object" || dataTypeB === "object") {
        if (
          afield &&
          afield !== undefined &&
          bfield &&
          bfield !== undefined
        ) {
          return orderedByAsc
            ? afield.Name.localeCompare(bfield.Name)
            : bfield.Name.localeCompare(afield.Name);
        } else if (
          afield &&
          afield !== undefined &&
          (!bfield || bfield === undefined)
        ) {
          return orderedByAsc
            ? afield.Name.localeCompare("")
            : "".localeCompare(afield.Name);
        } else if (
          (!afield || afield === undefined) &&
          bfield &&
          bfield !== undefined
        ) {
          return orderedByAsc
            ? "".localeCompare(bfield.Name)
            : bfield.Name.localeCompare("");
        }
        return 0;
      } else if (dataTypeA === "string" || dataTypeB === "string") {
        if (!afield || afield === undefined) {
          afield = "";
        }
        if (!bfield || bfield === undefined) {
          bfield = "";
        }

        return orderedByAsc
          ? afield.localeCompare(bfield)
          : bfield.localeCompare(afield);
      } else {
        return 0;
      }
    }
  });
};

function shouldRenderTableFilters(filters) {
  // if there aren't any filters then don't show
  if (!filters) {
    return false;
  }

  // if the only filters are type select then only render if there are some options
  return (
    filters.filter((f) => f.type).length > 0 &&
    filters.some(
      (filter) =>
        !["datetime", "select"].includes(filter.type) &&
        !filter.filterByParent
    )
  );
}

function tableHandleFilterChange(filter, event, filters, setFilters) {
  setFilters([
    ...filters.filter((f) => f.Key !== filter.name),
    {
      Key: filter.name,
      FilterAction: filter.filterAction,
      TextFieldsSearch: filter.textFieldsSearch,
      Type: filter.type,
      Value:
        filter.type === "bit"
          ? event.target.checked
          : filter.type === "select"
          ? event.filter((e) => e.label).length > 0 //It is assumed if the event has a label, then its got a value
            ? event.filter((e) => e.label).map((e) => e.value) //This is because if e.value was = 0, then it wouldn't count it in the filter
            : ""
          : event.target.value,
    },
  ]);
}

const useExistingRecord = (
  id,
  entityName,
  globalDispatch,
  dispatch,
  loadDataMethod,
  state,
  stages
) => {
  useEffect(() => {
    const fetchData = async (id, loadDataMethod) => {
      try {
        const [serviceResponse] = await Promise.all([
          loadDataMethod
            ? loadDataMethod(globalDispatch)
            : GetEntityRecordById(globalDispatch, entityName, id),
        ]);
        if (
          serviceResponse &&
          serviceResponse.data &&
          serviceResponse.data.Fields
        ) {
          // go to the overview stage if there are multiple stages, otherwise go to stage 1
          let filteredStages = filterStages(
            stages,
            serviceResponse.data.Fields
          );

          dispatch({
            type: DispatchMethods.SetEditRecord,
            stage:
              filteredStages.length > 1
                ? getOverviewIndex(filteredStages)
                : 1,
            responseData: serviceResponse.data,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDefaultData = async () => {
      if (state.initialLoad) {
        try {
          const [serviceResponse] = await Promise.all([
            GetDefaultValuesForEntity(globalDispatch, entityName),
          ]);
          if (serviceResponse && serviceResponse.data) {
            dispatch({
              type: DispatchMethods.SetDefaultValues,
              responseData: serviceResponse.data,
            });
          } else {
            dispatch({
              type: DispatchMethods.SetInitialLoad,
              initialLoad: false,
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    if (state.initialLoad && (id || loadDataMethod)) {
      fetchData(id, loadDataMethod);
    } else if (entityName === "g4c_communication") {
      fetchDefaultData();
    } else {
      dispatch({
        type: DispatchMethods.SetInitialLoad,
        initialLoad: false,
      });
    }
  }, [
    dispatch,
    entityName,
    id,
    globalDispatch,
    state.initialLoad,
    stages,
    state.stage,
    loadDataMethod,
  ]);
};

const useLookupOptions = (
  dispatch,
  globalDispatch,
  loading,
  lookups,
  setLoading,
  state
) => {
  useEffect(() => {
    const fetchData = async (lookups) => {
      try {
        // attempt to retrieve from session storage before making a call to the API
        let lookupOptions = [];
        lookups.forEach((lookup) => {
          const lookupData = getFromSessionStorage(lookup.name);
          if (lookupData) {
            lookupOptions.push({
              name: lookup.name,
              data: lookupData,
            });
          }
        });

        // retrieve the lookup options from the API if not in session storage
        const serviceLookups = lookups.filter(
          (lookup) =>
            !lookupOptions.some(
              (option) => option.name === lookup.name
            )
        );

        if (serviceLookups.length > 0) {
          const result = await Promise.all(
            serviceLookups.reduce((requests, lookup) => {
              if (lookup.method) {
                requests.push(Get(globalDispatch, lookup.method));
              } else {
                requests.push(
                  GetAllEntityBasicInfo(
                    globalDispatch,
                    lookup.entityName,
                    lookup.searchFilter
                  )
                );
              }
              return requests;
            }, [])
          );

          if (result && result.length) {
            const serviceLookupOptions = serviceLookups.map(
              (lookup, i) => ({
                name: lookup.name,
                data: lookup.mapData
                  ? result[i].data.map((x) => lookup.mapData(x))
                  : result[i].data,
              })
            );

            // store the lookup options in session storage
            serviceLookupOptions.forEach((lookup) => {
              addToSessionStorage(lookup.name, lookup.data);
            });

            // combine the values
            lookupOptions = lookupOptions.concat(
              serviceLookupOptions
            );
          }
        }

        dispatch({
          type: DispatchMethods.SetLookupOptions,
          lookupOptions: lookupOptions,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(LoadingState.Loaded);
      }
    };

    // don't do anything if already loading
    if (loading !== LoadingState.NotLoaded) {
      return;
    }

    if (lookups.length > 0) {
      setLoading(LoadingState.Loading);
      fetchData(lookups);
    } else {
      setLoading(LoadingState.Loaded);
    }
  }, [
    dispatch,
    globalDispatch,
    loading,
    lookups,
    setLoading,
    state.lookupOptions,
  ]);
};

function usePeakPricing(productType) {
  switch (String(productType)) {
    case String(ProductType.AutoScheduled):
    case String(ProductType.Pass):
    case String(ProductType.Scheduled):
      return true;
    default:
      return false;
  }
}

function searchRecords(
  entityName,
  filters,
  globalDispatch,
  setAllRecords,
  setFilteredRecords,
  setLoading,
  setSubmitting,
  PageRequested = 1,
  PageSize = 200
) {
  setLoading(true);

  const fetchData = async () => {
    try {
      const [serviceResponse] = await Promise.all([
        Search(globalDispatch, {
          PageRequested: PageRequested,
          PageSize: PageSize,
          Filters: filters,
          EntityName: entityName,
        }),
      ]);

      if (
        serviceResponse &&
        serviceResponse.data &&
        serviceResponse.data.Records &&
        serviceResponse.data.Records.length > 0
      ) {
        // Order the returned records by name
        const serviceData = serviceResponse.data.Records.sort(
          (a, b) => a.Name.localeCompare(b.Name)
        );
        setAllRecords(serviceData);
        setFilteredRecords(serviceData);
      } else {
        setAllRecords([]);
        setFilteredRecords([]);
        if (globalDispatch) {
          globalDispatch({
            type: GlobalDispatchMethods.AddNotification,
            notification: {
              message: "No Records Found",
              success: false,
            },
            dispatch: globalDispatch,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };
  fetchData();
}

function searchRecordsAndAddToLoadedData(
  entityName,
  filters,
  globalDispatch,
  loadedRecords,
  resetLoadedRecords,
  fieldOrderedBy,
  fieldOrderedByAsc,
  setLoadedRecords,
  setLoading,
  setSubmitting,
  setTotalRecords,
  PageRequested = 1,
  PageSize = 200
) {
  setLoading(true);

  const fetchData = async () => {
    try {
      const [serviceResponse] = await Promise.all([
        Search(globalDispatch, {
          PageRequested: PageRequested,
          PageSize: PageSize,
          Filters: filters,
          EntityName: entityName,
        }),
      ]);

      if (
        serviceResponse &&
        serviceResponse.data &&
        serviceResponse.data.TotalRecords &&
        serviceResponse.data.Records.length > 0
      ) {
        setTotalRecords(serviceResponse.data.TotalRecords);
        // Order the returned records by the fieldOrderedBy and fieldOrderedByAsc values
        const serviceData = sortColumn(
          serviceResponse.data.Records,
          fieldOrderedBy,
          fieldOrderedByAsc
        );
        if (resetLoadedRecords) {
          setLoadedRecords(serviceData);
        } else {
          let updatedLoadedRecords = loadedRecords;

          //Loop through the returned records in serviceData and check we aren't adding any duplicates
          serviceData.forEach((s) => {
            if (!loadedRecords.some((l) => l.Id === s.Id)) {
              updatedLoadedRecords.push(s);
            }
          });
          setLoadedRecords(updatedLoadedRecords);
        }
      } else {
        setLoadedRecords([]);
        if (globalDispatch) {
          globalDispatch({
            type: GlobalDispatchMethods.AddNotification,
            notification: {
              message: "No Records Found",
              success: false,
            },
            dispatch: globalDispatch,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };
  fetchData();
}

function SetRecordStatus(
  dispatch,
  entityName,
  records,
  stateCode,
  globalDispatch,
  usingSubmitButton,
  setAllRecords,
  setCurrentPage,
  setCurrentRecords,
  setFilteredRecords,
  setInitialFirstAttemptToGetData,
  setRecordsSelected,
  setLoading,
  setSubmitting,
  setLoadedRecords,
  handleSubmitFilter,
  orderedBy,
  orderedByAsc,
  recordsPerPage,
  state
) {
  setLoading(true);

  const setStatus = async () => {
    try {
      const [serviceResponse] = await Promise.all([
        SetRecordState(globalDispatch, {
          Ids: records,
          EntityName: entityName,
          StateCode: stateCode,
        }),
      ]);
      if (serviceResponse && serviceResponse.status === 200) {
        setCurrentPage(1);
        setCurrentRecords([]);
        setRecordsSelected([]);
        if (usingSubmitButton) {
          setLoadedRecords([]);
          handleSubmitFilter(
            1,
            orderedBy,
            orderedByAsc,
            recordsPerPage,
            true
          );
        } else {
          setAllRecords([]);
          setFilteredRecords([]);

          //Check if what we just updated was in the related entities
          //If so then update the values in the related entities
          var updatedRelatedEntities = state && state.relatedEntities;
          var updatedRelatedEntity =
            updatedRelatedEntities &&
            updatedRelatedEntities.find(
              (r) => r.entityName === entityName
            );
          if (updatedRelatedEntity) {
            records.forEach((r) => {
              var record = updatedRelatedEntity.entities.find(
                (e) => e.Id === r
              );
              if (record && record.Fields) {
                record.Fields["statecode"] = stateCode;
                record.Fields["statuscode"] = stateCode === 0 ? 1 : 2;
                const now = new Date();
                record.Fields["modifiedon"] = now.toString();
              }
            });
            dispatch({
              type: DispatchMethods.SetRelatedEntities,
              relatedEntities: updatedRelatedEntities,
            });
          } else {
            //Otherwise trigger a reset of the loading of the records via
            //setInitialFirstAttemptToGetData(true)
            //Trigger a reset of the loading of the records
            setInitialFirstAttemptToGetData(true);
          }
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setSubmitting(false);
    }
  };
  setStatus();
}

function useRecords(
  allRecords,
  entityName,
  filterByStatus,
  filters,
  globalDispatch,
  initialFilters,
  initialFirstAttemptToGetData,
  letter,
  loadStateRelatedEntityName,
  loading,
  orderedBy,
  orderedByAsc,
  recordStatusToShow,
  setAllRecords,
  setCurrentPage,
  setFilteredRecords,
  setFilters,
  setInitialFirstAttemptToGetData,
  setLoading,
  setOrderedBy,
  setOrderedByAsc,
  setSubformAddingNewRecord,
  state,
  subForm,
  subformAddingNewRecord
) {
  const sessionStorageEntryName = `${entityName}-${
    recordStatusToShow === 0 ? "Active" : "Inactive"
  }`;
  const data =
    loadStateRelatedEntityName && state && state.relatedEntities
      ? getTableContentFromStateRelatedEntity(
          loadStateRelatedEntityName,
          filterByStatus,
          recordStatusToShow,
          state
        )
      : getFromSessionStorage(sessionStorageEntryName);

  useEffect(() => {
    if (
      data === null &&
      !loadStateRelatedEntityName &&
      initialFirstAttemptToGetData
    ) {
      setLoading(true);

      const fetchData = async () => {
        try {
          const [serviceResponse] = await Promise.all([
            GetAllEntityDetailedInfo(
              globalDispatch,
              entityName,
              String(recordStatusToShow) ===
                String(RecordStatus.Inactive)
            ),
          ]);

          if (serviceResponse && serviceResponse.data) {
            // Order the returned records by name
            const serviceData = serviceResponse.data.sort((a, b) =>
              a.Name.localeCompare(b.Name)
            );

            addToSessionStorage(sessionStorageEntryName, serviceData);

            setAllRecords(serviceData);
          } else {
            setAllRecords([]);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
          setInitialFirstAttemptToGetData(false);
        }
      };
      fetchData();
    } else if (loading) {
      setAllRecords(data);
      setLoading(false);
    } else if (
      loadStateRelatedEntityName &&
      !subForm &&
      (subformAddingNewRecord ||
        !checkIfTwoArraysAreTheSame(allRecords, data))
    ) {
      //In the event we are coming back from a subform that is adding a new record to the related entity
      //or have done some sort of action that changed records in the related entity such
      //that there is now a mismatch between data and allRecords
      //then set the filters back to their default initial values
      //and set the filtered and current records to the new data. This should trigger the useEffect
      //below that will then properly filter it
      setAllRecords(data);
      setFilteredRecords(data);
      setFilters(initialFilters);
      setSubformAddingNewRecord(false);
    }
  }, [
    allRecords,
    data,
    entityName,
    globalDispatch,
    initialFilters,
    initialFirstAttemptToGetData,
    loadStateRelatedEntityName,
    loading,
    recordStatusToShow,
    sessionStorageEntryName,
    setAllRecords,
    setFilteredRecords,
    setFilters,
    setInitialFirstAttemptToGetData,
    setLoading,
    setSubformAddingNewRecord,
    subForm,
    subformAddingNewRecord,
  ]);

  // filter and sort the records
  useEffect(() => {
    if (allRecords && allRecords.length > 0) {
      const filteredRecords = allRecords.filter((record) => {
        const letterMatch =
          letter === "" ||
          record.Name.toLowerCase().indexOf(letter.toLowerCase()) ===
            0;

        if (!letterMatch) {
          return false;
        }

        if (filters && filters.length > 0) {
          for (const filter of filters) {
            //Need to add extra check for filter.Value and field.Id being null or undefined
            //as if they were equal to 0 like with a false value for a field of type bit,
            //then these would return a 0 rather than true or false
            //if we just had it to just filter.Value or field.Id && field.Id.toString().toLowerCase() !== filterValue)
            //Also if its a date then we want to keep it as filter.Value instead of making it a string as we want to
            //do a date comparison
            const filterValue =
              filter.Value !== null && filter.Value !== undefined
                ? filter.Type === "datetime" ||
                  filter.Type === "select" ||
                  filter.Type === "bit"
                  ? filter.Value
                  : filter.Value.toString().toLowerCase()
                : "";

            //For text filters we check against a list of given fields on the filter
            //Check for each field in the filter Fields if there exists
            //a substring match for the given filterValue in at least one of the record fields
            if (filter.Type === "text" && filter.TextFieldsSearch) {
              let matchFound = false;
              filter.TextFieldsSearch.forEach((t) => {
                if (!matchFound) {
                  //Don't look anymore if matchFound becomes true
                  if (
                    (t === "Name" &&
                      record.Name &&
                      record.Name.toLowerCase().indexOf(
                        filterValue
                      ) !== -1) ||
                    (record.Fields &&
                      String(record.Fields[t]) &&
                      String(record.Fields[t])
                        .toLowerCase()
                        .indexOf(filterValue) !== -1)
                  ) {
                    matchFound = true;
                  }
                }
              });
              if (!matchFound) {
                return false;
              }
            }
            //Otherwise handle the filtering like normal
            else {
              var filterKeyName = filter.Key;
              //If the key ends with -From or -To then its a date field
              //Also need to get which direction the date comparison needs to be in
              let dateDirection = filterKeyName.endsWith("-From")
                ? "From"
                : filterKeyName.endsWith("-To")
                ? "To"
                : "";

              if (filterKeyName.endsWith("-From")) {
                filterKeyName = filterKeyName.substring(
                  0,
                  filterKeyName.indexOf("-From")
                );
              } else if (filterKeyName.endsWith("-To")) {
                filterKeyName = filterKeyName.substring(
                  0,
                  filterKeyName.indexOf("-To")
                );
              }

              //If the filter has its own filterAction then we can use that as a custom function to
              //read the value from the record to get a custom field value that we can use for the comparison
              //Otherwise read the value directly from the record as the value to use for the comparison
              let field = filter.FilterAction
                ? filter.FilterAction(filterValue, record)
                : filter.Key === "Name"
                ? record.Name
                : record.Fields[filterKeyName];

              if (
                (filter.Type === "bit" || filterValue) && //If filter.Type is bit and filterValue has a value of false,
                //then this if statement would always be false even if the field is true which is why the filter.Type === "bit" is needed
                (!field ||
                  (filter.Type === "datetime" &&
                    ((dateDirection === "From" &&
                      !isSameOrLaterDate(field, filterValue)) ||
                      (dateDirection === "To" &&
                        !isSameOrBeforeDate(field, filterValue)))) ||
                  (filter.Type === "select" &&
                    filterValue.length > 0 &&
                    !filterValue.some(
                      (f) => String(f) === String(field.Id)
                    ) &&
                    !filterValue.some(
                      (f) => String(f) === String(field)
                    )) ||
                  (filter.Type !== "select" &&
                    filter.Type !== "datetime" &&
                    ((typeof field === "string" &&
                      field.toLowerCase().indexOf(filterValue) ===
                        -1) ||
                      (typeof field === "number" &&
                        String(field) !== filterValue) ||
                      (field.Id !== null &&
                        field.Id !== undefined &&
                        field.Id.toString().toLowerCase() !==
                          filterValue))))
              ) {
                return false;
              }
            }
          }
        }
        return true;
      });

      const newOrderedList = sortColumn(
        filteredRecords,
        orderedBy,
        orderedByAsc
      );

      setFilteredRecords(newOrderedList);
      setCurrentPage(1);
      setOrderedBy(orderedBy);
      setOrderedByAsc(orderedByAsc);
    }
  }, [
    allRecords,
    filters,
    letter,
    orderedBy,
    orderedByAsc,
    setCurrentPage,
    setFilteredRecords,
    setOrderedBy,
    setOrderedByAsc,
  ]);
}

export {
  addToSessionStorage,
  anyRealCardProvider,
  allowEditOnOverview,
  checkForAnyEditableStages,
  checkIfFieldDisabled,
  checkIfFieldRequired,
  checkIfRequiredFieldValueValid,
  checkIfUserRoleCanEditRecord,
  checkForValidRoles,
  checkRelatedEntityHasRecords,
  createOrUpdateRecord,
  displayEditButtonOnOverview,
  displayElement,
  displayElementOnOverview,
  displayOverviewRender,
  displayStage,
  filterStages,
  generateFilterQueryString,
  generateRedirectURL,
  getAttributesForSave,
  getConfirmationModal,
  getCustomAction,
  getCustomComponent,
  getDateRangeDateValues,
  getDescription,
  getEditLink,
  getEnum,
  getFieldInitialValue,
  getFieldLabel,
  getFieldLabelForFormTable,
  getFieldObject,
  getFieldPlaceholder,
  getFieldValue,
  getFilterComponent,
  getFromSessionStorage,
  getHeaderText,
  getInputType,
  getLinkForNewRecord,
  getLookupByName,
  getLookupFieldOptions,
  getLookupsForStages,
  getLookupInState,
  getNameFieldValue,
  getOptionsFromRecords,
  getOverviewIndex,
  getSectionInitialFilters,
  getStageNames,
  getStagesToRender,
  getTableColumnValue,
  getSectionInitiallyCollapsed,
  getTooltipDescription,
  getTouchedValue,
  handleCheckListChange,
  handleFilteredPicklistChange,
  handlePicklistChange,
  handleRedirect,
  hideNavigationButtons,
  isNonEmptyString,
  mapPropsToValuesForStages,
  scrollToTopOfPage,
  searchRecords,
  searchRecordsAndAddToLoadedData,
  selectStage,
  setCreateOrUpdateLookupRecord,
  setCustomActionValues,
  SetRecordStatus,
  shouldRenderTableFilters,
  sortColumn,
  tableHandleFilterChange,
  useExistingRecord,
  useLookupOptions,
  usePeakPricing,
  useRecords,
};
