import { SelectWithFiltering } from "../elements/_Elements";
import { PageSize } from "./enums";

function RecordsPerPage({
  records,
  recordsPerPage,
  setRecordsPerPage,
  totalRecordCount,
}) {
  return (records && records.length > 10) || totalRecordCount > 10 ? (
    <div className="records-per-page">
      <SelectWithFiltering
        isClearable={false}
        label="Records per page"
        name="recordsPerPage"
        onChange={(event) => {
          setRecordsPerPage(parseInt(event.value));
        }}
        options={Object.entries(PageSize).map(([Name, Number]) => ({
          Key: Number,
          Value: Name,
        }))}
        value={recordsPerPage.toString()}
      />
    </div>
  ) : (
    <></>
  );
}

export default RecordsPerPage;
