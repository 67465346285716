const administratorRoleOnly = ["administrator"];
const administratorRoles = ["administrator", "clientadministrator"];
const productAdministratorRoles = [
  "administrator",
  "clientadministrator",
  "productadministrator",
];
const marketingAdministratorRoles = [
  "administrator",
  "clientadministrator",
  "marketingadministrator",
];
const allNonReadOnlyRoles = [
  "administrator",
  "clientadministrator",
  "productadministrator",
  "marketingadministrator",
];
const allRoles = [
  "administrator",
  "clientadministrator",
  "productadministrator",
  "marketingadministrator",
  "readonly",
];

const getPageAllowedRoles = (page) => {
  switch (page) {
    case "admin-settings":
    case "journal-daily-reports":
    case "quick-start":
    case "sitemap":
      return administratorRoleOnly;
    case "access-control-provider":
    case "access-point":
    case "answer-group":
    case "answer-group-option":
    case "answer-option":
    case "answer-option-category":
    case "blacklist":
    case "block":
    case "block-seat":
    case "bookable-resource":
    case "channel":
    case "channel-question":
    case "charity":
    case "city":
    case "communication-settings":
    case "country":
    case "coupon-product":
    case "coupon-type":
    case "donation-batch-header":
    case "fixture":
    case "fund":
    case "global-settings":
    case "language":
    case "on-hold-reason":
    case "operator":
    case "operator-group":
    case "payment-batch":
    case "payment-batch-item":
    case "payment-method":
    case "print-transform":
    case "printer":
    case "promotion":
    case "promotion-exclusion-date":
    case "promotion-product-group":
    case "promotion-type":
    case "question":
    case "region":
    case "seat-allocation":
    case "seat-class":
    case "series":
    case "tax-code":
    case "tax-component":
    case "tax-rate":
    case "till-group":
    case "terminal":
    case "vehicle-registration":
    case "venue":
    case "venue-configuration":
    case "venue-configuration-block":
    case "zone":
      return administratorRoles;
    case "additional-product":
    case "calendar-item":
    case "channel-price-list":
    case "cross-sell":
    case "group":
    case "price-break":
    case "price-break-group":
    case "product":
    case "product-calendar":
    case "product-category":
    case "product-modifier":
    case "reporting-category":
    case "sales-plan":
    case "variant-price-list":
    case "variant-type":
    case "variant-type-category":
    case "widget-group":
      return productAdministratorRoles;
    case "abtest":
    case "campaign":
    case "communication":
    case "communication-audience":
    case "communication-flow":
    case "marketing-list":
    case "subscription-group":
      return marketingAdministratorRoles;
    case "account":
    case "booking":
    case "booking-ticket":
    case "contact":
    case "contacts-report":
    case "coupon":
    case "customer-address":
    case "email-marketing-report":
    case "financial-report":
    case "membership":
    case "membership-access-token":
    case "membership-account":
    case "memberships-report":
    case "on-hold-audit":
    case "operational-report":
    case "sales-and-bookings-report":
    case "telegram-queue":
    case "voucher":
      return allRoles;
    default:
      return [];
  }
};

const userHasCorrectRole = (user, allowedRoles) => {
  if (user) {
    return user.userRoles.some((r) => allowedRoles.includes(r));
  }
  return false;
};

export {
  administratorRoleOnly,
  administratorRoles,
  productAdministratorRoles,
  marketingAdministratorRoles,
  allNonReadOnlyRoles,
  allRoles,
  getPageAllowedRoles,
  userHasCorrectRole,
};
