import {
  CartIcon,
  HandIcon,
  ImageIcon,
  MinusIcon,
  RefreshIcon,
  TextIcon,
  TicketIcon,
  UserIcon,
} from "../../Icons";
import { DraggableIconCard } from "../../elements/_Elements";
import { Regarding } from "../../../js/enums";


  const hasControl = (controlName, values) => {
    return values.rows.flatMap(row => row.Columns)
    .some(column => column.Controls.some(control => control.Template === controlName));
    };

  const displayControl = (controlName,state, values) => {
  switch(controlName)
  {
    case "booking-control":
      return !hasControl("booking-control", values) && state.g4c_regardingtype &&
      (String(state.g4c_regardingtype) === String(Regarding.Booking) || (String(state.g4c_regardingtype) === String(Regarding["Booking Payment"])));
    case "voucher-control":
      return !hasControl("voucher-control", values) && state.g4c_regardingtype && (String(state.g4c_regardingtype)) === String(Regarding.Voucher)
    case "coupon-control":
      return !hasControl("coupon-control", values) && state.g4c_regardingtype && (String(state.g4c_regardingtype)) === String(Regarding.Coupon)
    case "membership-renewal-control":
      return !hasControl("membership-renewal-control", values) && state.g4c_regardingtype && 
            ((String(state.g4c_regardingtype)) === String(Regarding["Membership (Owner)"]) || (String(state.g4c_regardingtype)) === String(Regarding["Membership (Member)"]))
    default:
      return false;
  }
}

function ControlElements({ state,values }) {

  return (
    <>
      <DraggableIconCard
        id="text-control"
        icon={TextIcon}
        title="Text"
      />
      <DraggableIconCard
        id="image-control"
        icon={ImageIcon}
        title="Image"
      />
      <DraggableIconCard
        id="image-text-control"
        icon={ImageIcon}
        title="Text & Image"
      />
      <DraggableIconCard
        id="divider-control"
        icon={MinusIcon}
        title="Divider"
      />
      <DraggableIconCard
        id="button-control"
        icon={HandIcon}
        title="Button"
      />
      <DraggableIconCard
        id="social-follow-control"
        icon={UserIcon}
        title="Social Follow"
      />
      
       {displayControl("booking-control",state,values) && (
         <DraggableIconCard
         id="booking-control"
         icon={CartIcon}
         title="Booking"
        />
      )}
       {displayControl("coupon-control",state,values) && (
      <DraggableIconCard
        id="coupon-control"
        icon={TicketIcon}
        title="Coupon"
      />
      )}
       {displayControl("voucher-control",state,values) && (
      <DraggableIconCard
        id="voucher-control"
        icon={TicketIcon}
        title="Voucher"
      />
     )}
       {displayControl("membership-renewal-control",state,values) && (
      <DraggableIconCard
        id="membership-renewal-control"
        icon={RefreshIcon}
        title="Membership Renewal"
      />
    )}
    </>
  );
}

export default ControlElements;
