const lookups = {
  account: {
    all: { name: "Account", entityName: "account" },
    attributes: {
      name: "Account Attributes",
      entityName: "account",
      method: "GetEntityAttributes?entityname=account",
      mapData: (attribute) => ({
        Key: attribute.Name,
        Value: attribute.DisplayName,
      }),
    },
  },
  campaign: { all: { name: "Campaigns", entityName: "campaign" } },
  contact: {
    all: { name: "Contacts", entityName: "contact" },
    attributes: {
      name: "Contact Attributes",
      entityName: "contact",
      method: "GetEntityAttributes?entityname=contact",
      mapData: (attribute) => ({
        Key: attribute.Name,
        Value: attribute.DisplayName,
      }),
    },
  },
  customeraddress: {
    all: { name: "CustomerAddresses", entityName: "customeraddress" },
  },
  g4_answergroup: {
    all: { name: "Answer Groups", entityName: "g4_answergroup" },
  },
  g4_answergroupoption: {
    all: {
      name: "Answer Group Options",
      entityName: "g4_answergroupoption",
    },
  },
  g4_answeroption: {
    all: { name: "Answer Options", entityName: "g4_answeroption" },
  },
  g4_answeroptioncategory: {
    all: {
      name: "Answer Option Categories",
      entityName: "g4_answeroptioncategory",
    },
  },
  g4_city: {
    all: { name: "Cities", entityName: "g4_city" },
  },
  g4_country: {
    all: { name: "Countries", entityName: "g4_country" },
  },
  g4_group: {
    all: {
      name: "Groups",
      entityName: "g4_group",
    },
    widget: {
      name: "Groups By Widget",
      entityName: "g4_group",
      searchFilter: "22",
    },
    page: {
      name: "Groups By Page",
      entityName: "g4_group",
      searchFilter: "1",
    },
    product: {
      name: "Groups By Product",
      entityName: "g4_group",
      searchFilter: "21",
    },
  },
  g4_language: {
    all: { name: "Languages", entityName: "g4_language" },
  },
  g4_question: {
    all: { name: "Questions", entityName: "g4_question" },
  },
  g4_questionanswer: {
    all: {
      name: "Question Answers",
      entityName: "g4_questionanswer",
    },
  },
  g4_region: {
    all: { name: "Regions", entityName: "g4_region" },
  },
  g4b_accesscontrolprovider: {
    all: {
      name: "Access Control Providers",
      entityName: "g4b_accesscontrolprovider",
    },
  },
  g4b_accesspoint: {
    all: { name: "Access Points", entityName: "g4b_accesspoint" },
  },
  g4b_additionalproduct: {
    all: {
      name: "Additional Products",
      entityName: "g4b_additionalproduct",
    },
  },
  g4b_analysiscategory: {
    all: {
      name: "Analysis Categories",
      entityName: "g4b_analysiscategory",
    },
  },
  g4b_area: {
    all: {
      name: "Areas",
      entityName: "g4b_area",
    },
  },
  g4b_block: {
    all: {
      name: "Blocks",
      entityName: "g4b_block",
    },
  },
  g4b_blockseat: {
    all: {
      name: "Block Seats",
      entityName: "g4b_blockseat",
    },
  },
  g4b_blockseatclass: {
    all: {
      name: "Block Seat Classes",
      entityName: "g4b_blockseatclass",
    },
  },
  g4b_bookableresource: {
    all: {
      name: "Bookable Resources",
      entityName: "g4b_bookableresource",
    },
  },
  g4b_bookableresourcecategory: {
    all: {
      name: "Bookable Resource Categories",
      entityName: "g4b_bookableresourcecategory",
    },
  },
  g4b_booking: {
    all: { name: "Booking", entityName: "g4b_booking" },
    attributes: {
      name: "Booking Attributes",
      entityName: "g4b_booking",
      method: "GetEntityAttributes?entityname=g4b_booking",
      mapData: (attribute) => ({
        Key: attribute.Name,
        Value: attribute.DisplayName,
      }),
    },
    fixture: {
      name: "Bookings By Fixture",
      entityName: "g4b_booking-fixture",
    },
    series: {
      name: "Bookings By Series",
      entityName: "g4b_booking-series",
    },
  },
  g4b_bookingoperator: {
    all: {
      name: "Operators",
      entityName: "g4b_bookingoperator",
    },
  },
  g4b_bookingpayment: {
    all: {
      name: "Booking Payments",
      entityName: "g4b_bookingpayment",
    },
    attributes: {
      name: "Booking Payment Attributes",
      entityName: "g4b_bookingpayment",
      method: "GetEntityAttributes?entityname=g4b_bookingpayment",
      mapData: (attribute) => ({
        Key: attribute.Name,
        Value: attribute.DisplayName,
      }),
    },
  },
  g4b_bookingproduct: {
    all: {
      name: "Booking Products",
      entityName: "g4b_bookingproduct",
    },
  },
  g4b_bookingproductbundlecontentitem: {
    all: {
      name: "Booking Product Bundle Content Items",
      entityName: "g4b_bookingproductbundlecontentitem",
    },
  },
  g4b_bookingproductmodifier: {
    all: {
      name: "Booking Product Modifiers",
      entityName: "g4b_bookingproductmodifier",
    },
  },
  g4b_calendaritem: {
    all: { name: "Calendar Items", entityName: "g4b_calendaritem" },
  },
  g4b_channel: {
    all: { name: "Channels", entityName: "g4b_channel" },
  },
  g4b_channelpricelist: {
    all: {
      name: "Channel Price Lists",
      entityName: "g4b_channelpricelist",
    },
  },
  g4b_channelquestion: {
    all: {
      name: "Channel Questions",
      entityName: "g4b_channelquestion",
    },
  },
  g4b_companydetails: {
    all: {
      name: "Company Details",
      entityName: "g4b_companydetails",
    },
  },
  g4b_coupon: {
    all: {
      name: "Coupons",
      entityName: "g4b_coupon",
    },
    attributes: {
      name: "Coupon Attributes",
      entityName: "g4b_coupon",
      method: "GetEntityAttributes?entityname=g4b_coupon",
      mapData: (attribute) => ({
        Key: attribute.Name,
        Value: attribute.DisplayName,
      }),
    },
  },
  g4b_couponcategory: {
    all: {
      name: "Coupon Categories",
      entityName: "g4b_couponcategory",
    },
  },
  g4b_couponproduct: {
    all: {
      name: "Coupon Products",
      entityName: "g4b_couponproduct",
    },
  },
  g4b_coupontype: {
    all: {
      name: "Coupon Types",
      entityName: "g4b_coupontype",
    },
  },
  g4b_crosssellproduct: {
    all: {
      name: "Cross Sell Products",
      entityName: "g4b_crosssellproduct",
    },
  },
  g4b_fixture: {
    all: {
      name: "Fixtures",
      entityName: "g4b_fixture",
    },
  },
  g4b_journalentry: {
    all: {
      name: "Journal Entries",
      entityName: "g4b_journalentry",
    },
  },
  g4b_journalpayment: {
    all: {
      name: "Journal Payments",
      entityName: "g4b_journalpayment",
    },
  },
  g4b_onholdaudit: {
    all: { name: "On Hold Audits", entityName: "g4b_onholdaudit" },
  },
  g4b_onholdreason: {
    all: { name: "On Hold Reasons", entityName: "g4b_onholdreason" },
  },
  g4b_operatorgroup: {
    all: { name: "Operator Groups", entityName: "g4b_operatorgroup" },
  },
  g4b_paymentbatch: {
    all: {
      name: "Payment Batches",
      entityName: "g4b_paymentbatch",
    },
  },
  g4b_paymentbatchitem: {
    all: {
      name: "Payment Batch Items",
      entityName: "g4b_paymentbatchitem",
    },
  },
  g4b_paymentbatchitemresponse: {
    all: {
      name: "Payment Batch Item Responses",
      entityName: "g4b_paymentbatchitemresponse",
    },
  },
  g4b_paymentmandate: {
    all: {
      name: "Payment Mandates",
      entityName: "g4b_paymentmandate",
    },
  },
  g4b_paymentmethod: {
    all: {
      name: "Payment Methods",
      entityName: "g4b_paymentmethod",
    },
    finance: {
      name: "Finance Payment Methods",
      entityName: "g4b_paymentmethod",
      searchFilter: "21",
    },
  },
  g4b_pricebreak: {
    all: {
      name: "Price Break",
      entityName: "g4b_pricebreak",
    },
  },
  g4b_pricebreakgroup: {
    all: {
      name: "Price Break Groups",
      entityName: "g4b_pricebreakgroup",
    },
  },
  g4b_printer: {
    all: { name: "Printers", entityName: "g4b_printer" },
  },
  g4b_printerjobtype: {
    all: {
      name: "Print Job Type",
      entityName: "g4b_printerjobtype",
    },
  },
  g4b_printtransform: {
    all: {
      name: "Print Transforms",
      entityName: "g4b_printtransform",
    },
  },
  g4b_productbase: {
    all: { name: "Products", entityName: "g4b_productbase" },
    donation: {
      name: "Donation Products",
      entityName: "g4b_productbase",
      searchFilter: "15",
    },
  },
  g4b_productbasemodifier: {
    all: {
      name: "Product Modifiers",
      entityName: "g4b_productbasemodifier",
    },
  },
  g4b_productbundle: {
    all: {
      name: "Bundles",
      entityName: "g4b_productbundle",
    },
  },
  g4b_productbundleitem: {
    all: {
      name: "Bundle Items",
      entityName: "g4b_productbundleitem",
    },
  },
  g4b_productcalendar: {
    all: {
      name: "Product Calendars",
      entityName: "g4b_productcalendar",
    },
  },
  g4b_productgroup: {
    all: {
      name: "Product Groups",
      entityName: "g4b_productgroup",
    },
  },
  g4b_productuser: {
    all: {
      name: "Product Users",
      entityName: "g4b_productuser",
    },
  },
  g4b_promotion: {
    all: {
      name: "Promotions",
      entityName: "g4b_promotion",
    },
  },
  g4b_promotionexclusiondate: {
    all: {
      name: "Promotion Exclusion Dates",
      entityName: "g4b_promotionexclusiondate",
    },
  },
  g4b_promotiontype: {
    all: {
      name: "Promotion Types",
      entityName: "g4b_promotiontype",
    },
  },
  g4b_protoproduct: {
    all: {
      name: "Product Variants",
      entityName: "g4b_protoproduct",
      method: "GetAllProductVariants",
      mapData: (productVariant) => ({
        Key: productVariant.Id,
        Value: productVariant.Name,
        ProductId: productVariant.ProductId,
        VariantTypeId: productVariant.VariantTypeId,
      }),
    },
  },
  g4b_reportingcategory: {
    all: {
      name: "Reporting Categories",
      entityName: "g4b_reportingcategory",
    },
  },
  g4b_salesplan: {
    all: {
      name: "Sales Plans",
      entityName: "g4b_salesplan",
    },
    fixture: {
      name: "Sales Plans By Fixtures",
      entityName: "g4b_salesplan",
      searchFilter: "1",
    },
    series: {
      name: "Sales Plans By Series",
      entityName: "g4b_salesplan",
      searchFilter: "2",
    },
    other: {
      name: "Sales Plans By Others",
      entityName: "g4b_salesplan",
      searchFilter: "3",
    },
  },
  g4b_seatallocation: {
    all: {
      name: "Seat Allocations",
      entityName: "g4b_seatallocation",
    },
    fixture: {
      name: "Seat Allocations By Fixture",
      entityName: "g4b_seatallocation-fixture",
    },
    series: {
      name: "Seat Allocations By Series",
      entityName: "g4b_seatallocation-series",
    },
  },
  g4b_seatclass: {
    all: {
      name: "Seat Classes",
      entityName: "g4b_seatclass",
    },
  },
  g4b_series: {
    all: {
      name: "Series",
      entityName: "g4b_series",
    },
  },
  g4b_taxcode: {
    all: { name: "Tax Codes", entityName: "g4b_taxcode" },
  },
  g4b_taxcomponent: {
    all: { name: "Tax Components", entityName: "g4b_taxcomponent" },
  },
  g4b_taxrate: {
    all: { name: "Tax Rates", entityName: "g4b_taxrate" },
  },
  g4b_telegram: {
    all: {
      name: "Telegrams",
      entityName: "g4b_telegram",
    },
  },
  g4b_telegramqueue: {
    all: {
      name: "Telegram Queues",
      entityName: "g4b_telegramqueue",
    },
  },
  g4b_terminal: {
    all: { name: "Terminals", entityName: "g4b_terminal" },
  },
  g4b_ticket: {
    all: {
      name: "Booking Tickets",
      entityName: "g4b_ticket",
    },
  },
  g4b_ticketfixtures: {
    all: {
      name: "Ticket Fixtures",
      entityName: "g4b_ticketfixtures",
    },
  },
  g4b_tillgroup: {
    all: { name: "Till Groups", entityName: "g4b_tillgroup" },
  },
  g4b_variantpricelist: {
    all: {
      name: "Variant Price Lists",
      entityName: "g4b_variantpricelist",
    },
  },
  g4b_varianttype: {
    all: {
      name: "Variant Types",
      entityName: "g4b_varianttype",
      method: "GetAllVariantTypes",
      mapData: (variantType) => ({
        Key: variantType.Id,
        Value: variantType.Name,
        VariantTypeCategoryId: variantType.VariantTypeCategoryId,
      }),
    },
  },
  g4b_varianttypecategory: {
    all: {
      name: "Variant Type Categories",
      entityName: "g4b_varianttypecategory",
    },
  },
  g4b_venue: {
    all: {
      name: "Venues",
      entityName: "g4b_venue",
    },
  },
  g4b_vehicleregistrationnumber: {
    all: {
      name: "Vehicle Registration Numbers",
      entityName: "g4b_vehicleregistrationnumber",
    },
  },
  g4b_venueconfiguration: {
    all: {
      name: "Venue Configurations",
      entityName: "g4b_venueconfiguration",
    },
  },
  g4b_venueconfigurationblock: {
    all: {
      name: "Venue Configuration Blocks",
      entityName: "g4b_venueconfigurationblock",
    },
  },
  g4b_voucher: {
    all: {
      name: "Vouchers",
      entityName: "g4b_voucher",
    },
    attributes: {
      name: "Voucher Attributes",
      entityName: "g4b_voucher",
      method: "GetEntityAttributes?entityname=g4b_voucher",
      mapData: (attribute) => ({
        Key: attribute.Name,
        Value: attribute.DisplayName,
      }),
    },
  },
  g4b_widgetgroup: {
    all: { name: "Widget Groups", entityName: "g4b_widgetgroup" },
  },
  g4b_zone: {
    all: { name: "Zones", entityName: "g4b_zone" },
  },
  g4c_abtest: {
    all: {
      name: "AB Tests",
      entityName: "g4c_abtest",
    },
  },
  g4c_communication: {
    all: { name: "Communications", entityName: "g4c_communication" },
    abtest: {
      name: "Communications for AB Tests",
      entityName: "g4c_communication",
      searchFilter: "abtest",
    },
    contactFlow: {
      name: "Contact Flow Communications",
      entityName: "g4c_communication",
      method: "GetFlowCommunications?type=contact",
      mapData: (communication) => ({
        Key: communication.Id,
        Value: communication.Name,
        StatusCode: communication.StatusCode,
      }),
    },
    bookingFlow: {
      name: "Booking Flow Communications",
      entityName: "g4c_communication",
      method: "GetFlowCommunications?type=g4b_booking",
      mapData: (communication) => ({
        Key: communication.Id,
        Value: communication.Name,
        StatusCode: communication.StatusCode,
      }),
    },
    couponFlow: {
      name: "Coupon Flow Communications",
      entityName: "g4b_coupon",
      method: "GetFlowCommunications?type=g4b_coupon",
      mapData: (communication) => ({
        Key: communication.Id,
        Value: communication.Name,
        StatusCode: communication.StatusCode,
      }),
    },
    membershipFlow: {
      name: "Membership Flow Communications",
      entityName: "g4c_communication",
      method: "GetFlowCommunications?type=g4m_membership",
      mapData: (communication) => ({
        Key: communication.Id,
        Value: communication.Name,
        StatusCode: communication.StatusCode,
      }),
    },
    voucherFlow: {
      name: "Voucher Flow Communications",
      entityName: "g4b_voucher",
      method: "GetFlowCommunications?type=g4b_voucher",
      mapData: (communication) => ({
        Key: communication.Id,
        Value: communication.Name,
        StatusCode: communication.StatusCode,
      }),
    },
  },
  g4c_communicationactivity: {
    all: {
      name: "Communication Activities",
      entityName: "g4c_communicationactivity",
    },
  },
  g4c_communicationaudience: {
    all: {
      name: "Communication Audiences",
      entityName: "g4c_communicationaudience",
    },
  },
  g4c_communicationaudit: {
    all: {
      name: "Communication Audits",
      entityName: "g4c_communicationaudit",
    },
  },
  g4c_communicationsavedlayout: {
    all: {
      name: "Communication Saved Layouts",
      entityName: "g4c_communicationsavedlayout",
    },
  },
  g4c_subscriptiongroup: {
    all: {
      name: "Subscription Groups",
      entityName: "g4c_subscriptiongroup",
    },
  },
  g4c_triggerflow: {
    all: {
      name: "Communication Flows",
      entityName: "g4c_triggerflow",
    },
  },
  g4d_charity: {
    all: {
      name: "Charities",
      entityName: "g4d_charity",
    },
  },
  g4d_declaration: {
    all: {
      name: "Gift Aid Declarations",
      entityName: "g4d_declaration",
    },
  },
  g4d_donation: {
    all: {
      name: "Donations",
      entityName: "g4d_donation",
    },
  },
  g4d_donationbatchheader: {
    all: {
      name: "Donation Batch Headers",
      entityName: "g4d_donationbatchheader",
    },
  },
  g4d_donationbatchline: {
    all: {
      name: "Donation Batch Lines",
      entityName: "g4d_donationbatchline",
    },
  },
  g4d_fund: {
    all: {
      name: "Funds",
      entityName: "g4d_fund",
    },
  },
  g4gdpr_contactaudit: {
    all: {
      name: "Contact Audits",
      entityName: "g4gdpr_contactaudit",
    },
  },
  g4m_membership: {
    all: {
      name: "Memberships",
      entityName: "g4m_membership",
    },
    attributes: {
      name: "Membership Attributes",
      entityName: "g4m_membership",
      method: "GetEntityAttributes?entityname=g4m_membership",
      mapData: (attribute) => ({
        Key: attribute.Name,
        Value: attribute.DisplayName,
      }),
    },
  },
  g4m_membershipaccesstoken: {
    all: {
      name: "Membership Access Tokens",
      entityName: "g4m_membershipaccesstoken",
    },
  },
  g4m_membershipaccount: {
    all: {
      name: "Membership Accounts",
      entityName: "g4m_membershipaccount",
    },
  },
  g4m_membershipaccounttransaction: {
    all: {
      name: "Membership Accounts Transactions",
      entityName: "g4m_membershipaccounttransaction",
    },
  },
  g4m_membershippaymentplan: {
    all: {
      name: "Membership Payment Plans",
      entityName: "g4m_membershippaymentplan",
    },
  },
  g4m_membershippaymentplanschedule: {
    all: {
      name: "Membership Payment Plan Schedules",
      entityName: "g4m_membershippaymentplanschedule",
    },
  },
  g4m_membershiptype: {
    all: {
      name: "Membership Types",
      entityName: "g4m_membershiptype",
    },
  },
  list: { all: { name: "Marketing Lists", entityName: "list" } },
  listmember: {
    all: { name: "Marketing List Members", entityName: "listmember" },
  },
};

export default lookups;
