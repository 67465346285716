import React, { useState } from "react";
import { AudienceType, ButtonStyle } from "../../../js/enums";
import { GetEntityAttributes } from "../../../js/service";
import {
  getEntityNameForRegarding,
  isRegardingDifferentToListType,
} from "../../../js/utility";
import { useGlobalReducer } from "../../../GlobalContext";
import {
  Button,
  IconCard,
  Loading,
  Modal,
  SelectWithFiltering,
} from "../../elements/_Elements";
import classNames from "classnames";

function CommunicationAttributes({
  field,
  setCustomAction,
  setFieldValue,
  state,
  values,
}) {
  const { g4c_audiencetype, g4c_listtype, g4c_regardingtype } = state;
  const targetAtEntityNumber =
    String(state.g4c_listtype) === "1" ? 6 : 3; //6 for account, 3 for contact

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState("");
  const [selectedEntity, setSelectedEntity] = useState(
    targetAtEntityNumber
  );
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [selectedAttributeName, setSelectedAttributeName] =
    useState("");

  const globalDispatch = useGlobalReducer();
  const fetchDataForEntity = () => {
    const fetchData = async () => {
      try {
        //Either get the attributes for the targeted at entity,
        //or the attributes for the regarding entity
        const [attributeResponse] = await Promise.all([
          selectedEntity === targetAtEntityNumber
            ? GetEntityAttributes(
                globalDispatch,
                getEntityNameForRegarding(targetAtEntityNumber)
              )
            : String(state["g4c_regardingtype"]) !==
              String(targetAtEntityNumber)
            ? GetEntityAttributes(
                globalDispatch,
                getEntityNameForRegarding(state["g4c_regardingtype"])
              )
            : [],
        ]);
        setFormData({
          attributes:
            attributeResponse && attributeResponse.data
              ? attributeResponse.data
              : [],
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    fetchData();
  };

  const includeRegardingType =
    String(g4c_audiencetype) === String(AudienceType["A/B Testing"])
      ? false
      : isRegardingDifferentToListType(
          g4c_listtype,
          g4c_regardingtype
        );

  const convertTypeNumberToEntityDisplayName = (typeNumber) => {
    switch (String(typeNumber)) {
      case "1":
        return "Booking";
      case "2":
        return "Booking Payment";
      case "3":
        return "Contact";
      case "4":
      case "5":
        return "Membership";
      case "6":
        return "Account";
      case "7":
        return "Coupon";
      case "8":
        return "Voucher";
      default:
        return "";
    }
  };

  const PredefinedAttributes = () => {
    switch (String(selectedEntity)) {
      case "1": //Booking
        return Object.freeze({
          "Booking Reference": "g4b_bookingreference",
          "Total Price": "g4b_totalprice",
        });
      case "2": //Booking Payment
        return Object.freeze({
          "Payment Amount": "g4b_paymentamount",
          "Payment URL": "g4b_paymenturl",
        });
      case "3": //Contact
        return Object.freeze({
          Email: "emailaddress1",
          "First Name": "firstname",
          "Full Name": "fullname",
          "Last Name": "lastname",
          Salutation: "salutation",
        });
      case "4": //Membership (Member)
      case "5": //Membership (Owner)
        return Object.freeze({
          "End Date": "g4m_enddate",
          "Membership Number": "g4m_membershipnumber",
          "Renewal Date": "g4m_renewaldate",
          "Start Date": "g4m_startdate",
        });
      case "6": //Account
        return Object.freeze({
          "Account Name": "name",
        });
      case "7": //Coupon
        return Object.freeze({
          Code: "g4b_couponcode",
          "Full Name": "g4b_customername",
        });
      case "8": //Voucher
        return Object.freeze({
          "Full Name": "g4b_customername",
          "Reference Number": "g4b_referencenumber",
        });
      default:
        return "";
    }
  };

  const handleSelectChange = (event) => {
    const { value } = event;
    if (value && value !== "") {
      let attributeName = "";

      //Check the value against the predefined fields in case that was selected
      let matchedPredefinedField =
        Object.entries(PredefinedAttributes()).find(
          ([_, val]) => val === value
        )?.[1] || "";

      if (matchedPredefinedField) {
        attributeName = matchedPredefinedField;
      }
      //Otherwise check it against the attributes in the formData
      else {
        let filteredAttributes = formData.attributes.filter(
          (att) => att.AttributeId === value
        );
        attributeName =
          filteredAttributes.length === 1
            ? filteredAttributes[0].Name
            : "";
      }

      //Add regarding_ to the front if its the regarding entity
      attributeName =
        selectedEntity === targetAtEntityNumber
          ? attributeName
          : "regarding_" + attributeName;

      setSelectedAttributeName(attributeName);
      setSelectedAttribute(value);
    } else {
      setSelectedAttributeName("");
      setSelectedAttribute("");
    }
  };

  return (
    <Modal
      title={"Add Attribute"}
      modalCloseButtonClick={() => {
        setCustomAction("");
      }}
      className="modal modal-dialog-scrollable"
    >
      <div className="modal-body min-vh-50">
        {loading ? (
          <Loading />
        ) : (
          <>
            {includeRegardingType && (
              <>
                <small>Select an entity type</small>
                <div className="row mb-3">
                  <div key={1} className="col-md-6">
                    <IconCard
                      containerClass={classNames(
                        "border",
                        "rounded",
                        "cursor-pointer",
                        selectedEntity === targetAtEntityNumber
                          ? "border-primary"
                          : ""
                      )}
                      onClick={() => {
                        setSelectedEntity(targetAtEntityNumber);
                        setFormData(null);
                      }}
                    >
                      {convertTypeNumberToEntityDisplayName(
                        targetAtEntityNumber
                      )}
                    </IconCard>
                  </div>
                  <div key={2} className="col-md-6">
                    <IconCard
                      containerClass={classNames(
                        "border",
                        "rounded",
                        "cursor-pointer",
                        selectedEntity === state["g4c_regardingtype"]
                          ? "border-primary"
                          : ""
                      )}
                      onClick={() => {
                        setSelectedEntity(state["g4c_regardingtype"]);
                        setFormData(null);
                      }}
                    >
                      {convertTypeNumberToEntityDisplayName(
                        state["g4c_regardingtype"]
                      )}
                    </IconCard>
                  </div>
                </div>
              </>
            )}
            <small>
              Add an attribute value for the selected recipient type.
            </small>
            <SelectWithFiltering
              label={"Attribute"}
              name={"Attribute"}
              options={
                formData && formData.attributes
                  ? formData.attributes
                      .map((att) => {
                        return {
                          Key: att.AttributeId,
                          Value: att.DisplayName
                            ? att.DisplayName
                            : att.Name,
                        };
                      })
                      .sort((a, b) => a.Value.localeCompare(b.Value))
                  : Object.entries(PredefinedAttributes()).map(
                      ([Name, Number]) => ({
                        Key: Number,
                        Value: Name,
                      })
                    )
              }
              onChange={(event) => handleSelectChange(event)}
              placeholder={"Please select"}
              value={selectedAttribute}
            />
            {!formData && (
              <Button
                className={"mt-3"}
                text={"Fetch remaining attributes"}
                style={ButtonStyle.OutlineSecondary}
                onClick={() => {
                  fetchDataForEntity();
                }}
              />
            )}
          </>
        )}
      </div>
      <div className="modal-footer">
        {!loading && (
          <>
            <Button
              text={"Confirm"}
              style={ButtonStyle.Primary}
              onClick={() => {
                setFieldValue(
                  field.name,
                  values[field.name] +
                    ` <<${selectedAttributeName.toLowerCase()}>>`
                );
                setCustomAction("");
              }}
            />
            <Button
              text={"Cancel"}
              style={ButtonStyle.Info}
              onClick={() => {
                setCustomAction("");
              }}
            />
          </>
        )}
      </div>
    </Modal>
  );
}

export default CommunicationAttributes;
