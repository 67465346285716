import { Fragment } from "react";
import {
  BadgeList,
  Button,
  Card,
  CollapsibleSection,
  ReadOnlyLabel,
} from "../elements/_Elements";
import {
  allowEditOnOverview,
  displayEditButtonOnOverview,
  displayElementOnOverview,
  displayOverviewRender,
  getCustomComponent,
  getFieldLabel,
  handleCheckListChange,
  handleFilteredPicklistChange,
  handlePicklistChange,
  getSectionInitialFilters,
  checkIfUserRoleCanEditRecord,
  getSectionInitiallyCollapsed,
} from "./Helpers";
import { BackgroundStyle, ButtonStyle } from "../../js/enums";
import { DispatchMethods } from "./enums";
import FormFields from "./FormFields";
import FormTable from "./FormTable";
import {
  getImageBase64Src,
  getReadOnlyValue,
} from "../../js/utility";
import FormTableSubmit from "./FormTableSubmit";
import { useGlobalState } from "../../GlobalContext";

function OverviewStage({
  customAction,
  dispatch,
  entityName,
  errors,
  handleBlur,
  handleChange,
  id,
  index,
  pageRefs,
  parentFormEntityName,
  parentFormState,
  setCustomAction,
  setFieldValue,
  setSubForm,
  stage,
  stages,
  state,
  subForm,
  touched,
  values,
}) {
  const globalState = useGlobalState();

  const tableSections = stage.sections.filter(
    (section) => section.table
  );

  return (
    <Card className="mb-3">
      <CollapsibleSection
        allowCollapse={stage.allowCollapse}
        id={id}
        initialCollapsed={getSectionInitiallyCollapsed(
          stage,
          state,
          values
        )}
        title={stage.title}
        sectionControls={
          <>
            {!allowEditOnOverview(stage, state) &&
              displayEditButtonOnOverview(stage, state) &&
              checkIfUserRoleCanEditRecord(globalState, stages) && (
                <Button
                  disabled={subForm !== "" || customAction !== ""}
                  style={ButtonStyle.Link}
                  text={"Edit"}
                  onClick={() =>
                    dispatch({
                      type: DispatchMethods.SetStage,
                      //Need to do findIndex as using the index value won't work
                      //if there are stages on the main and side sections as
                      //those index values will relate to the lists
                      //of main and side section stages rather than the stages as a whole
                      stage: stages.findIndex((e) => e === stage) + 1,
                      skipToOverview: true,
                    })
                  }
                />
              )}
          </>
        }
      >
        <div
          className="row g-3"
          id={stage.id}
          ref={(r) => (pageRefs.current[index] = r)}
        >
          {stage.sections
            .filter(
              (section) =>
                section.fields &&
                section.fields.length > 0 &&
                section.fields.filter((field) =>
                  displayElementOnOverview(
                    field,
                    state,
                    values,
                    globalState
                  )
                ).length > 0
            )
            .map((section, j) => {
              const disabled = subForm !== "" || customAction !== "";
              return allowEditOnOverview(stage, state) ? (
                <FormFields
                  key={j}
                  disabled={disabled}
                  overview={true}
                  {...{
                    dispatch,
                    entityName,
                    errors,
                    handleBlur,
                    handleChange,
                    handleCheckListChange,
                    handleFilteredPicklistChange,
                    handlePicklistChange,
                    parentFormState,
                    section,
                    setCustomAction,
                    setFieldValue,
                    setSubForm,
                    stages,
                    state,
                    touched,
                    values,
                  }}
                />
              ) : (
                <Fragment key={j}>
                  {section.name && (
                    <div className="col-12">
                      <h5>{section.name}</h5>
                    </div>
                  )}
                  {section.fields.some((field) =>
                    displayElementOnOverview(
                      field,
                      state,
                      values,
                      globalState
                    )
                  ) && (
                    <div className="col-12">
                      <div className="row">
                        {section.fields
                          .filter((field) =>
                            displayElementOnOverview(
                              field,
                              state,
                              values,
                              globalState
                            )
                          )
                          .map((field, k) => {
                            return (
                              <div
                                className={
                                  field.overviewClassName
                                    ? field.overviewClassName
                                    : field.className
                                    ? field.className
                                    : "col-md-4"
                                }
                                key={k}
                              >
                                {displayOverviewRender(
                                  field,
                                  state,
                                  values
                                ) ? (
                                  field.overviewRender(
                                    state,
                                    setFieldValue,
                                    values
                                  )
                                ) : field.type === "image" ? (
                                  <>
                                    {state[field.name] && (
                                      <img
                                        src={getImageBase64Src(
                                          state[field.name].MimeType,
                                          state[field.name]
                                            .Base64String
                                        )}
                                        className="overview-image mb-3"
                                        alt=""
                                      />
                                    )}
                                  </>
                                ) : field.type === "fileupload" ? (
                                  <>
                                    {state[field.name] && (
                                      <ReadOnlyLabel
                                        label={getFieldLabel(
                                          field,
                                          state,
                                          values,
                                          globalState
                                        )}
                                        value={getReadOnlyValue(
                                          field,
                                          state,
                                          values,
                                          globalState
                                        )}
                                      />
                                    )}
                                  </>
                                ) : field.type === "checklist" ? (
                                  <BadgeList
                                    backgroundStyle={
                                      BackgroundStyle.Info
                                    }
                                    items={
                                      state[field.name + "name"]
                                        ? state[
                                            field.name + "name"
                                          ].map((x) => {
                                            return { text: x };
                                          })
                                        : []
                                    }
                                    label={field.label}
                                  />
                                ) : field.type === "component" &&
                                  field.displayComponentOnOverview ? (
                                  getCustomComponent(
                                    disabled,
                                    dispatch,
                                    entityName,
                                    errors,
                                    field,
                                    handleBlur,
                                    handleChange,
                                    handleCheckListChange,
                                    null,
                                    handleFilteredPicklistChange,
                                    handlePicklistChange,
                                    null,
                                    parentFormState,
                                    setFieldValue,
                                    setSubForm,
                                    stages,
                                    state,
                                    null,
                                    values
                                  )
                                ) : (
                                  <ReadOnlyLabel
                                    field={field}
                                    label={getFieldLabel(
                                      field,
                                      state,
                                      values,
                                      globalState
                                    )}
                                    state={state}
                                    value={getReadOnlyValue(
                                      field,
                                      state,
                                      values,
                                      globalState
                                    )}
                                  />
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          {tableSections.map((section, j) => {
            if (displayOverviewRender(section.table, state, values)) {
              return section.table.overviewRender(
                state,
                setFieldValue,
                values
              );
            }

            const initialFilters = getSectionInitialFilters(
              section,
              state.id
            );

            // don't show the table if the record hasn't been created yet
            // but display a message indicating that finishing the creation of the
            // new  record would make it appear
            if (initialFilters.length > 0 && !state.id) {
              return (
                <Fragment key={j}>
                  {section.name && <h5>{section.name}</h5>}
                  <div className="mb-3">
                    <strong>
                      Please save the current record to view the table
                      for this section
                    </strong>
                  </div>
                </Fragment>
              );
            }

            const TableComponent =
              section.table && section.table.useSubmitButton
                ? FormTableSubmit
                : FormTable;

            return (
              <Fragment key={j}>
                <TableComponent
                  {...{
                    dispatch,
                    section,
                    setSubForm,
                    state,
                    subForm,
                    values,
                  }}
                  disabled={subForm !== "" || customAction !== ""}
                  initialFilters={initialFilters}
                  parentId={state.id}
                  subFormParentFormEntityName={parentFormEntityName}
                  subFormParentFormState={parentFormState}
                />
              </Fragment>
            );
          })}
        </div>
      </CollapsibleSection>
    </Card>
  );
}

export default OverviewStage;
