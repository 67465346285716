import { Button, Modal } from "../../elements/_Elements";
import { ButtonStyle } from "../../../js/enums";

function ABTestConfirmPublishModal({
  onSubmit,
  setConfirmationModal,
  setFieldValue,
  values,
}) {
  const getConfirmationModalTitleText = () => {
    return String(values.g4c_starttype) === "0" //Immediately
      ? "Publish A/B Test Immediately"
      : String(values.g4c_starttype) === "1" //Scheduled
      ? "Publish Scheduled A/B Test"
      : "Publish";
  };

  const getConfirmationModalBodyText = () => {
    return String(values.g4c_starttype) === "0" //Immediately
      ? "Do you want to publish this A/B Test? This action will attempt to publish this A/B Test immediately."
      : String(values.g4c_starttype) === "1" //Scheduled
      ? `Do you want to publish this A/B Test? This action will attempt to schedule the publishing of this A/B Test from ${values.g4c_startdate} to ${values.g4c_enddate}`
      : "";
  };

  const getConfirmationModalConfirmButtonText = () => {
    return String(values.g4c_starttype) === "0" //Immediately
      ? "Publish Immediately"
      : String(values.g4c_starttype) === "1" //Scheduled
      ? "Schedule Publish"
      : "Confirm";
  };

  return (
    <Modal
      title={getConfirmationModalTitleText()}
      modalCloseButtonClick={() => {
        setConfirmationModal(null);
      }}
      className="modal modal-dialog-scrollable"
    >
      <div className="modal-body">
        <small>{getConfirmationModalBodyText()}</small>
      </div>
      <div className="modal-footer">
        <Button
          text={getConfirmationModalConfirmButtonText()}
          style={ButtonStyle.Primary}
          onClick={() => {
            setFieldValue("action", "saveAndPublish");
            setConfirmationModal(null);
            onSubmit();
          }}
        />
        <Button
          text={"Cancel"}
          style={ButtonStyle.Info}
          onClick={() => {
            setConfirmationModal(null);
          }}
        />
      </div>
    </Modal>
  );
}

export default ABTestConfirmPublishModal;
