import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { checkRelatedEntityHasRecords } from "../components/form/Helpers";
import { DonationStatus, TelegramQueueStage } from "../js/enums";
import { checkIfSellFixturesAndSeriesSettingOn } from "../js/utility";
import { BookingTicketFormStages } from "./bookingTicket";
import { DonationFormStages } from "./donation";
import lookups from "./lookups";
import { TelegramQueueFormStages } from "./telegramQueue";

export const BookingProductBundleContentItemFormStages = [
  {
    title: "General",
    id: "general",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_productbundleitemidname",
            className: "col-md-6",
            description: "",
            label: "Product Bundle Item",
            type: "nvarchar",
          },
          {
            name: "g4b_variantidname",
            className: "col-md-6",
            description: "",
            label: "Product Variant",
            type: "int",
          },
          {
            name: "g4b_fixtureidname",
            className: "col-md-6",
            description: "",
            displayIfFromGlobalState: (globalState) => {
              return checkIfSellFixturesAndSeriesSettingOn(
                globalState
              );
            },
            label: "Fixture",
            type: "nvarchar",
          },
          {
            name: "g4b_seriesidname",
            className: "col-md-6",
            description: "",
            displayIfFromGlobalState: (globalState) => {
              return checkIfSellFixturesAndSeriesSettingOn(
                globalState
              );
            },
            label: "Series",
            type: "nvarchar",
          },
          {
            name: "g4b_startdatetime",
            className: "col-md-6",
            description: "",
            label: "Start",
            type: "datetime",
          },
          {
            name: "g4b_enddatetime",
            className: "col-md-6",
            description: "",
            label: "End",
            type: "datetime",
          },
          {
            name: "g4b_netprice",
            className: "col-md-6",
            description: "",
            label: "Net Price",
            type: "money",
          },
          {
            name: "g4b_price",
            className: "col-md-6",
            description: "",
            label: "Price",
            type: "money",
          },
          {
            name: "g4b_taxrate",
            className: "col-md-6",
            description: "",
            label: "Tax Rate (%)",
            type: "decimal",
          },
          {
            name: "g4b_taxamount",
            className: "col-md-6",
            description: "",
            label: "Tax Amount",
            type: "money",
          },
        ],
      },
    ],
  },
  {
    title: "Journal Entries",
    id: "journal-entries",
    displayEditButtonFromState: () => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          fields: [
            {
              name: "g4b_people",
              label: "People",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_units",
              label: "Units",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_value",
              label: "Value",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_netvalue",
              label: "Net Value",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_valueused",
              label: "Value Used (%)",
              className: "col-number",
            },
            {
              name: "g4b_tax",
              label: "Tax",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_commission",
              label: "Commission",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_commissioncategoryidname",
              label: "Commission Category",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_journalentry",
          lookup: lookups.g4b_journalentry.all,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Booking Tickets",
    id: "booking-tickets",
    displayEditButtonFromState: () => false,
    sectionInitiallyCollapsedFromState: (state) =>
      !checkRelatedEntityHasRecords("g4b_ticket", state),
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_utid",
              label: "UTID",
              className: "col-short-name",
              type: "string",
            },
            {
              name: "g4b_barcode",
              label: "Barcode",
              className: "col-short-name",
            },
            {
              name: "g4b_printed",
              className: "col-bool",
              label: "Is Printed?",
            },
            {
              name: "g4b_iscancelled",
              className: "col-bool",
              label: "Is Cancelled?",
            },
            {
              name: "g4b_whitelisted",
              className: "col-bool",
              label: "Is Whitelisted?",
            },
            {
              name: "g4b_blacklisted",
              className: "col-bool",
              label: "Is Blacklisted?",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_ticket",
          lookup: lookups.g4b_ticket.all,
          linkForNewRecord: "/booking-ticket",
          subFormStages: BookingTicketFormStages,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Telegram Queues",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sectionInitiallyCollapsedFromState: (state) =>
      !checkRelatedEntityHasRecords("g4b_telegramqueue", state),
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_sequencenumber",
              label: "Sequence Number",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_type",
              label: "Type",
              className: "col-short-name",
            },
            {
              name: "g4b_stage",
              className: "col-name",
              label: "Stage",
              type: "picklist",
              enum: TelegramQueueStage,
            },
            {
              name: "g4b_accesscontrolprovideridname",
              label: "Access Control Provider",
              className: "col-short-name",
            },
            {
              name: "g4b_fixtureidname",
              displayIfFromGlobalState: (globalState) => {
                return checkIfSellFixturesAndSeriesSettingOn(
                  globalState
                );
              },
              label: "Fixture",
              className: "col-short-name",
            },
            {
              name: "g4b_seriesidname",
              displayIfFromGlobalState: (globalState) => {
                return checkIfSellFixturesAndSeriesSettingOn(
                  globalState
                );
              },
              label: "Series",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4b_telegramqueue",
          lookup: lookups.g4b_telegramqueue.all,
          linkForNewRecord: "/telegram-queue",
          subFormStages: TelegramQueueFormStages,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Donations",
    id: "booking-donations",
    displayEditButtonFromState: () => false,
    sectionInitiallyCollapsedFromState: (state) =>
      !checkRelatedEntityHasRecords("g4d_donation", state),
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          fields: [
            {
              name: "g4d_declarationidname",
              className: "col-short-name",
              label: "Declaration",
              type: "string",
            },
            {
              name: "g4d_fundidname",
              className: "col-short-name",
              label: "Fund",
              type: "string",
            },
            {
              name: "g4d_donationstatus",
              className: "col-short-name",
              label: "Donation Status",
              type: "statusbadge",
              enum: DonationStatus,
            },
            {
              name: "g4d_donationvalue",
              className: "col-short-name",
              label: "Donation value",
              type: "money",
            },
            {
              name: "g4d_giftaidvalue",
              className: "col-short-name",
              label: "Gift Aid Value",
              type: "money",
            },
            {
              name: "g4m_membershipidname",
              className: "col-short-name",
              label: "Membership",
              type: "string",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4d_donation",
          lookup: lookups.g4d_donation.all,
          subFormStages: DonationFormStages,
          useSubForm: true,
        },
      },
    ],
  },
];
