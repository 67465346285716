import { getDateString } from "../../Helpers";

function CouponTableCell({
  children,
  className,
  fontFamily,
  fontSize,
  fontWeight,
  padding,
  textColour,
}) {
  return (
    <td
      className={className}
      style={{
        color: textColour,
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontWeight: fontWeight,
        padding: padding,
      }}
    >
      {children}
    </td>
  );
}

function ActualCouponRow({
  backgroundColour,
  borderColour,
  borderThickness,
  borderType,
  code,
  fontFamily,
  fontSize,
  fontWeight,
  localeCode,
  longDateFormat,
  margin,
  padding,
  redeemBy,
  redeemFrom,
  redeemLink,
  termsLink,
  textColour,
  useBy,
}) {
  return (
    <tr>
      <td style={{ padding: margin }}>
        <table
          className="full-width zero-margin"
          cellSpacing="0"
          style={{
            borderColor: borderColour,
            borderStyle: borderType,
            borderWidth: borderThickness,
          }}
        >
          <tbody>
            <tr style={{ backgroundColor: backgroundColour }}>
              <CouponTableCell
                {...{
                  fontFamily,
                  fontSize,
                  fontWeight,
                  padding,
                  textColour,
                }}
              >
                <p style={{ fontSize: "20px" }}>
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: '<xsl:value-of select="CouponName" />',
                    }}
                  ></strong>
                </p>
              </CouponTableCell>
            </tr>
            <tr style={{ backgroundColor: backgroundColour }}>
              <td>
                <table className="mobile-full2 column" align="left">
                  <tbody>
                    <tr>
                      <CouponTableCell
                        {...{
                          fontFamily,
                          fontSize,
                          fontWeight,
                          padding,
                          textColour,
                        }}
                      >
                        <p
                          data-xsl-wrap="xsl:if"
                          data-xsl-attributes='test="CouponCode"'
                        >
                          <strong>{code} </strong>
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                '<xsl:value-of select="CouponCode" />',
                            }}
                          />
                        </p>
                        <p
                          data-xsl-wrap="xsl:if"
                          data-xsl-attributes='test="RedeemFromDate"'
                        >
                          <strong>{redeemFrom} </strong>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `<xsl:value-of select="g4:TranslateDate(RedeemFromDate, '${longDateFormat}', '${localeCode}')" />`,
                            }}
                          />
                        </p>
                        <p
                          data-xsl-wrap="xsl:if"
                          data-xsl-attributes='test="RedeemByDate"'
                        >
                          <strong>{redeemBy} </strong>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `<xsl:value-of select="g4:TranslateDate(RedeemByDate, '${longDateFormat}', '${localeCode}')" />`,
                            }}
                          />
                        </p>
                        <p
                          data-xsl-wrap="xsl:if"
                          data-xsl-attributes='test="UseByDate"'
                        >
                          <strong>{useBy} </strong>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `<xsl:value-of select="g4:TranslateDate(UseByDate, '${longDateFormat}', '${localeCode}')" />`,
                            }}
                          />
                        </p>
                      </CouponTableCell>
                    </tr>
                  </tbody>
                </table>
                <div
                  className="inner-content-only"
                  dangerouslySetInnerHTML={{
                    __html: "<!--[if mso]></td><td><![endif]--></td>",
                  }}
                ></div>
                <table className="mobile-full2 column" align="left">
                  <tbody>
                    <tr>
                      <CouponTableCell
                        {...{
                          fontFamily,
                          fontSize,
                          fontWeight,
                          padding,
                          textColour,
                        }}
                      >
                        <div
                          className="inner-content-only"
                          dangerouslySetInnerHTML={{
                            __html: `<xsl:variable name="couponCodeUrl" select="concat('https://www.eventdata.co.uk/Library/Symbology/Symbol.aspx?Type=128{HtmlEncode:&Dpi=300&}Data=', CouponCode)"></xsl:variable>`,
                          }}
                        ></div>
                        <p
                          style={{ textAlign: "center" }}
                          dangerouslySetInnerHTML={{
                            __html:
                              '<xsl:img src="{$couponCodeUrl}" style="max-width: 100%; width: 268px;" width="268"></xsl:img>',
                          }}
                        ></p>
                      </CouponTableCell>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            {(redeemLink || termsLink) && (
              <tr
                style={{
                  backgroundColor: backgroundColour,
                }}
              >
                <CouponTableCell
                  {...{
                    fontFamily,
                    fontSize,
                    fontWeight,
                    padding,
                    textColour,
                  }}
                >
                  {redeemLink && (
                    <p style={{ float: "left" }}>
                      <a href={redeemLink}>Redeem Online</a>
                    </p>
                  )}
                  {termsLink && (
                    <p style={{ float: "right" }}>
                      <a href={termsLink}>View Terms & Conditions</a>
                    </p>
                  )}
                </CouponTableCell>
              </tr>
            )}
          </tbody>
        </table>
      </td>
    </tr>
  );
}

function ActualCoupon({
  backgroundColour,
  borderColour,
  borderThickness,
  borderType,
  code,
  fontFamily,
  fontSize,
  fontWeight,
  localeCode,
  longDateFormat,
  margin,
  padding,
  redeemBy,
  redeemFrom,
  redeemLink,
  termsLink,
  textColour,
  useBy,
}) {
  return (
    <table
      className="full-width zero-margin hide-on-designer"
      cellSpacing="0"
    >
      <tbody>
        <ActualCouponRow
          {...{
            backgroundColour,
            borderColour,
            borderThickness,
            borderType,
            code,
            fontFamily,
            fontSize,
            fontWeight,
            localeCode,
            longDateFormat,
            margin,
            padding,
            redeemBy,
            redeemFrom,
            redeemLink,
            termsLink,
            textColour,
            useBy,
          }}
        />
      </tbody>
    </table>
  );
}

function SampleCoupon({
  backgroundColour,
  borderColour,
  borderThickness,
  borderType,
  code,
  fontFamily,
  fontSize,
  fontWeight,
  margin,
  padding,
  redeemBy,
  redeemFrom,
  redeemLink,
  termsLink,
  textColour,
  useBy,
}) {
  return (
    <table
      className="full-width zero-margin remove-item"
      cellSpacing="0"
    >
      <tbody>
        <tr>
          <td style={{ padding: margin }}>
            <table
              className="full-width zero-margin"
              cellSpacing="0"
              style={{
                borderColor: borderColour,
                borderStyle: borderType,
                borderWidth: borderThickness,
              }}
            >
              <tbody>
                <tr style={{ backgroundColor: backgroundColour }}>
                  <CouponTableCell
                    {...{
                      fontFamily,
                      fontSize,
                      fontWeight,
                      padding,
                      textColour,
                    }}
                  >
                    <p style={{ fontSize: "20px" }}>
                      <strong>Coupon Type</strong>
                    </p>
                  </CouponTableCell>
                </tr>
                <tr style={{ backgroundColor: backgroundColour }}>
                  <td>
                    <table
                      className="mobile-full2 column"
                      align="left"
                    >
                      <tbody>
                        <tr>
                          <CouponTableCell
                            {...{
                              fontFamily,
                              fontSize,
                              fontWeight,
                              padding,
                              textColour,
                            }}
                          >
                            <p>
                              <strong>{code} </strong>
                              SJ20N-F3UD2-301U5
                            </p>
                            <p>
                              <strong>{redeemFrom} </strong>
                              {getDateString(
                                0,
                                0,
                                "dddd D MMMM YYYY"
                              )}
                            </p>
                            <p>
                              <strong>{redeemBy} </strong>
                              {getDateString(
                                0,
                                2,
                                "dddd D MMMM YYYY"
                              )}
                            </p>
                            <p>
                              <strong>{useBy} </strong>
                              {getDateString(
                                0,
                                6,
                                "dddd D MMMM YYYY"
                              )}
                            </p>
                          </CouponTableCell>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      className="inner-content-only"
                      dangerouslySetInnerHTML={{
                        __html:
                          "<!--[if mso]></td><td><![endif]--></td>",
                      }}
                    ></div>
                    <table
                      className="mobile-full2 column"
                      align="left"
                    >
                      <tbody>
                        <tr>
                          <CouponTableCell
                            {...{
                              fontFamily,
                              fontSize,
                              fontWeight,
                              padding,
                              textColour,
                            }}
                          >
                            <p style={{ textAlign: "center" }}>
                              <img
                                src="https://www.eventdata.co.uk/Library/Symbology/Symbol.aspx?Type=128&Dpi=300&Data=SJ20N-F3UD2-301U5"
                                alt="SJ20N-F3UD2-301U5"
                                style={{ maxWidth: "100%" }}
                                width="268"
                              />
                            </p>
                          </CouponTableCell>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                {(redeemLink || termsLink) && (
                  <tr
                    style={{
                      backgroundColor: backgroundColour,
                    }}
                  >
                    <CouponTableCell
                      {...{
                        fontFamily,
                        fontSize,
                        fontWeight,
                        padding,
                        textColour,
                      }}
                    >
                      {redeemLink && (
                        <p style={{ float: "left" }}>
                          <a href={redeemLink}>Redeem Online</a>
                        </p>
                      )}
                      {termsLink && (
                        <p style={{ float: "right" }}>
                          <a href={termsLink}>
                            View Terms & Conditions
                          </a>
                        </p>
                      )}
                    </CouponTableCell>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export { ActualCoupon, ActualCouponRow, SampleCoupon };
