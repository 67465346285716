import { useEffect, useState } from "react";
import {
  checkIfSellFixturesAndSeriesSettingOn,
  getUpdatedLookupOptionsValues,
} from "../../../js/utility";
import {
  BeneficiaryRequirement,
  ButtonStyle,
  LoadingState,
  ProductType,
  ProductTypeNoFixturesAndSeries,
  TimeInterval,
} from "../../../js/enums";
import lookups from "../../../forms/lookups";
import {
  Button,
  Input,
  Loading,
  Modal,
  SelectWithFiltering,
} from "../../elements/_Elements";
import {
  useGlobalReducer,
  useGlobalState,
} from "../../../GlobalContext";
import { Create } from "../../../js/service";
import { getDataFromStateLookupOrServices } from "./Helpers";
import { useFormReducer } from "../FormContext";
import { DispatchMethods } from "../enums";
import { getLookupInState } from "../Helpers";

function ProductBundleItemProductCreate({
  entityName,
  handleChange,
  name,
  setDisplayAddNewProductModal,
  productBundleItems,
  state,
}) {
  const dispatch = useFormReducer();

  const [isCreating, setIsCreating] = useState(null);
  const [loading, setLoading] = useState(LoadingState.NotLoaded);
  const [initialLoad, setInitialLoad] = useState(true);
  const [bookableResourceData, setBookableResourceData] =
    useState(null);
  const [calendarData, setCalendarData] = useState(null);
  const [couponTypeData, setCouponTypeData] = useState(null);
  const [fundData, setFundData] = useState(null);
  const [seriesData, setSeriesData] = useState(null);
  useState(null);
  const [variantTypeData, setVariantTypeData] = useState(null);
  const [variantTypeCategoryData, setVariantTypeCategoryData] =
    useState(null);

  const [newProductRecordType, setNewProductRecordType] =
    useState(null);
  const [newProductRecordName, setNewProductRecordName] =
    useState("");
  const [newProductRecordCapacity, setNewProductRecordCapacity] =
    useState(null);
  const [
    newProductRecordBookableResource,
    setNewProductRecordBookableResource,
  ] = useState(null);
  const [
    newProductRecordBeneficiaryRequirement,
    setNewProductRecordBeneficiaryRequirement,
  ] = useState(null);
  const [newProductRecordSeriesId, setNewProductRecordSeriesId] =
    useState(null);
  const [
    newProductRecordCouponProductTypeId,
    setNewProductRecordCouponProductTypeId,
  ] = useState(null);
  const [
    newProductRecordVoucherValidForDays,
    setNewProductRecordVoucherValidForDays,
  ] = useState(null);
  const [newProductRecordFund, setNewProductRecordFund] =
    useState(null);
  const [
    newProductRecordDonationPercentage,
    setNewProductRecordDonationPercentage,
  ] = useState(null);

  const [
    newProductRecordCollectStartDate,
    setNewProductRecordCollectStartDate,
  ] = useState(null);
  const [newProductRecordDuration, setNewProductRecordDuration] =
    useState(null);
  const [newProductRecordInterval, setNewProductRecordInterval] =
    useState(null);
  const [newProductRecordCalendar, setNewProductRecordCalendar] =
    useState(null);
  const [
    newProductRecordVariantTypeCategory,
    setNewProductRecordVariantTypeCategory,
  ] = useState(null);
  const [
    newProductRecordVariantTypes,
    setNewProductRecordVariantTypes,
  ] = useState(null);

  const globalDispatch = useGlobalReducer();
  const globalState = useGlobalState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getDataFromStateLookupOrServices(
          globalDispatch,
          [
            {
              entityName: "g4b_bookableresource",
              lookupOptions: getLookupInState(
                state,
                lookups.g4b_bookableresource.all
              ),
              setData: setBookableResourceData,
            },
            {
              entityName: "g4b_productcalendar",
              lookupOptions: getLookupInState(
                state,
                lookups.g4b_productcalendar.all
              ),
              setData: setCalendarData,
            },
            {
              entityName: "g4b_coupontype",
              lookupOptions: getLookupInState(
                state,
                lookups.g4b_coupontype.all
              ),
              setData: setCouponTypeData,
            },
            {
              entityName: "g4d_fund",
              lookupOptions: getLookupInState(
                state,
                lookups.g4d_fund.all
              ),
              setData: setFundData,
            },
            {
              entityName: "g4b_series",
              lookupOptions: getLookupInState(
                state,
                lookups.g4b_series.all
              ),
              setData: setSeriesData,
            },
            {
              entityName: "g4b_varianttype",
              lookupOptions: getLookupInState(
                state,
                lookups.g4b_varianttype.all
              ),
              setData: setVariantTypeData,
            },
            {
              entityName: "g4b_varianttypecategory",
              lookupOptions: getLookupInState(
                state,
                lookups.g4b_varianttypecategory.all
              ),
              setData: setVariantTypeCategoryData,
            },
          ],
          setLoading,
          setInitialLoad
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (initialLoad) {
      fetchData();
    }
  }, [globalDispatch, initialLoad, state]);

  const createProductForBundle = async () => {
    let response = null;
    setIsCreating(true);
    try {
      const successMessage = `${newProductRecordName} successfully created`;

      let attributes = {
        g4b_type: newProductRecordType,
        g4b_name: newProductRecordName,
        g4b_capacity: newProductRecordCapacity,
        g4b_bookableresourceid: newProductRecordBookableResource,
        g4b_beneficiaryrequirement:
          String(newProductRecordType) ===
          String(ProductType.Membership)
            ? BeneficiaryRequirement.Required
            : newProductRecordBeneficiaryRequirement,
        g4b_seriesid: newProductRecordSeriesId,
        g4b_couponproducttypeid: newProductRecordCouponProductTypeId,
        g4b_vouchervalidfordays: newProductRecordVoucherValidForDays,
        g4b_fund_id: newProductRecordFund,
        g4b_donationpercentage: newProductRecordDonationPercentage,
        g4b_collectstartdate: newProductRecordCollectStartDate,
        g4b_duration: newProductRecordDuration,
        g4b_interval: newProductRecordInterval,
        g4b_productcalendarid: newProductRecordCalendar,
        g4b_reportname: newProductRecordName.toUpperCase(),
        g4b_varianttypecategory: newProductRecordVariantTypeCategory,
        productVariants:
          newProductRecordVariantTypes &&
          newProductRecordVariantTypes.length > 0
            ? newProductRecordVariantTypes.map((type) => {
                return {
                  Id: null,
                  Code: "",
                  MandatoryVariant: false,
                  MarketingListId: null,
                  NotForSale: false,
                  PriceListItems: [],
                  TaxCodeId: null,
                  VariantTypeId: type.key,
                };
              })
            : [],
        g4b_categoryid: state.g4b_categoryid,
      };

      const [serviceResponse] = await Promise.all([
        Create(
          globalDispatch,
          successMessage,
          {
            Id: state.id,
            Name: newProductRecordName,
            Attributes: attributes,
          },
          entityName
        ),
      ]);
      response = serviceResponse.data;
      // add the new product bundle item
      let newValue = [...productBundleItems];
      newValue.push({
        Id: null,
        ProductId: response.Id,
        ProductName: newProductRecordName,
        VariantTypeId: null,
        FixedPrice: null,
        Percentage: null,
        FixedRevenueShare: false,
        CollectGiftAid: false,
        SetAsParentProductBundleItem: false,
      });
      handleChange({
        target: {
          name: name,
          value: newValue,
        },
      });

      //Update the productLookup and productVariantsLookup in state
      let updatedLookupOptions = getUpdatedLookupOptionsValues(
        { Key: response.Id, Value: response.Name },
        lookups.g4b_productbase.all,
        state.lookupOptions
      );

      var newProductVariants = response.RelatedEntities.find(
        (r) => r.EntityName === "g4b_protoproduct"
      );
      if (
        newProductVariants &&
        newProductVariants.Entities &&
        newProductVariants.Entities.length > 0
      ) {
        newProductVariants.Entities.forEach((pv) => {
          updatedLookupOptions = getUpdatedLookupOptionsValues(
            {
              Key: pv.Id,
              Value: pv.Name,
              ProductId: response.Id,
              VariantTypeId: pv.Fields.g4b_varianttypeid,
            },
            lookups.g4b_protoproduct.all,
            updatedLookupOptions
          );
        });
      }
      dispatch({
        type: DispatchMethods.SetLookupOptions,
        lookupOptions: updatedLookupOptions,
      });

      setDisplayAddNewProductModal(null);
    } catch (error) {
      console.error(error);
    } finally {
      setIsCreating(false);
    }
  };

  const productTypeEnum = checkIfSellFixturesAndSeriesSettingOn(
    globalState
  )
    ? ProductType
    : ProductTypeNoFixturesAndSeries;

  return (
    <Modal
      title={"Create Product for Bundle Item"}
      modalCloseButtonClick={() => setDisplayAddNewProductModal(null)}
      className="modal modal-dialog-scrollable modal-fullscreen"
    >
      {loading !== LoadingState.Loaded ? (
        <Loading />
      ) : (
        <>
          <div className="modal-body">
            <small>
              {
                "Enter values for the new product record. The new product will retain the same category set on this bundle product."
              }
            </small>
            <SelectWithFiltering
              className={"mb-3"}
              label={"Type"}
              mandatory={true}
              name={"NewProductRecordType"}
              options={Object.entries(productTypeEnum)
                .filter((key) => key[0] !== "Bundle")
                .map(([Name, Number]) => ({
                  Key: Number,
                  Value: Name,
                }))}
              onChange={(event) =>
                setNewProductRecordType(event.value)
              }
              value={newProductRecordType}
            />
            {newProductRecordType && (
              <>
                <Input
                  className={"mb-3"}
                  label={"Name"}
                  mandatory={true}
                  name={"NewProductRecordName"}
                  onChange={(event) =>
                    setNewProductRecordName(event.target.value)
                  }
                  value={newProductRecordName}
                />
                <SelectWithFiltering
                  className={"mb-3"}
                  label={"Variant Type Category"}
                  mandatory={true}
                  name={"NewProductRecordVariantTypeCategory"}
                  options={variantTypeCategoryData}
                  onChange={(event) => {
                    setNewProductRecordVariantTypeCategory(
                      event.value
                    );
                    setNewProductRecordVariantTypes([]);
                  }}
                  value={newProductRecordVariantTypeCategory}
                />
                <SelectWithFiltering
                  className={"mb-3"}
                  label={"Variant Type"}
                  mandatory={true}
                  name={"NewProductRecordVariantTypeCategory"}
                  optionGroups={
                    variantTypeCategoryData
                      ? variantTypeCategoryData
                          .filter(
                            (category) =>
                              // return categories which have variant types and are not filtered out by the category filter
                              variantTypeData.some(
                                (option) =>
                                  option.VariantTypeCategoryId ===
                                  category.Key
                              ) &&
                              (!newProductRecordVariantTypeCategory ||
                                newProductRecordVariantTypeCategory ===
                                  "" ||
                                newProductRecordVariantTypeCategory ===
                                  category.Key)
                          )
                          .map((category) => ({
                            Key: category.Value,
                            Value: variantTypeData.filter(
                              (option) =>
                                // return variant types which are in the category and not already added
                                option.VariantTypeCategoryId ===
                                  category.Key &&
                                (!newProductRecordVariantTypes ||
                                  !newProductRecordVariantTypes.some(
                                    (variantType) =>
                                      variantType === option.Key
                                  ))
                            ),
                          }))
                      : []
                  }
                  isMulti={true}
                  onChange={(event) =>
                    setNewProductRecordVariantTypes(event)
                  }
                  value={
                    newProductRecordVariantTypes &&
                    newProductRecordVariantTypes.length > 0
                      ? newProductRecordVariantTypes.map(
                          (v) => v.value
                        )
                      : []
                  }
                />
                {String(newProductRecordType) ===
                  String(ProductType.Coupon) && (
                  <>
                    {couponTypeData && couponTypeData.length > 0 && (
                      <SelectWithFiltering
                        className={"mb-3"}
                        label={"Coupon Type"}
                        mandatory={true}
                        name={"NewProductRecordCouponType"}
                        options={couponTypeData}
                        onChange={(event) =>
                          setNewProductRecordCouponProductTypeId(
                            event.value
                          )
                        }
                        value={newProductRecordCouponProductTypeId}
                      />
                    )}
                  </>
                )}
                {String(newProductRecordType) ===
                  String(ProductType.Voucher) && (
                  <>
                    <Input
                      className={"mb-3"}
                      label={"Voucher Valid for Days"}
                      mandatory={true}
                      name={"NewProductRecordVoucherValidForDays"}
                      onChange={(event) =>
                        setNewProductRecordVoucherValidForDays(
                          event.target.value
                        )
                      }
                      type="int"
                      value={newProductRecordVoucherValidForDays}
                    />
                  </>
                )}
                {[
                  String(ProductType.AutoScheduled),
                  String(ProductType.Scheduled),
                  String(ProductType.Fixture),
                  String(ProductType.Series),
                ].includes(String(newProductRecordType)) && (
                  <>
                    {bookableResourceData &&
                      bookableResourceData.length > 0 && (
                        <SelectWithFiltering
                          className={"mb-3"}
                          label={"Bookable Resource"}
                          mandatory={true}
                          name={"NewProductRecordBookableResource"}
                          options={bookableResourceData}
                          onChange={(event) =>
                            setNewProductRecordBookableResource(
                              event.value
                            )
                          }
                          value={newProductRecordBookableResource}
                        />
                      )}
                  </>
                )}
                {[
                  String(ProductType.AutoScheduled),
                  String(ProductType.Fixture),
                  String(ProductType.Pass),
                  String(ProductType.Scheduled),
                  String(ProductType.Series),
                ].includes(String(newProductRecordType)) && (
                  <SelectWithFiltering
                    className={"mb-3"}
                    label={"Beneficiary Requirement"}
                    mandatory={true}
                    name={"NewProductRecordBeneficaryRequirement"}
                    options={Object.entries(
                      BeneficiaryRequirement
                    ).map(([Name, Number]) => ({
                      Key: Number,
                      Value: Name,
                    }))}
                    onChange={(event) =>
                      setNewProductRecordBeneficiaryRequirement(
                        event.value
                      )
                    }
                    value={newProductRecordBeneficiaryRequirement}
                  />
                )}
                {[
                  String(ProductType.Membership),
                  String(ProductType.Pass),
                ].includes(String(newProductRecordType)) && (
                  <>
                    <Input
                      className={"mb-3"}
                      label={"Collect Start Date"}
                      mandatory={true}
                      name={"NewProductRecordCollectStartDate"}
                      onChange={(event) =>
                        setNewProductRecordCollectStartDate(
                          event.target.checked
                        )
                      }
                      type="bit"
                      checked={newProductRecordCollectStartDate}
                    />
                  </>
                )}
                {[
                  String(ProductType.Fixture),
                  String(ProductType.Series),
                ].includes(String(newProductRecordType)) && (
                  <>
                    {seriesData && seriesData.length > 0 && (
                      <SelectWithFiltering
                        className={"mb-3"}
                        label={"Series"}
                        mandatory={true}
                        name={"NewProductRecordSeries"}
                        options={seriesData}
                        onChange={(event) =>
                          setNewProductRecordSeriesId(event.value)
                        }
                        value={newProductRecordSeriesId}
                      />
                    )}
                  </>
                )}
                {String(newProductRecordType) ===
                  String(ProductType.Donation) && (
                  <>
                    <SelectWithFiltering
                      className={"mb-3"}
                      label={"Fund"}
                      mandatory={true}
                      name={"NewProductRecordFund"}
                      options={fundData}
                      onChange={(event) =>
                        setNewProductRecordFund(event.value)
                      }
                      value={newProductRecordFund}
                    />
                    <Input
                      className={"mb-3"}
                      label={"Donation Percentage"}
                      mandatory={true}
                      name={"NewProductRecordDonationPercentage"}
                      onChange={(event) =>
                        setNewProductRecordDonationPercentage(
                          event.target.value
                        )
                      }
                      type="int"
                      value={newProductRecordDonationPercentage}
                    />
                  </>
                )}
                {[
                  String(ProductType.AutoScheduled),
                  String(ProductType.Scheduled),
                ].includes(String(newProductRecordType)) && (
                  <>
                    <Input
                      className={"mb-3"}
                      label={"Capacity"}
                      mandatory={true}
                      name={"NewProductRecordCapacity"}
                      onChange={(event) =>
                        setNewProductRecordCapacity(
                          event.target.value
                        )
                      }
                      type="int"
                      value={newProductRecordCapacity}
                    />
                    <SelectWithFiltering
                      className={"mb-3"}
                      label={"Duration"}
                      mandatory={true}
                      name={"NewProductRecordDuration"}
                      options={Object.entries(TimeInterval).map(
                        ([Name, Number]) => ({
                          Key: Number,
                          Value: Name,
                        })
                      )}
                      onChange={(event) =>
                        setNewProductRecordDuration(event.value)
                      }
                      value={newProductRecordDuration}
                    />
                    {String(newProductRecordType) ===
                      String(ProductType.AutoScheduled) && (
                      <SelectWithFiltering
                        className={"mb-3"}
                        label={"Interval"}
                        mandatory={true}
                        name={"NewProductRecordInterval"}
                        options={Object.entries(TimeInterval).map(
                          ([Name, Number]) => ({
                            Key: Number,
                            Value: Name,
                          })
                        )}
                        onChange={(event) =>
                          setNewProductRecordInterval(event.value)
                        }
                        value={newProductRecordInterval}
                      />
                    )}
                  </>
                )}
                {([
                  String(ProductType.AutoScheduled),
                  String(ProductType.Scheduled),
                ].includes(String(newProductRecordType)) ||
                  ([
                    String(ProductType.Membership),
                    String(ProductType.Pass),
                  ].includes(String(newProductRecordType)) &&
                    newProductRecordCollectStartDate)) && (
                  <>
                    {calendarData && calendarData.length > 0 && (
                      <SelectWithFiltering
                        className={"mb-3"}
                        label={"Calendar"}
                        mandatory={true}
                        name={"NewProductRecordCategory"}
                        options={calendarData}
                        onChange={(event) =>
                          setNewProductRecordCalendar(event.value)
                        }
                        value={newProductRecordCalendar}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="modal-footer">
            <Button
              text={"Confirm"}
              style={ButtonStyle.Primary}
              onClick={() => createProductForBundle()}
              showSpinner={isCreating}
              disabled={
                !newProductRecordType ||
                !newProductRecordName ||
                newProductRecordName.trim() === "" ||
                !newProductRecordVariantTypes ||
                newProductRecordVariantTypes.lenght === 0 ||
                isCreating
              }
            />
            <Button
              text={"Cancel"}
              style={ButtonStyle.Info}
              onClick={() => setDisplayAddNewProductModal(null)}
              showSpinner={isCreating}
              disabled={isCreating}
            />
          </div>
        </>
      )}
    </Modal>
  );
}

export default ProductBundleItemProductCreate;
