import moment from "moment";
import { GlobalDispatchMethods } from "../../../js/enums";
import { DispatchMethods } from "../enums";
import { DesignMode, DragType, LinkType } from "./enums";
import {
  BookingControlDefaultValues,
  BookingControlFormStages,
  ButtonControlDefaultValues,
  ButtonControlFormStages,
  DividerControlDefaultValues,
  DividerControlFormStages,
  ImageControlDefaultValues,
  ImageControlFormStages,
  ImageTextControlDefaultValues,
  ImageTextControlFormStages,
  MembershipRenewalControlDefaultValues,
  MembershipRenewalControlFormStages,
  SocialFollowControlDefaultValues,
  GetSocialFollowControlFormStages,
  TextControlDefaultValues,
  TextControlFormStages,
  CouponControlFormStages,
  VoucherControlFormStages,
  CouponControlDefaultValues,
  VoucherControlDefaultValues,
} from "./controls";

const applyControlChanges = (dispatch, values) => {
  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      ...values,
      rows: values.rows.map((row, rowIndex) => {
        return {
          ...row,
          Columns: row.Columns.map((column, columnIndex) => {
            return {
              ...column,
              Controls: column.Controls.map(
                (control, controlIndex) => {
                  if (
                    rowIndex === values.editControl.rowIndex &&
                    columnIndex === values.editControl.columnIndex &&
                    controlIndex === values.editControl.controlIndex
                  ) {
                    return {
                      Name: values.controlName,
                      Template: control.Template,
                      Properties: control.Properties.map(
                        (property) => {
                          return {
                            Name: property.Name,
                            Value: values[property.Name],
                          };
                        }
                      ),
                    };
                  } else {
                    return control;
                  }
                }
              ),
            };
          }),
        };
      }),
      editControl: "",
    },
  });
};

const cancelControlChanges = (dispatch, values) => {
  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      designMode: String(DesignMode.Controls),
      editControl: "",
      globalBackgroundColour: values.globalBackgroundColour,
      globalContentBackgroundColour:
        values.globalContentBackgroundColour,
      globalContentPadding: values.globalContentPadding,
      globalLinkColour: values.globalLinkColour,
      globalPadding: values.globalPadding,
      rows: values.rows,
    },
  });
};

const changeDesignMode = (dispatch, designMode, values) => {
  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      ...values,
      designMode: String(designMode),
    },
  });
};

const deleteControl = (
  columnIndex,
  controlIndex,
  globalDispatch,
  rowIndex,
  setFieldValue,
  values
) => {
  // prevent if a control is being edited
  if (values.editControl) {
    globalDispatch({
      type: GlobalDispatchMethods.AddNotification,
      notification: {
        message: "Cannot delete while a component is being edited",
        success: false,
      },
      dispatch: globalDispatch,
    });
    return;
  }

  setFieldValue(
    "rows",
    values.rows.map((row, i) => {
      return {
        ...row,
        Columns: row.Columns.map((column, j) => {
          return {
            ...column,
            Controls: column.Controls.filter(
              (_, k) =>
                !(
                  i === rowIndex &&
                  j === columnIndex &&
                  k === controlIndex
                )
            ),
          };
        }),
      };
    })
  );
};

const deleteRow = (rowIndex, setFieldValue, values) => {
  setFieldValue(
    "rows",
    values.rows.filter((_, i) => i !== rowIndex)
  );
};

const getElementOffset = (element) => {
  let topDistance = 0;
  let leftDistance = 0;

  while (element) {
    topDistance += element.offsetTop;
    leftDistance += element.offsetLeft;
    element = element.offsetParent;
  }

  return { top: topDistance, left: leftDistance };
};

const getEmailLinkId = (href) => {
  let url = "";
  if (href.startsWith("..")) {
    url = new URL("https://www.green4solutions.com/" + href);
  } else if (href.indexOf("actions/redirect.aspx?url=") > -1) {
    const parentUrl = new URL(href);
    let redirectUrl = parentUrl.searchParams.get("url");
    if (redirectUrl.startsWith("..")) {
      redirectUrl = new URL(
        redirectUrl,
        parentUrl.origin + parentUrl.pathname
      ).href;
    }
    url = new URL(redirectUrl);
  } else {
    url = new URL(href);
  }

  const id = new URL(url).searchParams.get("g4id");
  return id ? parseInt(id) : null;
};

const getLinkHref = (properties, prefix) => {
  const linkType =
    properties[prefix ? `${prefix}LinkType` : "linkType"];

  switch (linkType) {
    case LinkType.Predefined:
      return properties[
        prefix ? `${prefix}PredefinedLinks` : "predefinedLinks"
      ];
    case LinkType["Email Address"]:
      const emailAddress =
        properties[prefix ? `${prefix}EmailAddress` : "emailAddress"];
      const subject = encodeURIComponent(
        properties[prefix ? `${prefix}EmailSubject` : "emailSubject"]
      );
      const content = encodeURIComponent(
        properties[prefix ? `${prefix}EmailContent` : "emailContent"]
      );
      return `mailto:${emailAddress}?subject=${subject}&body=${content}`;
    case LinkType.Attribute:
      const attribute =
        properties[
          prefix
            ? `${prefix}SelectedLinkAttribute`
            : "selectedLinkAttribute"
        ];
      return `actions/redirect.aspx?url=$$attribute:${attribute}-$`;
    case LinkType["Web Address"]:
    default:
      return properties[prefix ? `${prefix}Link` : "link"];
  }
};

const getStagesForControl = (template, properties) => {
  switch (template) {
    case "booking-control":
      return BookingControlFormStages;
    case "button-control":
      return ButtonControlFormStages;
    case "coupon-control":
      return CouponControlFormStages;
    case "divider-control":
      return DividerControlFormStages;
    case "image-control":
      return ImageControlFormStages;
    case "image-text-control":
      return ImageTextControlFormStages;
    case "membership-renewal-control":
      return MembershipRenewalControlFormStages;
    case "social-follow-control":
      return GetSocialFollowControlFormStages(properties);
    case "voucher-control":
      return VoucherControlFormStages;
    default:
      return TextControlFormStages;
  }
};

const handleDragEnd = (
  event,
  saveUndoSnapshot,
  setFieldValue,
  values
) => {
  const { active, over } = event;

  if (!active || !over) {
    return;
  }

  const dragType = getDragType(active.id, over.id);
  if (dragType === null) {
    return;
  }

  // deep copy the rows to avoid changing the original value and affecting snapshots
  let rows = [];
  const rowCopy = JSON.parse(JSON.stringify(values.rows));

  switch (dragType) {
    case DragType.NewRow:
      rows = handleNewRow(rowCopy, active.id, over.id);
      break;
    case DragType.MoveRow:
      rows = handleMoveRow(rowCopy, active.id, over.id);
      break;
    case DragType.NewControl:
      rows = handleNewControl(rowCopy, active.id, over.id);
      break;
    case DragType.MoveControl:
      rows = handleMoveControl(rowCopy, active.id, over.id);
      break;
    default:
      break;
  }

  if (rows === null || rows.length === 0) {
    return;
  }

  // update the rows value
  saveUndoSnapshot(values);
  setFieldValue("rows", rows);
};

const getDragType = (activeId, overId) => {
  if (overId.indexOf("row") === 0) {
    return activeId.indexOf("moverow") === 0
      ? DragType.MoveRow
      : DragType.NewRow;
  }

  if (overId.indexOf("control") === 0) {
    return activeId.indexOf("movecontrol") === 0
      ? DragType.MoveControl
      : DragType.NewControl;
  }

  return null;
};

const handleNewRow = (result, activeId, overId) => {
  // determine the indexes
  const overIdentifiers = overId.split("-");
  if (overIdentifiers.length !== 2) {
    return null;
  }

  const index = parseInt(overIdentifiers[1]);

  // determine the number of columns to add
  const activeIdentifiers = activeId.split("-");
  const columns = parseInt(activeIdentifiers[0]);

  // add the new row
  result.splice(index, 0, {
    Columns: new Array(columns).fill().map(() => ({ Controls: [] })),
  });

  return result;
};

const handleMoveRow = (result, activeId, overId) => {
  // determine the indexes
  const overIdentifiers = overId.split("-");
  if (overIdentifiers.length !== 2) {
    return null;
  }

  const index = parseInt(overIdentifiers[1]);

  // row being moved
  const activeIdentifiers = activeId.split("-");
  const rowIndex = parseInt(activeIdentifiers[1]);

  // remember the row being removed
  const row = result[rowIndex];

  // remove the row
  result.splice(rowIndex, 1);

  // add the row back in
  const newRowIndex = rowIndex < index ? index - 1 : index;
  result.splice(newRowIndex, 0, row);

  return result;
};

const handleNewControl = (result, activeId, overId) => {
  // determine the indexes
  const overIdentifiers = overId.split("-");
  if (overIdentifiers.length !== 4) {
    return;
  }

  const rowIndex = parseInt(overIdentifiers[1]);
  const columnIndex = parseInt(overIdentifiers[2]);
  const controlIndex = parseInt(overIdentifiers[3]);

  if (
    result[rowIndex] &&
    result[rowIndex].Columns &&
    result[rowIndex].Columns[columnIndex] &&
    result[rowIndex].Columns[columnIndex].Controls
  ) {
    result[rowIndex].Columns[columnIndex].Controls.splice(
      controlIndex,
      0,
      {
        Name: getNameForTemplate(activeId),
        Properties: getDefaultPropertiesForTemplate(activeId),
        Template: activeId,
      }
    );
  }

  return result;
};

const handleMoveControl = (result, activeId, overId) => {
  // determine the indexes
  const overIdentifiers = overId.split("-");
  const activeIdentifiers = activeId.split("-");
  if (
    overIdentifiers.length !== 4 ||
    activeIdentifiers.length !== 4
  ) {
    return;
  }

  const rowIndex = parseInt(overIdentifiers[1]);
  const columnIndex = parseInt(overIdentifiers[2]);
  let controlIndex = parseInt(overIdentifiers[3]);

  const activeRowIndex = parseInt(activeIdentifiers[1]);
  const activeColumnIndex = parseInt(activeIdentifiers[2]);
  const activeControlIndex = parseInt(activeIdentifiers[3]);

  // remember the control being removed
  const control =
    result[activeRowIndex].Columns[activeColumnIndex].Controls[
      activeControlIndex
    ];

  // remove the control
  result[activeRowIndex].Columns[activeColumnIndex].Controls.splice(
    activeControlIndex,
    1
  );

  if (
    rowIndex === activeRowIndex &&
    columnIndex === activeColumnIndex &&
    controlIndex > activeControlIndex
  ) {
    // adjust the new control index as we are moving the control within the same row and column
    controlIndex--;
  }

  // add the control back in
  result[rowIndex].Columns[columnIndex].Controls.splice(
    controlIndex,
    0,
    control
  );

  return result;
};

const getBoolValue = (value) => {
  if (typeof value === "boolean") {
    return value;
  }
  if (value === "1" || value === "true") {
    return true;
  }
  return false;
};

function generateCommunicationTemplateDataAndHTML(values) {
  let result = { templateData: "", templateHTML: null };
  let templateControls = {};
  let i = 1;
  values.rows.forEach((row) => {
    row.Columns.forEach((column) => {
      const zoneControls = [];
      column.Controls.forEach((control) => {
        zoneControls.push({
          Name: control.Name,
          Template: control.Template,
          Properties: control.Properties.map((property) => ({
            Name: property.Name,
            Value: property.Value,
          })),
          Settings: [],
        });
      });

      templateControls[`zone-${i}`] = zoneControls;
      i++;
    });
  });

  result.templateData = window.btoa(
    encodeURIComponent(
      JSON.stringify({
        BackgroundColour: values.globalBackgroundColour,
        ContentBackgroundColour: values.globalContentBackgroundColour,
        ContentPadding: values.globalContentPadding,
        LinkColour: values.globalLinkColour,
        Padding: values.globalPadding,
        Rows: values.rows.map((row) => row.Columns.length),
        TemplateControls: templateControls,
      })
    )
  );

  const ink = document.getElementById("ink");

  if (ink) {
    const templateHtmlRaw = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml" class="email-html">
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width" />
      <title></title>
      <link rel="stylesheet" href="ink.css" />
      <style>
        #ink a {
          color: ${values.globalLinkColour} !important;
        }
      </style>
    </head>
    <body>${ink.outerHTML}</body>
    </html>`;

    const templateHtml = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml" class="email-html">
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width" />
      <title></title>
      <style type="text/css" id="ink-css"></style>
      <style>
        #ink a {
          color: ${values.globalLinkColour} !important;
        }
      </style>
    </head>
    <body>${ink.outerHTML}</body>
    </html>`;

    result.templateHTMLRaw = templateHtmlRaw;
    result.templateHTML = window.btoa(
      encodeURIComponent(templateHtml)
    );
  }
  return result;
}

const getDateString = (dayOffset, monthOffset, format) => {
  const currentDate = moment();

  // Apply the offsets to the current date
  currentDate.add(dayOffset, "days");
  currentDate.add(monthOffset, "months");

  return currentDate.format(format);
};

const getDefaultPropertiesForTemplate = (template) => {
  switch (template) {
    case "booking-control":
      return BookingControlDefaultValues;
    case "button-control":
      return ButtonControlDefaultValues;
    case "coupon-control":
      return CouponControlDefaultValues;
    case "divider-control":
      return DividerControlDefaultValues;
    case "image-control":
      return ImageControlDefaultValues;
    case "image-text-control":
      return ImageTextControlDefaultValues;
    case "membership-renewal-control":
      return MembershipRenewalControlDefaultValues;
    case "social-follow-control":
      return SocialFollowControlDefaultValues;
    case "voucher-control":
      return VoucherControlDefaultValues;
    default:
      return TextControlDefaultValues;
  }
};

const getNameForTemplate = (template) => {
  switch (template) {
    case "booking-control":
      return "Booking Details";
    case "button-control":
      return "Button";
    case "coupon-control":
      return "Coupon";
    case "divider-control":
      return "Divider";
    case "image-control":
      return "Image";
    case "image-text-control":
      return "Text & Image";
    case "membership-renewal-control":
      return "Membership Renewal";
    case "social-follow-control":
      return "Social Follow";
    case "voucher-control":
      return "Voucher";
    default:
      return "Text";
  }
};

const mapControlProperties = (properties, template) => {
  const stages = getStagesForControl(template, properties);

  const booleanFields = stages.flatMap((stage) =>
    stage.sections.flatMap((section) =>
      section.fields
        .filter((field) => field.type === "bit")
        .map((field) => field.name)
    )
  );

  booleanFields.forEach((field) => {
    properties[field] = getBoolValue(properties[field]);
  });

  return properties;
};

const renderHeatMap = (setHeatMapState, Statistics, UniqueClicks) => {
  const heatMap = document.getElementById("communication-heat-map");
  const body =
    heatMap && heatMap.contentDocument
      ? heatMap.contentDocument.body
      : null;
  if (!body) {
    return;
  }

  // set the height and then wait for update
  setHeatMapState((previous) => {
    return {
      anchors: [],
      height: body.scrollHeight,
      show: previous.show,
    };
  });

  setTimeout(function () {
    // remove mobile elements
    Array.from(body.getElementsByClassName("show-for-small")).forEach(
      function (element) {
        element.parentNode.removeChild(element);
      }
    );

    const { ClickThroughs } = Statistics;
    // get the click throughs and corresponding id
    const counts = ClickThroughs.map((x) => x.Count);
    const minCount = Math.min(...counts);
    const maxCount = Math.max(...counts);

    const heat1 = minCount;
    const increment = parseFloat((maxCount - minCount) / 3);
    const heat2 = heat1 + increment;
    const heat3 = heat2 + increment;

    for (const clickThrough of ClickThroughs) {
      clickThrough.Id = getEmailLinkId(clickThrough.Page);
      clickThrough.Heat =
        clickThrough.Count >= heat3
          ? 3
          : clickThrough.Count >= heat2
          ? 2
          : 1;
    }

    let heatMapAnchors = [];
    const anchors = body.getElementsByTagName("a");
    for (const anchor of anchors) {
      const offset = getElementOffset(anchor);

      const id = getEmailLinkId(anchor.href);

      const clickThrough = id
        ? ClickThroughs.find((x) => x.Id === id)
        : null;

      const count = clickThrough ? clickThrough.Count : 0;
      const totalCount = clickThrough ? clickThrough.TotalCount : 0;
      const percentage =
        UniqueClicks > 0 && count > 0
          ? (count * 100) / UniqueClicks
          : 0;
      const heat = clickThrough ? clickThrough.Heat : 0;

      heatMapAnchors.push({
        count: count,
        heat: heat,
        href: anchor.getAttribute("href"),
        id: id,
        offsetHeight: anchor.offsetHeight,
        offsetLeft: offset.left,
        offsetTop: offset.top,
        offsetWidth: anchor.offsetWidth,
        percentage: `${percentage.toFixed(2)}%`,
        totalCount: totalCount,
        uniqueClicks: 0,
      });
    }

    setHeatMapState((previous) => {
      return {
        anchors: heatMapAnchors,
        height: body.scrollHeight,
        show: previous.show,
      };
    });
  }, 100);
};

const setCommCopyAttributes = (attributes, state) => {
  let commTemplateDataAndHTML =
    generateCommunicationTemplateDataAndHTML(state);

  attributes["templateData"] = commTemplateDataAndHTML.templateData;
  attributes["templateHtml"] = commTemplateDataAndHTML.templateHTML;
  attributes["g4c_active"] = false;
  attributes["g4c_listsid"] = null;
  attributes["g4c_sid"] = null;
  attributes["g4c_publishedrecipients"] = null;
  attributes["statuscode"] = 1;
  if (state.g4c_designerdata) {
    // only reset the template if it was created in comm creator
    attributes["g4c_templateid"] = null;
  }
};

const setEditControl = (
  columnIndex,
  controlIndex,
  dispatch,
  globalDispatch,
  name,
  properties,
  rowIndex,
  template,
  values
) => {
  // prevent if a control is being edited
  if (values.editControl) {
    globalDispatch({
      type: GlobalDispatchMethods.AddNotification,
      notification: {
        message:
          "Cannot edit while another component is being edited",
        success: false,
      },
      dispatch: globalDispatch,
    });
    return;
  }

  const stages = getStagesForControl(template, properties);

  const propertyValues = {};
  stages.forEach((stage) => {
    stage.sections
      .filter((section) => section.fields)
      .forEach((section) => {
        section.fields.forEach((field) => {
          propertyValues[field.name] =
            field.type === "bit"
              ? getBoolValue(properties[field.name])
              : properties[field.name]
              ? properties[field.name]
              : "";
        });
      });
  });

  propertyValues.controlName = name;

  dispatch({
    type: DispatchMethods.SetReloadValues,
    reloadValues: {
      ...values,
      ...propertyValues,
      editControl: {
        rowIndex: rowIndex,
        columnIndex: columnIndex,
        controlIndex: controlIndex,
        template: template,
      },
    },
  });
};

export {
  applyControlChanges,
  cancelControlChanges,
  changeDesignMode,
  deleteControl,
  deleteRow,
  generateCommunicationTemplateDataAndHTML,
  getDateString,
  getLinkHref,
  getNameForTemplate,
  getStagesForControl,
  handleDragEnd,
  mapControlProperties,
  renderHeatMap,
  setCommCopyAttributes,
  setEditControl,
};
