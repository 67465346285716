import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { checkRelatedEntityHasRecords } from "../components/form/Helpers";
import { TelegramQueueStage } from "../js/enums";
import { checkIfSellFixturesAndSeriesSettingOn } from "../js/utility";
import lookups from "./lookups";
import { TelegramQueueFormStages } from "./telegramQueue";

export const BookingTicketFormStages = [
  {
    title: "Details",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_barcode",
            description: "",
            label: "Barcode",
            type: "nvarchar",
          },
          {
            name: "g4b_ticketnumber",
            description: "",
            label: "Ticket Number",
            type: "nvarchar",
          },
          {
            name: "g4b_utid",
            description: "",
            label: "UTID",
            type: "int",
          },
        ],
      },
    ],
  },
  {
    title: "Status",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_printed",
            className: "col-md-6",
            description: "",
            label: "Printed",
            type: "bit",
          },
          {
            name: "g4b_printedtime",
            className: "col-md-6",
            description: "",
            label: "Printed Time",
            type: "datetime",
          },
          {
            name: "g4b_whitelisted",
            className: "col-md-6",
            description: "",
            label: "Is Whitelisted",
            type: "bit",
          },
          {
            name: "g4b_blacklisted",
            className: "col-md-6",
            description: "",
            label: "Is Blacklisted",
            type: "bit",
          },
          {
            name: "g4b_iscancelled",
            className: "col-md-6",
            description: "",
            label: "Is Cancelled",
            type: "bit",
          },
        ],
      },
    ],
  },
  {
    title: "Related",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_seriesidname",
            className: "col-md-6",
            description: "",
            displayIfFromGlobalState: (globalState) => {
              return checkIfSellFixturesAndSeriesSettingOn(
                globalState
              );
            },
            label: "Series",
            type: "nvarchar",
          },
          {
            name: "g4b_fixtureidname",
            className: "col-md-6",
            description: "",
            displayIfFromGlobalState: (globalState) => {
              return checkIfSellFixturesAndSeriesSettingOn(
                globalState
              );
            },
            label: "Fixture",
            type: "nvarchar",
          },
          {
            name: "g4b_productuseridname",
            className: "col-md-6",
            description: "",
            label: "Product User",
            type: "nvarchar",
          },
          {
            name: "g4b_protoproductidname",
            className: "col-md-6",
            description: "",
            label: "Product Variant",
            type: "nvarchar",
          },
          {
            name: "g4b_variantidname",
            className: "col-md-6",
            description: "",
            label: "Bundle Product Variant",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Telegram Queues",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sectionInitiallyCollapsedFromState: (state) =>
      !checkRelatedEntityHasRecords("g4b_telegramqueue", state),
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_sequencenumber",
              label: "Sequence Number",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_type",
              label: "Type",
              className: "col-short-name",
            },
            {
              name: "g4b_stage",
              className: "col-name",
              label: "Stage",
              type: "picklist",
              enum: TelegramQueueStage,
            },
            {
              name: "g4b_accesscontrolprovideridname",
              label: "Access Control Provider",
              className: "col-short-name",
            },
            {
              name: "g4b_fixtureidname",
              displayIfFromGlobalState: (globalState) => {
                return checkIfSellFixturesAndSeriesSettingOn(
                  globalState
                );
              },
              label: "Fixture",
              className: "col-short-name",
            },
            {
              name: "g4b_seriesidname",
              displayIfFromGlobalState: (globalState) => {
                return checkIfSellFixturesAndSeriesSettingOn(
                  globalState
                );
              },
              label: "Series",
              className: "col-short-name",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          loadStateRelatedEntityName: "g4b_telegramqueue",
          lookup: lookups.g4b_telegramqueue.all,
          linkForNewRecord: "/telegram-queue",
          subFormStages: TelegramQueueFormStages,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Booking Ticket Fixtures",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromGlobalState: (globalState) => {
      return checkIfSellFixturesAndSeriesSettingOn(globalState);
    },
    sectionInitiallyCollapsedFromState: (state) =>
      !checkRelatedEntityHasRecords("g4b_ticketfixtures", state),
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: false,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_utid",
              className: "col-number",
              label: "UTID",
              type: "string",
            },
            {
              name: "g4b_fixtureidname",
              label: "Fixture",
              className: "col-short-name",
            },
            {
              name: "g4b_whitelisted",
              className: "col-bool",
              label: "Is Whitelisted?",
            },
            {
              name: "g4b_blacklisted",
              className: "col-bool",
              label: "Is Blacklisted?",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              textFieldsSearch: ["g4b_utid", "g4b_fixtureidname"],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          loadStateRelatedEntityName: "g4b_ticketfixtures",
          lookup: lookups.g4b_ticketfixtures.all,
          useSubForm: false,
        },
      },
    ],
  },
];
