import { hideNavigationButtons } from "../Helpers";
import { Button, Footer } from "../../elements/_Elements";
import { ButtonStyle } from "../../../js/enums";
import ABTestConfirmPublishModal from "./ABTestConfirmPublishModal";
import ABTestNoFinalCountMessageModal from "./ABTestNoFinalCountMessageModal";
import { useGlobalState } from "../../../GlobalContext";

function ABTestNavigationButtons({
  backText,
  disabled = false,
  errors,
  isBusy = false,
  onBack,
  onSubmit,
  saveAndCloseSubmitButtonClickEvent,
  saveAndCloseSubmitButtonText = "Save and Close",
  setConfirmationModal,
  setFieldValue,
  showSaveAndCloseButton,
  showSaveButton,
  stage,
  stages,
  state,
  submitButtonClickEvent,
  submitText = "Save",
  values,
}) {
  const globalState = useGlobalState();

  const handleSaveButton = (action, submitButtonEvent) => {
    let confirmationModalComponent = null;

    //Check if the selected comm audience has a final count thats more
    //than 0 otherwise publishing will throw an error in the services
    if (
      action === "saveAndPublish" &&
      (!values.finalCount || values.finalCount === 0)
    ) {
      confirmationModalComponent = {
        displayModal: true,
        modalComponent: ABTestNoFinalCountMessageModal,
      };
    } else if (action === "saveAndPublish") {
      confirmationModalComponent = {
        displayModal: true,
        modalComponent: ABTestConfirmPublishModal,
      };
    }

    if (confirmationModalComponent) {
      if (errors && Object.keys(errors).length > 0) {
        setConfirmationModal(null);
      } else {
        setConfirmationModal(confirmationModalComponent);
      }
    } else {
      submitButtonEvent();
      onSubmit();
    }
  };
  return (!onBack && !showSaveButton && !showSaveAndCloseButton) ||
    hideNavigationButtons(globalState, stage, stages, state) ? (
    <></>
  ) : (
    <>
      <Footer {...{ backText, disabled, isBusy, onBack }}>
        {showSaveButton && (
          <>
            {state.statuscode !== 4 && (
              <>
                <Button
                  style={ButtonStyle.Primary}
                  text={submitText}
                  disabled={disabled || isBusy}
                  showSpinner={isBusy}
                  onClick={() =>
                    handleSaveButton("save", submitButtonClickEvent)
                  }
                />
                <Button
                  className="ms-3"
                  style={ButtonStyle.Primary}
                  text={"Save and Publish"}
                  disabled={disabled || isBusy}
                  showSpinner={isBusy}
                  onClick={() =>
                    handleSaveButton("saveAndPublish", () =>
                      setFieldValue("action", "saveAndPublish")
                    )
                  }
                />
              </>
            )}
          </>
        )}
        {showSaveAndCloseButton && state.statuscode !== 4 && (
          <Button
            className="ms-3"
            type={"submit"}
            style={ButtonStyle.Primary}
            text={saveAndCloseSubmitButtonText}
            disabled={disabled || isBusy}
            showSpinner={isBusy}
            onClick={() =>
              handleSaveButton(
                "saveAndClose",
                saveAndCloseSubmitButtonClickEvent
              )
            }
          />
        )}
      </Footer>
    </>
  );
}

export default ABTestNavigationButtons;
